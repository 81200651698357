export default (id, moduleState, onlyOwn) => {
    if (!id || !moduleState) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }

        return null;
    }

    if (!Array.isArray(moduleState.errors) || moduleState.errors.length === 0) {
        return null;
    }

    if (onlyOwn) {
        return moduleState.errors.find(error => error.id === id);
    }

    return moduleState.errors[0];
};