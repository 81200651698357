export default () => ({
    state: {
        accountability: null,
        involved: null,
        social: null,
        location: null,
        phone: null,
        office: null,
        contact: null,
        emailSignUp: null,
    },
});