/* eslint-disable max-len */
export default {
    'lni-checkbox': {
        messages: {
            valueMissing: {
                global: 'Veuillez cocher "{legend}{labelText}", cette étape est obligatoire',
                inline: 'Veuillez cocher ce champ',
            },
        },
    },
    'lni-checkbox-list': {
        messages: {
            valueMissing: {
                global: 'Veuillez sélectionner une option pour "{legend}{labelText}", cette étape est obligatoire',
                inline: 'Veuillez sélectionner une option',
            },
        },
    },
    'lni-error-container': {
        heading: 'Avant de continuer:',
        message: 'Veuillez corriger les erreurs suivantes',
    },
    'lni-input-phone': {
        messages: {
            patternMismatch: {
                global: 'Veuillez indiquer un numéro de téléphone valide (Format:XXX-XXX-XXXX)',
                inline: 'Veuillez indiquer un numéro de téléphone valide (Format:XXX-XXX-XXXX)',
            },
        },
        phoneHelperText: 'Format: XXX-XXX-XXXX',
    },
    'lni-input-text': {
        messages: {
            valueMissing: {
                global: 'Veuillez indiquer "{legend}{labelText}", cette étape est obligatoire',
                inline: 'Veuillez remplir ce champ',
            },
            patternMismatch: {
                global: 'Veuillez utiliser le bon format pour "{legend}{labelText}"',
                inline: 'Veuillez utiliser le bon format{example}',
            },
            typeMismatch: {
                global: 'Veuillez utiliser le bon format pour "{legend}{labelText}"',
                inline: 'Veuillez utiliser le bon format{example}',
            },
            tooShort: {
                global: '"{labelText}" doit contenir au moins {num} caractères. Veuillez ajouter des caractères {difference}.',
                inline: '"{labelText}" doit contenir au moins {num} caractères. Veuillez ajouter des caractères {difference}.',
            },
        },
    },
    'lni-input-email': {
        messages: {
            valueMissing: {
                global: 'Veuillez indiquer "{legend}{labelText}", cette étape est obligatoire',
                inline: 'Veuillez remplir ce champ',
            },
            patternMismatch: {
                global: 'Veuillez indiquer une adresse e-mail valide pour "{legend}{labelText}"',
                inline: 'Veuillez indiquer une adresse e-mail valide',
            },
            typeMismatch: {
                global: 'Veuillez indiquer une adresse e-mail valide pour "{legend}{labelText}"',
                inline: 'Veuillez indiquer une adresse e-mail valide',
            },
        },
    },
    'lni-radios': {
        messages: {
            valueMissing: {
                global: 'Veuillez sélectionner une option pour "{legend}{labelText}", cette étape est obligatoire',
                inline: 'Veuillez sélectionner une option',
            },
        },
    },
    'lni-select': {
        messages: {
            valueMissing: {
                global: 'Veuillez sélectionner une option pour "{legend}{labelText}", cette étape est obligatoire',
                inline: 'Veuillez sélectionner une option',
            },
        },
    },
    'lni-spinner': {
        label: 'Chargement en cours...',
    },
};
/* eslint-enable max-len */