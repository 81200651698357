/* eslint-disable */
export const audiences = [
    {
        relationships: [
            'CostLedgerRequestor',
        ],
        tasks: [{ appCode: "cvledgers", core: true }],
        ui_code: 'rel_cvledger',
        ui_name: 'Crime Victim Ledger Requestor',
        plural_name: 'Crime Victims',
        enableDashboard: true,
        dashboard_name: 'My crime victims tasks with L&amp;I',
        tab_name: 'Crime Victims Tasks',
        more_access_name: 'Add a county',
        signup_text: 'Sign up as a crime victims cost ledger requestor',
        task_tool_name: 'Crime Victims Access',
        needs_mfa: {
            Test: true,
            PreProd: true,
            Production: true,
        },
        needs_kba: {
            Test: false,
            PreProd: false,
            Production: false,
        },
        environments: {
            Dev2: true,
            Test: true,
            PreProd: true,
            Production: true,
        },
        push_widgets: [
            {
                url: 'https://lni.wa.gov/admin/pushwidgets/widg-iw-2-StepVerification.asp',
            },
        ],
    },
    {
        relationships: [
            'Claimant',
        ],
        tasks: [
            {
                appCode: 'cac',
                core: true,
            },
            {
                appCode: 'securemessagecenter',
                core: true,
            },
            {
                appCode: 'wroa',
                core: true,
            },
            {
                appCode: 'ctoc',
                core: true,
            },
            {
                appCode: 'saw',
                core: false,
            },
        ],
        ui_code: 'rel_worker',
        ui_name: 'Injured Worker or Authorized Delegate',
        plural_name: 'Injured Workers',
        enableDashboard: true,
        dashboard_name: 'My injured worker claim(s) and tasks with L&amp;I',
        tab_name: 'Injured Worker tasks',
        more_access_name: 'Get access to another claim',
        signup_text: 'Sign up as an Injured Worker',
        task_tool_name: 'Injured Worker access',
        needs_mfa: {
            Test: true,
            PreProd: true,
            Production: true,
        },
        needs_kba: {
            Test: false,
            PreProd: false,
            Production: false,
        },
        environments: {
            Dev2: true,
            Test: true,
            PreProd: true,
            Production: true,
        },
        push_widgets: [
            {
                url: 'https://lni.wa.gov/admin/pushwidgets/widg-iw-2-StepVerification.asp',
            },
        ],
    },
    {
        relationships: [
            'LegalRep',
        ],
        tasks: [
            {
                appCode: 'saw',
                core: false,
            },
            {
                appCode: 'securemessagecenter',
                core: true,
            },
        ],
        ui_code: 'rel_legalRep',
        ui_name: 'Legal representative for an injured worker',
        plural_name: 'Legal representatives for an injured worker',
        enableDashboard: false,
        dashboard_name: 'My legal representative relationship(s) and tasks with L&amp;I',
        tab_name: 'Legal Rep tasks',
        more_access_name: 'Add a Legal Rep',
        signup_text: 'Sign up as a Legal Rep',
        task_tool_name: 'Legal Rep access',
        needs_mfa: {
            Test: true,
            PreProd: true,
            Production: true,
        },
        needs_kba: {
            Test: false,
            PreProd: false,
            Production: false,
        },
        environments: {
            Dev2: true,
            Test: true,
            PreProd: true,
            Production: true,
        },
        push_widgets: [
            {
                url: 'https://lni.wa.gov/admin/pushwidgets/widg-lgl-2-StepVerification.asp',
            },
        ],
    },
    {
        relationships: [
            'MedicalProvider',
        ],
        tasks: [
            {
                appCode: 'saw',
                core: false,
            },
            {
                appCode: 'securemessagecenter',
                core: true,
            },
        ],
        ui_code: 'rel_provider',
        ui_name: 'Medical or Vocational provider',
        plural_name: 'Medical or Vocational providers',
        enableDashboard: true,
        dashboard_name: 'My provider relationship(s) and claims tasks with L&amp;I',
        tab_name: 'Provider claims tasks',
        more_access_name: 'Add a Provider',
        signup_text: 'Sign up as a Provider',
        task_tool_name: 'Provider access',
        needs_mfa: {
            Test: true,
            PreProd: true,
            Production: true,
        },
        needs_kba: {
            Test: false,
            PreProd: false,
            Production: false,
        },
        environments: {
            Dev2: true,
            Test: true,
            PreProd: true,
            Production: true,
        },
        push_widgets: [
            {
                url: 'https://lni.wa.gov/admin/pushwidgets/widg-mp-2-StepVerification.asp',
            },
        ],
    },
    {
        relationships: [
            'ServiceProvider', //peb provider
        ],
        tasks: [
            {
                appCode: 'saw',
                core: false,
            },
            {
                appCode: 'peb',
                core: true,
            },
        ],
        ui_code: 'rel_provider_billing',
        ui_name: 'Billing provider',
        plural_name: 'Billing providers',
        enableDashboard: false,
        dashboard_name: 'My provider relationship(s) and billing tasks with L&amp;I',
        tab_name: 'Provider billing tasks',
        more_access_name: 'Add a Billing Provider',
        signup_text: 'Sign up as a Billing Provider',
        task_tool_name: 'Billing provider access',
        needs_mfa: {
            Test: true,
            PreProd: true,
            Production: true,
        },
        needs_kba: {
            Test: false,
            PreProd: false,
            Production: false,
        },
        environments: {
            Dev2: true,
            Test: true,
            PreProd: true,
            Production: true,
        },
        push_widgets: [],
    },
    {
        relationships: [
            'IME',
        ],
        tasks: [
            {
                appCode: 'saw',
                core: false,
            },
        ],
        ui_code: 'rel_ime',
        ui_name: 'Independent Medical Examination (IME) Provider',
        plural_name: 'Independent Medical Examination (IME) Providers',
        enableDashboard: false,
        dashboard_name: 'My Independent Medical Examination (IME) Provider tasks with L&amp;I',
        tab_name: 'IME Provider tasks',
        more_access_name: 'Add an Independent Medical Examination (IME) Provider',
        signup_text: 'Sign up as an Independent Medical Examination (IME) Provider',
        task_tool_name: 'IME',
        needs_mfa: {
            Test: true,
            PreProd: true,
            Production: true,
        },
        needs_kba: {
            Test: false,
            PreProd: false,
            Production: false,
        },
        environments: {
            Dev2: true,
            Test: true,
            PreProd: true,
            Production: true,
        },
        push_widgets: [
            {
                url: 'https://lni.wa.gov/admin/pushwidgets/widg-ime-2-StepVerification.asp',
            },
        ],
    },
    {
        relationships: [
            'Employer',
            'SelfInsuredEmployer',
        ],
        tasks: [
            {
                appCode: 'cacAccount',
                core: true,
            },
            {
                appCode: 'securemessagecenter',
                core: true,
            },
            {
                appCode: 'stay',
                core: true,
            },
            {
                appCode: 'arts',
                core: false,
            },
            {
                appCode: 'pwia',
                core: true,
            },
            {
                appCode: 'saw',
                core: false,
            },
        ],
        ui_code: 'rel_employer',
        ui_name: 'Employer or representative',
        plural_name: 'Employers or representatives',
        // Dashboard only exists for Employer, not Self-Insured Employer
        enableDashboard: true,
        dashboard_name: 'My employer relationship(s) and tasks with L&amp;I',
        tab_name: 'Employer tasks',
        more_access_name: 'Add a business',
        signup_text: 'Sign up as an Employer',
        task_tool_name: 'Employer access',
        needs_mfa: {
            Test: true,
            PreProd: true,
            Production: true,
        },
        needs_kba: {
            Test: false,
            PreProd: false,
            Production: false,
        },
        environments: {
            Dev2: true,
            Test: true,
            PreProd: true,
            Production: true,
        },
        push_widgets: [
            {
                url: 'https://lni.wa.gov/admin/pushwidgets/widg-emp-2-StepVerification.asp',
            },
        ],
    },
    {
        relationships: [
            'ThirdParty',
        ],
        tasks: [
            {
                appCode: 'saw',
                core: false,
            },
            {
                appCode: 'securemessagecenter',
                core: true,
            },
        ],
        ui_code: 'rel_tpa',
        ui_name: 'Third party administrator',
        plural_name: 'Third party administrators',
        enableDashboard: false,
        dashboard_name: 'My third party administrator relationship(s) and tasks with L&amp;I',
        tab_name: 'TPA tasks',
        more_access_name: 'Add a TPA',
        signup_text: 'Sign up as a TPA',
        task_tool_name: 'TPA access',
        needs_mfa: {
            Test: true,
            PreProd: true,
            Production: true,
        },
        needs_kba: {
            Test: false,
            PreProd: false,
            Production: false,
        },
        environments: {
            Dev2: true,
            Test: true,
            PreProd: true,
            Production: true,
        },
        push_widgets: [
            {
                url: 'https://lni.wa.gov/admin/pushwidgets/widg-tpa-2-StepVerification.asp',
            },
        ],
    },
    {
        relationships: [
            'ThirdParty',
        ],
        tasks: [
            {
                appCode: 'saw',
                core: false,
            },
            {
                appCode: 'securemessagecenter',
                core: true,
            },
        ],
        ui_code: 'rel_accountant',
        ui_name: 'Third party accountant/bookkeeper',
        plural_name: 'Third party accountants/bookkeepers',
        enableDashboard: false,
        dashboard_name: 'My accountant/bookkeeper relationship(s) and tasks with L&amp;I',
        tab_name: 'Accountant tasks',
        more_access_name: 'Add an Accountant',
        signup_text: 'Sign up as an Accountant',
        task_tool_name: 'Accountant access',
        needs_mfa: {
            Test: true,
            PreProd: true,
            Production: true,
        },
        needs_kba: {
            Test: false,
            PreProd: false,
            Production: false,
        },
        environments: {
            Dev2: true,
            Test: true,
            PreProd: true,
            Production: true,
        },
        push_widgets: [
            {
                url: 'https://lni.wa.gov/admin/pushwidgets/widg-acct-2-StepVerification.asp',
            },
        ],
    },
    {
        relationships: [
            'RetroGroup',
        ],
        tasks: [
            {
                appCode: 'saw',
                core: false,
            },
            {
                appCode: 'securemessagecenter',
                core: true,
            },
        ],
        ui_code: 'rel_retro',
        ui_name: 'Retro program administrator',
        plural_name: 'Retro program administrators',
        enableDashboard: false,
        dashboard_name: 'My Retro program relationship(s) and tasks with L&amp;I',
        tab_name: 'Retro Program tasks',
        more_access_name: 'Add a Retro',
        signup_text: 'Sign up as a Retro',
        task_tool_name: 'Retro access',
        needs_mfa: {
            Test: true,
            PreProd: true,
            Production: true,
        },
        needs_kba: {
            Test: false,
            PreProd: false,
            Production: false,
        },
        environments: {
            Dev2: true,
            Test: true,
            PreProd: true,
            Production: true,
        },
        push_widgets: [
            {
                url: 'https://lni.wa.gov/admin/pushwidgets/widg-retro-2-StepVerification.asp',
            },
        ],
    },
    {
        relationships: [
            'COHE',
        ],
        tasks: [
            {
                appCode: 'saw',
                core: false,
            },
            {
                appCode: 'securemessagecenter',
                core: true,
            },
        ],
        ui_code: 'rel_cohe',
        ui_name: 'Health Services Coordinators and COHE Directors',
        plural_name: 'Health Services Coordinators and COHE Directors',
        enableDashboard: false,
        dashboard_name: 'My Health Services Coordinators and COHE Directors relationship(s) and tasks with  L&amp;I',
        tab_name: 'COHE tasks',
        more_access_name: 'Add a COHE',
        signup_text: 'Sign up as a COHE',
        task_tool_name: 'COHE access',
        needs_mfa: {
            Test: true,
            PreProd: true,
            Production: true,
        },
        needs_kba: {
            Test: false,
            PreProd: false,
            Production: false,
        },
        environments: {
            Dev2: true,
            Test: true,
            PreProd: true,
            Production: true,
        },
        push_widgets: [
            {
                url: 'https://lni.wa.gov/admin/pushwidgets/widg-cohe-2-StepVerification.asp',
            },
        ],
    },
    {
        relationships: [
            'Surgical',
        ],
        ui_code: 'rel_surgical',
        ui_name: 'Surgical Best Practices Coordinator',
        plural_name: 'Surgical Best Practices Coordinators',
        enableDashboard: false,
        dashboard_name: 'My Surgical Best Practices Coordinator relationship(s) and tasks with  L&amp;I',
        tab_name: 'Surgical tasks',
        more_access_name: 'Add a Surgical Best Practices Coordinator',
        signup_text: 'Sign up as a Surgical Best Practices Coordinator',
        task_tool_name: 'Surgical access',
        needs_mfa: {
            Test: true,
            PreProd: true,
            Production: true,
        },
        needs_kba: {
            Test: false,
            PreProd: false,
            Production: false,
        },
        environments: {
            Dev2: true,
            Test: true,
            PreProd: true,
            Production: true,
        },
        push_widgets: [
            {
                url: 'https://lni.wa.gov/admin/pushwidgets/widg-surg-2-StepVerification.asp',
            },
        ],
    },
    {
        relationships: [
            'ActivityCoach',
        ],
        ui_code: 'rel_activityCoach',
        ui_name: 'Best Practice Activity Coach',
        plural_name: 'Best Practice Activity Coaches',
        enableDashboard: false,
        dashboard_name: 'My Activity Coach relationship(s) and tasks with  L&amp;I',
        tab_name: 'Activity Coach tasks',
        more_access_name: 'Add a Best Practice Activity Coach',
        signup_text: 'Sign up as a Best Practice Activity Coach',
        task_tool_name: 'ActivityCoach access',
        needs_mfa: {
            Test: true,
            PreProd: true,
            Production: true,
        },
        needs_kba: {
            Test: false,
            PreProd: false,
            Production: false,
        },
        environments: {
            Dev2: true,
            Test: true,
            PreProd: true,
            Production: true,
        },
        push_widgets: [
            {
                url: 'https://lni.wa.gov/admin/pushwidgets/widg-coach-2-StepVerification.asp',
            },
        ],
    },
    {
        relationships: [
            'LNI',
        ],
        ui_code: 'rel_lni',
        ui_name: 'Labor and Industries Employee',
        plural_name: 'Labor and Industries Employees',
        enableDashboard: false,
        dashboard_name: 'Labor and Industries Employee tasks',
        tab_name: 'L&amp;I Employee tasks',
        more_access_name: '',
        signup_text: '',
        task_tool_name: '',
        needs_mfa: {
            Test: true,
            PreProd: true,
            Production: true,
        },
        needs_kba: {
            Test: false,
            PreProd: false,
            Production: false,
        },
        environments: {
            Dev2: true,
            Test: true,
            PreProd: true,
            Production: true,
        },
        push_widgets: [
            {
                url: 'https://lni.wa.gov/admin/pushwidgets/widg-lni-2-StepVerification.asp',
            },
        ],
    },
    {
        relationships: [
            'ApprenticeshipGovernment',
            'ApprenticeshipSponsor',
            'ApprenticeProgram',
            'PrevailingWages',
            'WageUpdate',
            'PWAwardingAgency',
            'KnowledgeManagement',
            'LiniisWeb',
        ],
        tasks: [
            {
                appCode: 'arts',
                core: false,
            },
            {
                appCode: 'pwia',
                core: false,
            },
            {
                appCode: 'epis',
                core: false,
            },
            {
                appCode: 'saw',
                core: false,
            },
            {
                appCode: 'liniis_web',
                core: false,
            },

        ],
        ui_code: 'rel_other',
        ui_name: 'Other',
        plural_name: 'Other user types',
        enableDashboard: false,
        dashboard_name: 'My apps',
        tab_name: 'Additional tasks',
        more_access_name: 'Get more access',
        task_tool_name: 'Additional tasks',
        needs_mfa: {
            Test: false,
            PreProd: false,
            Production: false,
        },
        needs_kba: {
            Test: false,
            PreProd: false,
            Production: false,
        },
        environments: {
            Dev2: true,
            Test: true,
            PreProd: true,
            Production: true,
        },
        push_widgets: [
            {
                url: 'https://lni.wa.gov/admin/pushwidgets/widg-other-2-StepVerification.asp',
            },
        ],
    },
];