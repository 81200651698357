/* eslint-disable no-underscore-dangle */
import Framework from '@gov.wa.lni/framework.one-lni.core/source/Framework';
import toCamelCase from '@gov.wa.lni/framework.one-lni.core/source/lib/toCamelCase';
import loadResource from '@gov.wa.lni/framework.one-lni.core/source/lib/loadResource';

const defaultLanguage = 'en';
const supportedSubdirectories = ['es'];

export default class Utility {
    private _parent: Framework;

    constructor(parent: Framework) {
        this._parent = parent;
    }

    private _savedPreference(): string | null {
        const savedPreference = this._parent.storage.cookie.get('lniLanguagePreference');

        if (savedPreference) {
            return savedPreference;
        }

        return null;
    }

    private _navigatorPreference(): string | null {
        const trimIndex = 2;

        if (!window.navigator.language) {
            return null;
        }

        return window.navigator.language.substring(0, trimIndex);
    }

    private _urlPreference(): string | null {
        const firstLevelSubdirectory = window.location.pathname.split('/')[1]?.toLowerCase();
        return firstLevelSubdirectory
            && (supportedSubdirectories.indexOf(firstLevelSubdirectory) !== -1)
            ? firstLevelSubdirectory : null;
    }

    public detectLanguagePreference() {
        return this._urlPreference()
            || this._savedPreference()
            || this._navigatorPreference()
            || defaultLanguage;
    }

    public toCamelCase(str: string) {
        return toCamelCase(str);
    }

    public loadResource(url: string, type: string, callback?: (error?: Error | Event) => void) {
        return loadResource(url, type, callback);
    }
}