
export default (moduleInstance, instanceId) => {
    if (!moduleInstance
        || !instanceId) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }

        return;
    }

    if (!moduleInstance.getters) {
        moduleInstance.getters = {};
    }
    moduleInstance.getters.moduleId = () => instanceId;
    moduleInstance.getters.isValid = state => {
        // errors is not a property that this component uses
        if (typeof state.errors === 'undefined') {
            return null;
        }

        return state.errors && state.errors.length === 0;
    };
};