<template>
    <div
        class="lni-c-sharing-widget__wrapper lni-u-screen-only"
        :class="variants"
    >
        <details
            class="lni-c-sharing-widget"
            :open="isOpen"
            @toggle="handleToggle($event)"
        >
            <summary
                class="lni-c-sharing-widget__toggle"
            >
                <span class="lni-c-sharing-widget__toggle-label">{{ content.share }}&nbsp;</span>
                <transition name="lni-o-fade">
                    <span
                        v-if="!isOpen"
                        class="lnicon--share"
                        aria-hidden="true"
                    />
                    <span
                        v-else
                        class="lnicon--close lni-u-type--light"
                        aria-hidden="true"
                    />
                </transition>
            </summary>
            <div class="lni-c-sharing-widget__content lni-u-inline-flex lni-u-content-center">
                <ul class="lni-c-sharing-widget__list">
                    <li
                        v-for="(option, name) in shareButtons"
                        :key="name"
                    >
                        <component
                            :is="option.element"
                            class="lni-c-sharing-widget__link"
                            :type="option.element === 'button' ? 'button' : null"
                            :href="option.href"
                            :label="option.label"
                            v-bind="option"
                            :target="option.href ? '_blank' : null"
                            @click="option.action ? handleShareAction(option.action) : null"
                            @copied="isCopied = true"
                        >
                            <span
                                :class="`lnicon--${option.icon}`"
                                class="lni-c-sharing-widget__link-icon"
                                aria-hidden="true"
                            />
                            <span
                                class="lni-c-sharing-widget__link-text"
                            >
                                <span :class="`lni-u-p${alignment === 'right' ? 'r' : 'l'}2`">{{ isCopied && name === 'copy' ? option.labelAfterCopy : option.label }} <span
                                    v-if="isCopied && name === 'copy'"
                                    class="lnicon--checkmark lni-u-mr0 lni-u-mlauto"
                                    aria-hidden="true"
                                /></span>
                            </span>
                        </component>
                    </li>
                </ul>
            </div>
        </details>
    </div>
</template>

<script>
// TODO: create api for customizing buttons
import lniCopyUrl from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-sharing-widget/copyUrl.vue';
export default {
    name: 'LniSharingWidget',
    components: {
        lniCopyUrl,
    },
    props: {
        toggleLabel: {
            type: String,
            default: 'Share',
        },
    },
    data() {
        return {
            isOpen: false,
            isCopied: false,
        };
    },
    computed: {
        sharingDefaults() {
            return {
                copy: {
                    label: this.content.copy,
                    labelAfterCopy: this.content.copied,
                    element: 'lni-copy-url',
                },
                email: {
                    label: this.content.email,
                    href: this.emailHref,
                    element: 'a',
                    icon: 'mail',
                },
                print: {
                    label: this.content.print,
                    action: 'printPage',
                    element: 'button',
                    icon: 'print',
                },
                tweet: {
                    label: this.content.tweet,
                    element: 'a',
                    icon: 'twitter',
                    href: this.tweetUrl,
                    text: '',
                },
                facebook: {
                    label: this.content.facebook,
                    element: 'a',
                    icon: 'facebook',
                    href: this.facebookUrl,
                },
                native: {
                    label: this.content.shareVia,
                    element: 'button',
                    icon: 'share',
                    action: 'shareNatively',
                },
            };
        },
        shareButtons() {
            const buttons = {};

            this.shareTypes.forEach(type => {
                buttons[type] = {
                    ...this.sharingDefaults[type],
                };
            });
            if (!navigator.share) {
                delete buttons.native;
            }

            return {
                ...buttons,
            };

        },
        emailHref() {
            return `mailto:?subject=${escape(this.emailSubject || document.title)}&body=${escape(window.location.href)}`;
        },
        tweetUrl() {
            // https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/guides/web-intent
            const twitterUrl = 'https://twitter.com/intent/tweet';
            const tweetText = this.content.tweetText ? this.content.tweetText : document.title;
            return `${twitterUrl}?url=${escape(window.location.href)}&text=${escape(tweetText)}`;
        },
        facebookUrl() {
            //https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Flni.wa.gov%2Fnews-events%2Farticle%2F%3Fid%3D22-06
            return `https://www.facebook.com/sharer/sharer.php?u=${escape(window.location.href)}`;
        },
        variants() {
            return {
                '--align-left': this.alignment === 'left',
                '--align-right': this.alignment === 'right',
                '--fixed': this.fixed,
                '--opens-from-top': this.opensFrom === 'top',
            };
        },
    },
    methods: {
        handleShareAction(action) {
            this[action]();
        },
        handleToggle(evt) {
            this.isOpen = evt.target.open;
        },
        onEmailClick() {
            window.location.href = this.emailHref;
        },
        printPage() {
            window.print();
        },
        shareNatively() {
            navigator.share({
                url: window.location.href,
                title: this.shareButtons.native.text || document.title,
            });
        },

    },
};
</script>