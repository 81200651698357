import Framework from '@gov.wa.lni/framework.one-lni.core/source/Framework';
import validateSetupParameters from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/validateSetupParameters';

import {
    Vue,
    VueScrollTo,
} from '@gov.wa.lni/framework.one-lni.core/source/external';

/**
 * Sets up the vue scrollTo plugin for use by the framework and application components.
 * @param framework The framework instance to set up.
 * @param options The framework options to use when configuring.
 */
export default function setupScrollTo(framework: Framework, options: FrameworkOptions) {
    validateSetupParameters(framework, options);

    Vue.use(VueScrollTo, {
        container: 'body',
        duration: 500,
        easing: 'ease',
        offset: -60,
        cancelable: true,
        onDone: false,
        onCancel: false,
        x: false,
        y: true,
    });
}