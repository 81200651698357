/* eslint-disable no-underscore-dangle */
import {
    InterceptorOptions,
} from '@gov.wa.lni/framework.one-lni.core/source/types/InterceptorOptions';

export default class HttpInterceptor {

    private _interceptors: InterceptorOptions[] = [] as InterceptorOptions[];

    private set interceptors(value: InterceptorOptions[]) {
        this._interceptors = value;
    }

    public get interceptors() {
        return this._interceptors;
    }

    constructor(env: any) {
        env.fetch = (fetch => (...args: any) => this.interceptor(fetch, ...args))(fetch);
    }

    private interceptor(fetch: any, ...args: any) {
        const reversedInterceptors = this.interceptors.reduce(
            (array: InterceptorOptions[], interceptor: InterceptorOptions) =>
                [interceptor].concat(array),
            [],
        );
        let promise = Promise.resolve(args);

        reversedInterceptors.forEach(({
            request, requestError,
        }: { request?: any; requestError?: any }) => {
            if (request || requestError) {
                promise = promise.then(args => request(...args), requestError);
            }
        });

        promise = promise.then(
            (args: any) => fetch(...args).then((response: Response) => response)
                .catch((error: any) => Promise.reject(error)));

        reversedInterceptors.forEach(({
            response, responseError,
        }: { response?: any; responseError?: any }) => {
            if (response || responseError) {
                promise = promise.then(response, responseError);
            }
        });

        return promise;
    }

    register(interceptor: InterceptorOptions) {
        this.interceptors.push(interceptor);
        return () => {
            const index = this.interceptors.indexOf(interceptor);
            if (index >= 0) {
                this.interceptors.splice(index, 1);
            }
        };
    }

    clear() {
        this.interceptors = [] as InterceptorOptions[];
    }
}