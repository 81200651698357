export default function setAttribute(state, payload) {
    const moduleState = state[payload.id];
    if (!moduleState) {
        if (window.lni.Vue.config.devtools) {
            throw new Error(`setAttribute could not find the component: ${payload.id} in the store.`);
        }
        return;
    }

    if (moduleState) {
        moduleState[payload.attribute] = payload.value;
    }
}