/* eslint-disable max-len */
export default {
    'lni-checkbox': {
        messages: {
            valueMissing: {
                global: 'ብኽብረትኩም "{legend}{labelText}"፡ ምልክት ግበሩ፡ ኣድላይ እዩ',
                inline: 'ብኽብረትኩም ኣብዚ ምልክት ግበሩ',
            },
        },
    },
    'lni-checkbox-list': {
        messages: {
            valueMissing: {
                global: 'ብኽብረትኩም ን"{legend}{labelText}" ምርጫ ምረፁ፡ ኣድላይ እዩ',
                inline: 'ብኽብረትኩም ምርጫ ምረፁ',
            },
        },
    },
    'lni-error-container': {
        heading: 'ቅድሚ ምቕፃልና፡',
        message: 'ብኽብረትኩም እዞም ዝስዕቡ ስሕተታት ኣስተኻኽሉ',
    },
    'lni-input-phone': {
        messages: {
            patternMismatch: {
                global: 'ብኽብረትኩም ብቑዕ ዝኾነ ቁፅሪ ቴሌፎን ኣእትው (ቅርፂ፡ XXX-XXX-XXXX)',
                inline: 'ብኽብረትኩም ብቑዕ ዝኾነ ቁፅሪ ቴሌፎን ኣእትው (ቅርፂ፡ XXX-XXX-XXXX)',
            },
        },
        phoneHelperText: 'ቅርፂ፡ XXX-XXX-XXXX',
    },
    'lni-input-text': {
        messages: {
            valueMissing: {
                global: 'ብኽብረትኩም "{legend}{labelText}" ኣእትው፡ ኣድላይ እዩ',
                inline: 'ብኽብረትኩም እዚ ፊልድ ምልኡ',
            },
            patternMismatch: {
                global: 'ብኽብረትካ ን"{legend}{labelText}"እቲ ትኽክለኛ ቅርጺ ተጠቐም',
                inline: 'ብኽብረትኩም እቲ ትኽክለኛ ቅዲ ተጠቐሙ{example}',
            },
            typeMismatch: {
                global: 'ብኽብረትካ ን"{legend}{labelText}"እቲ ትኽክለኛ ቅርጺ ተጠቐም',
                inline: 'ብኽብረትኩም እቲ ትኽክለኛ ቅዲ ተጠቐሙ{example}',
            },
            tooShort: {
                global: '"{labelText}" እንተውሓ {num} ካራክተርርስ ንውሓት ክህልዎ ኣለዎ፡፡ ብኽብረትካ ተወሳኺ {difference} ካራክተርስ ወስኽ፡፡',
                inline: '"{labelText}" እንተውሓ {num} ካራክተርርስ ንውሓት ክህልዎ ኣለዎ፡፡ ብኽብረትካ ተወሳኺ {difference} ካራክተርስ ወስኽ፡፡',
            },
        },
    },
    'lni-input-email': {
        messages: {
            valueMissing: {
                global: 'ብኽብረትኩም "{legend}{labelText}" ኣእትው፡ ኣድላይ እዩ',
                inline: 'ብኽብረትኩም እዚ ፊልድ ምልኡ',
            },
            patternMismatch: {
                global: 'ብኽብረትካ ን"{legend}{labelText}" ትኽክለኛ ኢመይል ተጠቐም',
                inline: 'ብኽብረትኩም ብቑዕ ዝኾነ ኢሜይል ኣእትው',
            },
            typeMismatch: {
                global: 'ብኽብረትካ ን"{legend}{labelText}" ትኽክለኛ ኢመይል ተጠቐም',
                inline: 'ብኽብረትኩም ብቑዕ ዝኾነ ኢሜይል ኣእትው',
            },
        },
    },
    'lni-radios': {
        messages: {
            valueMissing: {
                global: 'ብኽብረትኩም ን"{legend}{labelText}" ምርጫ ምረፁ፡ ኣድላይ እዩ',
                inline: 'ብኽብረትኩም ምርጫ ምረፁ',
            },
        },
    },
    'lni-select': {
        messages: {
            valueMissing: {
                global: 'ብኽብረትኩም ን"{legend}{labelText}" ዝኾነ ምረፁ፡ ኣድላይ እዩ',
                inline: 'ብኽብረትኩም ዝኾነ ነገር ምረፁ',
            },
        },
    },
    'lni-spinner': {
        label: 'ኣብ ምጅማር…',
    },
};
/* eslint-enable max-len */