/* eslint-disable max-len */
export default {
    'lni-checkbox': {
        messages: {
            valueMissing: {
                global: 'Fadlan calaamadee "{legend}{labelText}", waa loo baahan yahay',
                inline: 'Fadlan goobtan calaamadee',
            },
        },
    },
    'lni-checkbox-list': {
        messages: {
            valueMissing: {
                global: 'Fadlan samee doorashada "{legend}{labelText}", waa loo baahan yahay',
                inline: 'Fadlan doorasho samee',
            },
        },
    },
    'lni-error-container': {
        heading: 'Ka hor inta aanan sii wadin:',
        message: 'Fadlan khaladaadkan soo socda sax',
    },
    'lni-input-phone': {
        messages: {
            patternMismatch: {
                global: 'Fadlan geli lambar telefoon sax ah (Qaabka: XXX-XXX-XXXX)',
                inline: 'Fadlan geli lambar telefoon sax ah (Qaabka: XXX-XXX-XXXX)',
            },
        },
        phoneHelperText: 'Qaabka: XXX-XXX-XXXX',
    },
    'lni-input-text': {
        messages: {
            valueMissing: {
                global: 'Fadlan geli "{legend}{labelText}", waa loo baahan yahay',
                inline: 'Fadlan buuxi goobtan',
            },
            patternMismatch: {
                global: 'Fadlan qaabka saxda ah u isticmaal "{legend}{labelText}"',
                inline: 'Fadlan isticmaal qaabka saxda ah{example}',
            },
            typeMismatch: {
                global: 'Fadlan qaabka saxda ah u isticmaal "{legend}{labelText}"',
                inline: 'Fadlan isticmaal qaabka saxda ah{example}',
            },
            tooShort: {
                global: '"{labelText}" waa in uu ugu yaraan dhererkiisu ahaadaa {num} xarfood. Fadlan xarfo dheeraad ah kuddar {difference}.',
                inline: '"{labelText}" waa in uu ugu yaraan dhererkiisu ahaadaa {num} xarfood. Fadlan xarfo dheeraad ah kuddar {difference}.',
            },
        },
    },
    'lni-input-email': {
        messages: {
            valueMissing: {
                global: 'Fadlan geli "{legend}{labelText}", waa loo baahan yahay',
                inline: 'Fadlan buuxi goobtan',
            },
            patternMismatch: {
                global: 'Fadlan email sax ah u isticmaal "{legend}{labelText}"',
                inline: 'Fadlan isticmaal email sax ah',
            },
            typeMismatch: {
                global: 'Fadlan email sax ah u isticmaal "{legend}{labelText}"',
                inline: 'Fadlan isticmaal email sax ah',
            },
        },
    },
    'lni-radios': {
        messages: {
            valueMissing: {
                global: 'Fadlan samee doorashada "{legend}{labelText}", waa loo baahan yahay',
                inline: 'Fadlan doorasho samee',
            },
        },
    },
    'lni-select': {
        messages: {
            valueMissing: {
                global: 'Fadlan dooro wax loogu talagalay "{legend}{labelText}", waa loo baahan yahay',
                inline: 'Fadlan wax ka dooro',
            },
        },
    },
    'lni-spinner': {
        label: 'Rarmaya ama socda…',
    },
};
/* eslint-enable max-len */