export default () => ({
    state: {
        isUrlMapped: false,
        classString: '',
        parentTitle: '',
        currentTitle: '',
        navigation: null,
        isLoaded: false,
        context: '',
    },
});