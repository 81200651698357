import {
    getCurrentLocation,
} from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure/actions/getCurrentLocation.js';
import { fakeCheckLogInResponse } from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure/mockApi';
export const getHeaderData = (callback, oneLni) => {
    const currentEnvironment = getCurrentLocation(oneLni);
    const headerDataURL = `${currentEnvironment}/ewn/v4/GatewayProxy/UiProxy.aspx?op=CheckLogIn`;
    if (oneLni.environment.current.name === 'Local Development') {
        callback(fakeCheckLogInResponse);
    } else {
        fetch(headerDataURL, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-type': 'application/json',
            },
        }).then(response => response.json())
            .then(data => {
                callback(data);
            });
    }
};