/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */

import map from '@gov.wa.lni/framework.one-lni.core/source/environment/map';
import script, { ScriptSearchResult } from '@gov.wa.lni/framework.one-lni.core/source/lib/script';
import Environment from '@gov.wa.lni/framework.one-lni.core/source/environment/Environment';

export type CurrentEnvironment = {
    /**
     * The current Environment detected from the environment map.
     */
    readonly current: Environment;
    /**
     * The url for the script used to load one-lni
     */
    readonly scriptLocation: string;

    /**
     * The hostname used to load one-lni.
     */
    readonly rootLocation: string;
};

const currentEnvironment = map.detect();

if (currentEnvironment.display
    && !currentEnvironment.isUnitTestEnvironment) {
    // eslint-disable-next-line no-console
    console.log(currentEnvironment);
}

// Assumes that this script is always bundled into oneLni.js
const scriptLocation: ScriptSearchResult = script('oneLni.js')!;

const _currentEnvironment: CurrentEnvironment = {
    get current(): Environment {
        return currentEnvironment;
    },

    get scriptLocation(): string {
        return scriptLocation.source;
    },

    get rootLocation(): string {
        return scriptLocation.root;
    },
};

export default _currentEnvironment;