import mutations from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/component/mutations';

export default moduleInstance => {
    if (!moduleInstance) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }

        return;
    }

    if (!moduleInstance.mutations) {
        moduleInstance.mutations = {};
    }
    moduleInstance.mutations.setValidity = mutations.setValidity;
    moduleInstance.mutations.setDirty = mutations.setDirty;
    moduleInstance.mutations.initializeValidationState = mutations.initializeValidationState;
    moduleInstance.mutations.addValidityTest = mutations.addValidityTest;
    moduleInstance.mutations.setValidityTestValue = mutations.setValidityTestValue;
    moduleInstance.mutations.customizeValidationMessage
        = mutations.customizeValidationMessage;
};