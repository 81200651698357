<template>
    <div class="lni-c-side-nav">
        <span class="lni-c-side-nav__heading lni-u-flex lni-u-pv2 lni-u-text--all-caps">
            <span
                id="sideMenuId"
                :class="[`lnicon--${menuIcon}`,'lni-u-type--sm']"
                aria-hidden="true"
                class="lni-u-pa2 lni-u-pl0"
            />
            <span class="lni-u-self-center">{{ currentTitle }}</span>
        </span>
        <ul class="lni-u-list-reset lni-u-pb3">
            <lni-side-nav-item
                v-for="(item, index) in navigation"
                :id="`side-nav-item_level-0_${index}`"
                :key="`side-nav-item_level-0_${index}`"
                :item="item"
                :index="index"
                :level="1"
                :context="`side-nav`"
            />
        </ul>
    </div>
</template>

<script>
import * as api from '@gov.wa.lni/framework.one-lni.navigation-components/source/navigation.api.js';

export default {
    name: 'LniSideNav',
    data() {
        return {
            active: [],
        };
    },
    computed: {
        menuIcon() {
            return this.getIcon(this.parentTitle);
        },
    },
    mounted() {
        this.$nextTick(() => {
            if (this.navigation === null) {
                this.getNavigation();
            }
        });
    },
    methods: {
        //TODO: consider less fragile ways of doing this (data in dotCMS?)
        getIcon(type) {
            switch (type) {
                case 'Safety & Health':
                    return 'safety-health';
                case 'Claims':
                    return 'claims';
                case 'Patient Care':
                    return 'patient-care';
                case 'Insurance':
                    return 'insurance';
                case 'Workers\' Rights':
                    return 'rights';
                case 'Licensing & Permits':
                    return 'licensing-permits';
                default:
                    return 'home';
            }
        },
        getNavigation() {
            api.getNavigation(null, this.$oneLni, this.isUrlMapped).then(data => {
                this.updateData(data);
            });
        },
        updateData(data) {
            this.currentTitle = data.leftNavigationTitle;
            this.parentTitle = data.leftNavigationSectionTitle;
            const leftNavigation = data.leftNavigation;
            leftNavigation.forEach(item => {
                let hierarchyActive = item.active === 'true';
                if (item.children) {
                    item.children.forEach(subItem => {
                        if (subItem.active === 'true') {
                            hierarchyActive = true;
                        }
                    });
                }
                item.hierarchyActive = hierarchyActive;
            });

            this.$store.commit('setAttribute', {
                id: this.id,
                attribute: 'navigation',
                value: leftNavigation,

            });

            this.$store.commit('setAttribute', {
                id: this.id,
                attribute: 'isLoaded',
                value: true,
            });
        },
    },
}; </script>