export default (context, payload) => new Promise(resolve => {
    context.dispatch('reportToSelf', payload, {
        root: true,
    }).then(() => {
        context.dispatch('reportUp', payload, {
            root: true,
        }).then(() => {
            resolve();
        });
    });
});