/* eslint-disable max-len */
export default {
    'lni-checkbox': {
        messages: {
            valueMissing: {
                global: '若必要，請核選「{legend}{labelText}」',
                inline: '請核選該欄位',
            },
        },
    },
    'lni-checkbox-list': {
        messages: {
            valueMissing: {
                global: '若必要，請為「{legend}{labelText}」做出選擇',
                inline: '請做出選擇',
            },
        },
    },
    'lni-error-container': {
        heading: '我們繼續之前：',
        message: '請修復下列錯誤',
    },
    'lni-input-phone': {
        messages: {
            patternMismatch: {
                global: '請輸入有效的電話號碼 (格式：XXX-XXX-XXXX)',
                inline: '請輸入有效的電話號碼 (格式：XXX-XXX-XXXX)',
            },
        },
        phoneHelperText: '格式：XXX-XXX-XXXX',
    },
    'lni-input-text': {
        messages: {
            valueMissing: {
                global: '若必要，請輸入「{legend}{labelText}」',
                inline: '請填寫此字段',
            },
            patternMismatch: {
                global: '請使用「{legend}{labelText}」的正確格式',
                inline: '請使用正確的格式{example}',
            },
            typeMismatch: {
                global: '請使用「{legend}{labelText}」的正確格式',
                inline: '請使用正確的格式{example}',
            },
            tooShort: {
                global: '"{labelText}"必須至少{num}個字符長。請多增{difference}個字符。',
                inline: '"{labelText}"必須至少{num}個字符長。請多增{difference}個字符。',
            },
        },
    },
    'lni-input-email': {
        messages: {
            valueMissing: {
                global: '若必要，請輸入「{legend}{labelText}」',
                inline: '請填寫此字段',
            },
            patternMismatch: {
                global: '請使用「{legend}{labelText}」的有效電郵',
                inline: '請輸入有效的電子郵件',
            },
            typeMismatch: {
                global: '請使用「{legend}{labelText}」的有效電郵',
                inline: '請輸入有效的電子郵件',
            },
        },
    },
    'lni-radios': {
        messages: {
            valueMissing: {
                global: '若必要，請為「{legend}{labelText}」做出選擇',
                inline: '請做出選擇',
            },
        },
    },
    'lni-select': {
        messages: {
            valueMissing: {
                global: '若必要，請為「{legend}{labelText}」選擇些許選項',
                inline: '請選擇些許選項',
            },
        },
    },
    'lni-spinner': {
        label: '正在載入…',
    },
};
/* eslint-enable max-len */