/* eslint-disable max-len */
export default {
    'lni-checkbox': {
        messages: {
            valueMissing: {
                global: 'Отметьте "{legend}{labelText}", это обязательное условие',
                inline: 'Отметьте это поле',
            },
        },
    },
    'lni-checkbox-list': {
        messages: {
            valueMissing: {
                global: 'Выберите вариант в пункте "{legend}{labelText}", это обязательное условие',
                inline: 'Выберите один из вариантов',
            },
        },
    },
    'lni-error-container': {
        heading: 'Перед тем как мы продолжим:',
        message: 'Пожалуйста, исправьте следующие ошибки',
    },
    'lni-input-phone': {
        messages: {
            patternMismatch: {
                global: 'Укажите действительный номер телефона (Формат XXX-XXX-XXXX)',
                inline: 'Укажите действительный номер телефона (Формат XXX-XXX-XXXX)',
            },
        },
        phoneHelperText: 'Формат XXX-XXX-XXXX',
    },
    'lni-input-text': {
        messages: {
            valueMissing: {
                global: 'Выберите вариант в пункте "{legend}{labelText}", это обязательное условие',
                inline: 'Пожалуйста, заполните это поле',
            },
            patternMismatch: {
                global: 'Используйте правильный формат для "{legend}{labelText}"',
                inline: 'Пожалуйста, используйте правильный формат{example}',
            },
            typeMismatch: {
                global: 'Используйте правильный формат для "{legend}{labelText}"',
                inline: 'Пожалуйста, используйте правильный формат{example}',
            },
            tooShort: {
                global: '"{labelText}" должен содержать не менее {num} символов. Добавьте еще {difference} символов.',
                inline: '"{labelText}" должен содержать не менее {num} символов. Добавьте еще {difference} символов.',
            },
        },
    },
    'lni-input-email': {
        messages: {
            valueMissing: {
                global: 'Выберите вариант в пункте "{legend}{labelText}", это обязательное условие',
                inline: 'Пожалуйста, заполните это поле',
            },
            patternMismatch: {
                global: 'Используйте действительный адрес электронной почты для "{legend}{labelText}"',
                inline: 'Укажите действительный адрес электронной почты',
            },
            typeMismatch: {
                global: 'Используйте действительный адрес электронной почты для "{legend}{labelText}"',
                inline: 'Укажите действительный адрес электронной почты',
            },
        },
    },
    'lni-radios': {
        messages: {
            valueMissing: {
                global: 'Выберите вариант в пункте "{legend}{labelText}", это обязательное условие',
                inline: 'Выберите один из вариантов',
            },
        },
    },
    'lni-select': {
        messages: {
            valueMissing: {
                global: 'Выберите какой-либо вариант "{legend}{labelText}", это обязательное условие',
                inline: 'Выберите какой-либо вариант',
            },
        },
    },
    'lni-spinner': {
        label: 'Загрузка...',
    },
};
/* eslint-enable max-len */