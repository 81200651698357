// Add #lniMainContent to your main area of app
//      * Apps built with the new spa template will already have this
// Add this mixin to application-main component of an app
//      * Apps built with the new spa template will already have this
// Requires that the meta object is populated with a title property if you want the title
//  to appear in the announcement of a route change.  Otherwise, it will just announce "navigated"

export default {
    inheritAttrs: false,
    watch: {
        $route(to) {
            this.$nextTick(() => {
                this.initializeApplicationMain();

                const title = to.meta && to.meta.title
                    ? to.meta.title
                    : '';
                this.setRouteAnnouncement(title);

                if (title) {
                    document.title = title;
                }
                this.setRouteWrapperFocus();
            });
        },
    },
    methods: {
        initializeApplicationMain() {
            const app = this.$el;
            if (!app.hasAttribute('data-is-initialized')) {
                const routeAnnouncer = document.createElement('div');
                routeAnnouncer.setAttribute('id', 'routeAnnouncer');
                routeAnnouncer.setAttribute('aria-live', 'polite');
                routeAnnouncer.classList.add('lni-u-visually-hidden');
                app.insertBefore(routeAnnouncer, app.firstChild);

                let mainContent = document.getElementById('lniMainContent');
                if (!mainContent) {
                    mainContent = document.createElement('span');
                    mainContent.setAttribute('id', 'lniMainContent');
                    mainContent.setAttribute('tabindex', '-1');

                    // assumes app has followed the spa template and has an "application-main" div
                    const applicationMain = document.getElementById('application-main');
                    applicationMain.insertBefore(mainContent, applicationMain.firstChild);
                }

                app.setAttribute('data-is-initialized', 'true');
            }

        },
        setRouteWrapperFocus() {
            const mainContent = document.getElementById('lniMainContent');
            if (mainContent) {
                mainContent.focus();
            }
        },
        setRouteAnnouncement(pageTitle) {
            const routeAnnouncement = pageTitle
                ? `Navigated to ${pageTitle}`
                : 'Navigated';
            const routeAnnouncer = document.getElementById('routeAnnouncer');
            if (routeAnnouncer) {
                routeAnnouncer.textContent = routeAnnouncement;
            }
        },
    },
};