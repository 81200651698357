
import * as audience from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure/audiences.js';

export const getRelationShipCode = relationshipCode => {
    let code = '';
    for (let audienceObj of audience.audiences) {
        for (let audienceRelationship of audienceObj.relationships) {
            if (audienceRelationship === relationshipCode
                && code === '') {
                code = audienceObj.ui_code;
            }
        }
    }
    return code;
};

export const getTabName = relationshipCode => {
    let tabName = '';
    for (let audienceObj of audience.audiences) {
        for (let audienceRelationship of audienceObj.relationships) {
            if (audienceRelationship === relationshipCode
                && tabName === '') {
                tabName = audienceObj.tab_name;
            }
        }
    }
    return tabName;
};