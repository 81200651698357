let activeFramework = null;

export default () => {
    if (activeFramework) {
        // TODO: for some reason the old version keeps getting overwritten on the oneLni variable.
        // I haven't been able to find where.  This will set it back.
        // However we already have the new version cached, so keep using it.
        activeFramework.noConflict();
    } else {
        const framework = window.oneLni;
        activeFramework = framework.noConflict();
    }
    return activeFramework;
};