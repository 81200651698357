/* eslint-disable no-underscore-dangle */

// cache oneLni in case this version is overwriting one that is already loaded.
if (!window._oneLni) {
    window._oneLni = window.oneLni;
}

export default () => {
    // This means we've overwritten the existing framework and it is a different
    // oneLni version.  Put it back to the cached(1st) version.
    // Then we return our overwriting version so that an app can still use it if
    // they want to.  It is used by the activeFramework module.
    const currentFramework = window.oneLni;
    if (window._oneLni && (window._oneLni.version.major !== window.oneLni.version.major)) {
        window.oneLni = window._oneLni;
    }

    return currentFramework;
};