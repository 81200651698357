import updateDependencyStatus from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/dependency/updateDependencyStatus.js';

export default function waitForDependencyScript(dependencyState, dependencyType, callback) {
    if (!dependencyState || !dependencyType) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }

        return;
    }

    if (dependencyState[dependencyType].required) {
        if (window[dependencyType]) {
            if (updateDependencyStatus(dependencyState, dependencyType)) {
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                callback && callback();
            }
        } else {
            dependencyState[dependencyType].scriptElement.addEventListener('load', () => {
                if (updateDependencyStatus(dependencyState, dependencyType)) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    callback && callback();
                }
            });

            dependencyState[dependencyType].scriptElement.addEventListener('error', error => {
                if (updateDependencyStatus(dependencyState, dependencyType)) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    callback && callback(error);
                }
            });
        }
    }
}