const candidateSelectors = [
    'input',
    'select',
    'textarea',
    'a[href]',
    'button',
    '[tabindex]',
    'audio[controls]',
    'video[controls]',
    '[contenteditable]:not([contenteditable="false"])',
];

const candidateSelector = candidateSelectors.join(',');

const matches = typeof Element === 'undefined'
    // eslint-disable-next-line no-empty-function
    ? () => { }
    : Element.prototype.matches
    || Element.prototype.msMatchesSelector
    || Element.prototype.webkitMatchesSelector;

const isContentEditable = node => node.contentEditable === 'true';

const isInput = node => node.tagName === 'INPUT';

const isHiddenInput = node => isInput(node) && node.type === 'hidden';

const isRadio = node => isInput(node) && node.type === 'radio';

const getCheckedRadio = nodes => {
    for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].checked) {
            return nodes[i];
        }
    }
    return null;
};

const isTabbableRadio = node => {
    if (!node.name) {
        return true;
    }
    // This won't account for the edge case where you have radio groups with the same name
    // in separate forms on the same page.
    const radioSet = node.ownerDocument.querySelectorAll(
        'input[type="radio"][name="' + node.name + '"]',
    );
    const checked = getCheckedRadio(radioSet);
    return !checked || checked === node;
};

const isNonTabbableRadio = node => isRadio(node) && !isTabbableRadio(node);

const isHidden = node =>
    // offsetParent being null will allow detecting cases where an element
    // is invisible or inside an invisible element,
    // as long as the element does not use position: fixed.
    // For them, their visibility has to be checked directly as well.
    node.offsetParent === null || getComputedStyle(node).visibility === 'hidden';

const getTabindex = node => {
    const tabindexAttr = parseInt(node.getAttribute('tabindex'), 10);
    if (!isNaN(tabindexAttr)) {
        return tabindexAttr;
    }
    // Browsers do not return `tabIndex` correctly for contentEditable nodes;
    // so if they don't have a tabindex attribute specifically set, assume it's 0.
    if (isContentEditable(node)) {
        return 0;
    }
    return node.tabIndex;
};


const isNodeMatchingSelectorFocusable = node => {
    if (
        node.disabled
        || isHiddenInput(node)
        || isHidden(node)
    ) {
        return false;
    }
    return true;
};

const isNodeMatchingSelectorTabbable = node => {
    if (
        !isNodeMatchingSelectorFocusable(node)
        || isNonTabbableRadio(node)
        || getTabindex(node) < 0
    ) {
        return false;
    }
    return true;
};

const isTabbable = node => {
    if (!node) {
        throw new Error('No node provided');
    }
    if (matches.call(node, candidateSelector) === false) {
        return false;
    }
    return isNodeMatchingSelectorTabbable(node);
};


const focusableCandidateSelector = candidateSelectors.concat('iframe').join(',');
const isFocusable = node => {
    if (!node) {
        throw new Error('No node provided');
    }
    if (matches.call(node, focusableCandidateSelector) === false) {
        return false;
    }
    return isNodeMatchingSelectorFocusable(node);
};

const sortOrderedTabbables = (a, b) => a.tabIndex === b.tabIndex
    ? a.documentOrder - b.documentOrder
    : a.tabIndex - b.tabIndex;

const tabbable = (el, initialOptions) => {
    const options = initialOptions || {};

    const regularTabbables = [];
    const orderedTabbables = [];

    let candidates = el.querySelectorAll(candidateSelector);

    if (options.includeContainer) {
        if (matches.call(el, candidateSelector)) {
            candidates = Array.prototype.slice.apply(candidates);
            candidates.unshift(el);
        }
    }

    let i, candidate, candidateTabindex;
    for (i = 0; i < candidates.length; i++) {
        candidate = candidates[i];

        if (!isNodeMatchingSelectorTabbable(candidate)) {
            continue;
        }

        candidateTabindex = getTabindex(candidate);
        if (candidateTabindex === 0) {
            regularTabbables.push(candidate);
        } else {
            orderedTabbables.push({
                documentOrder: i,
                tabIndex: candidateTabindex,
                node: candidate,
            });
        }
    }

    const tabbableNodes = orderedTabbables
        .sort(sortOrderedTabbables)
        .map(a => a.node)
        .concat(regularTabbables);

    return tabbableNodes;
};

tabbable.isTabbable = isTabbable;
tabbable.isFocusable = isFocusable;

export default tabbable;