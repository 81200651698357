export default (context, payload) => new Promise(resolve => {
    if (!context || !payload || !payload.targetId) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }
        resolve();
        return;
    }

    // The 'collectingErrorsFrom' list must come from the calling component or it will come from
    // an associated lni-error-container.  If this is the case, the lni-error-container must be
    // specified.

    const moduleState = context.rootState[payload.targetId];
    if (!moduleState) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('The component\'s store module was not found.');
        }
        resolve();
        return;
    }

    const errorsCollectedFrom = moduleState.errorsCollectedFrom;
    if (!errorsCollectedFrom || !Array.isArray(errorsCollectedFrom)) {
        if (window.lni.Vue.config.devtools) {
            throw new Error(`The component's store module does not have a valid errorsCollectedFrom property.`);
        }
        resolve();
        return;
    }

    const isErrorContainer = moduleState.isErrorContainer;

    // This is the component that will collect any errors on the descendant component
    const errorsCollectedBy = payload.targetId;

    const childProcessingPromises = [];

    errorsCollectedFrom.forEach(id => {
        const childProcessingPromise = new Promise(innerResolve => {
            let innerErrorsCollectedFrom = context.rootState[id].errorsCollectedFrom;
            let promises = [];
            if (innerErrorsCollectedFrom) {
                innerErrorsCollectedFrom.forEach(innerId => {
                    promises.push(
                        context.dispatch('reportToSelf', {
                            targetId: innerId,
                            validation: {
                                errorsCollectedBy: id,
                            },
                        }),
                    );
                });
            }

            Promise.all(promises).then(() => {
                const collectionPromises = [];
                if (context.rootState[id].errorsCollectedFrom) {
                    collectionPromises.push(context.dispatch('setErrorsOnSelf', {
                        targetId: id,
                    }));
                }

                Promise.all(collectionPromises).then(() => {
                    const customValidationPromises = [];
                    const customValidationAction = context.rootState[id].customValidationAction;
                    if (customValidationAction) {
                        customValidationPromises.push(
                            context.dispatch(`${id}/${customValidationAction}`, {
                                targetId: id,
                            }),
                        );
                    }
                    Promise.all(customValidationPromises).then(() => {
                        context.dispatch('reportToSelf', {
                            targetId: id,
                            validation: {
                                errorsCollectedBy,
                            },
                        }).then(() => {
                            innerResolve();
                        });
                    });
                });
            });
        });
        childProcessingPromises.push(childProcessingPromise);
    });

    Promise.all(childProcessingPromises).then(() => {
        if (isErrorContainer) {
            context.dispatch(`${payload.targetId}/activate`);
        }
        resolve();
    });
});