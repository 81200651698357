export default (id, moduleState, onlyOwn) => {
    if (!id || !moduleState) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }

        return null;
    }

    if (!Array.isArray(moduleState.errors)) {
        return false;
    }

    if (onlyOwn) {
        return moduleState.errors.some(error => error.id === id);
    }

    return moduleState.errors.length > 0;
};