import LniFooter from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-footer';
import LniFunctionalFooter from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-functional-footer';
import LniHeaderAnonymous from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-anonymous';
import LniHeaderSecure from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure';
import LniHeaderBasic from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-simple';
import LniSideNav from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-side-nav';
import LniSideNavItem from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-side-nav-item';
import LniBreadcrumb from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-breadcrumb';
import LniSiteAlert from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-site-alert';
import LniSharingWidget from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-sharing-widget';

export default [
    LniFooter,
    LniFunctionalFooter,
    LniHeaderAnonymous,
    LniHeaderSecure,
    LniHeaderBasic,
    LniSideNav,
    LniSideNavItem,
    LniBreadcrumb,
    LniSiteAlert,
    LniSharingWidget,
];