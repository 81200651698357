<template>
    <div
        v-if="alertTypes.length || custom"
        class="lni-c-site-alert__container lni-u-pv1 lni-u-ph1"
    >
        <div
            v-for="alert in alertTypes"
            :key="alert + '_alert'"
            class="lni-c-informational-message --site-alert lni-u-flex lni-u-items-center lni-u-ph2 lni-u-shadow--sm"
            :class="alert === 'active' ? '--warn' : '--info'"
            role="alert"
        >
            <span
                class="lni-u-mr2 lni-c-site-alert__icon"
                :class="alert === 'active' ? 'lnicon--exclamation' : 'lnicon--info'"
                aria-hidden="true"
            />
            <a :href="`${origin}${statusPageUrl}`">
                <span v-if="alert === 'active'">{{ messages.activeOutageMessage }}</span>
                <span v-else>{{ messages.scheduledOutageMessage }}</span>
            </a>
            <button
                class="lni-c-site-alert__dismiss lni-u-button-reset lni-u-mr0 lni-u-mlauto lni-u-flex lni-u-items-center"
                @click="dismissAlerts(alert)"
            >
                <span
                    class="lnicon--close"
                    aria-hidden="true"
                />
                <span
                    class="lni-c-site-alert__dismiss-tooltip lni-u-type--xxs lni-u-ph1"
                    :class="alert === 'active' ? '--warn' : '--info'"
                >
                    Don't show again
                </span>
            </button>
        </div>
        <div
            v-if="custom && !customIsDismissed"
            class="lni-u-pv1 lni-u-ph1"
        >
            <div
                class=" lni-c-informational-message --site-alert
                    lni-u-flex lni-u-items-center lni-u-ph2
                    lni-u-shadow--sm --custom"
                role="alert"
            >
                <lni-html-element
                    id="customMessage"
                    :htmlString="custom.message || custom.customMessage"
                    class="lni-u-mv2"
                />
                <button
                    class="lni-c-site-alert__dismiss lni-u-button-reset lni-u-mr0 lni-u-mlauto lni-u-flex lni-u-items-center"
                    @click="dismissAlerts('custom')"
                >
                    <span
                        class="lnicon--close"
                        aria-hidden="true"
                    />
                    <span class="lni-c-site-alert__dismiss-tooltip lni-u-type--xxs lni-u-ph1">
                        Don't show again
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import * as api from '@gov.wa.lni/framework.one-lni.navigation-components/source/navigation.api.js';

const dismissedKey = 'lni-dismissed-messages';
export default {
    name: 'LniSiteAlert',
    computed: {
        relevantAlerts() {
            return this.alerts.filter(alert => alert.type !== 'custom' || this.isPageSpecific(alert));
        },
        active() {
            return this.relevantAlerts.filter(alert => alert.status === 'active');
        },
        scheduled() {
            return this.relevantAlerts.filter(alert => alert.status === 'scheduled');
        },
        custom() {
            return this.alerts.find(alert => alert.type === 'custom' && alert.customMessage
                && !this.dismissed.includes(alert.inode));
        },
        alertTypes() {
            const alertTypes = [];
            if (this.active.length && !this.activeIsDismissed) {
                alertTypes.push('active');
            }
            if (this.scheduled.length && !this.scheduledIsDismissed) {
                alertTypes.push('scheduled');
            }
            return alertTypes;
        },
        activeIds() {
            return this.active.map(alert => alert.inode);
        },
        scheduledIds() {
            return this.scheduled.map(alert => alert.inode);
        },
        activeIsDismissed() {
            return this.activeIds.filter(inode => !this.dismissed.includes(inode)).length === 0;
        },
        scheduledIsDismissed() {
            return this.scheduledIds.filter(inode => !this.dismissed.includes(inode)).length === 0;
        },
        customIsDismissed() {
            return this.dismissed.includes(this.custom.inode);
        },
        url() {
            return window.location.href;
        },
    },
    created() {
        const env = this.$oneLni.environment.current;
        if (env.type === 'development') {
            this.multilingualEnabled = true;
        }
        this.getAlerts();
        this.syncWithLocalStore();
    },
    methods: {
        getAlerts() {
            api.getAlerts(null, this.$oneLni).then(data => {
                this.updateData(data);
            });
        },
        updateData(data) {
            if (Array.isArray(data.results)) {
                //new api
                this.alerts = data.results;
            } else {
                this.handleLegacyApi(data);
            }
            this.statusPageURL = data.statusPageURL;
        },
        handleLegacyApi(data) {
            const active = data.results.active.map(alert => ({
                ...alert,
                status: 'active',
            }));
            const scheduled = data.results.scheduled.map(alert => ({
                ...alert,
                status: 'scheduled',
            }));
            const custom = {
                ...data.results.custom,
                type: 'custom',
            };
            this.alerts = [...active, ...scheduled, custom];
            this.messages = data.results.messages;
        },
        isPageSpecific(alert) {
            return alert.paths && alert.paths.some(path => this.url.includes(path));
        },
        async syncWithLocalStore() {
            const savedDismissals = await this.$oneLni.storage.persistent.load(dismissedKey);
            if (savedDismissals) {
                this.dismissed = savedDismissals;
            }
        },
        dismissAlerts(type) {
            if (type === 'active') {
                this.dismissed = [...this.dismissed, ...this.activeIds];
            } else if (type === 'scheduled') {
                this.dismissed = [...this.dismissed, ...this.scheduledIds];
            } else if (type === 'custom') {
                this.dismissed = [...this.dismissed, this.custom.inode];
            }
            this.$oneLni.storage.persistent.save(dismissedKey, this.dismissed);
        },

    },
}; </script>