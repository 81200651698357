
export default context => {
    if (!context) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }

        return;
    }

    const moduleId = context.getters.moduleId;

    const moduleState = context.rootState[moduleId];
    if (!moduleState) {
        if (window.lni.Vue.config.devtools) {
            throw new Error(`The component's store module is not present.`);
        }

        return;
    }

    const errorsCollectedFrom = moduleState.errorsCollectedFrom;
    if (!Array.isArray(errorsCollectedFrom)) {
        return;
    }

    const processed = errorsCollectedFrom.map(errorsCollectedFromItem => {
        let processedItem;
        if (typeof errorsCollectedFromItem === 'function') {
            processedItem = errorsCollectedFromItem(moduleId);
        } else {
            processedItem = errorsCollectedFromItem;
        }
        return processedItem;
    });

    context.commit('setAttribute', {
        id: moduleId,
        attribute: 'errorsCollectedFrom',
        value: processed,
    }, {
        root: true,
    });
};