export default {
    bind(el, binding, vnode) {
        el.clickOutsideEvent = event => {
            if (
                el !== event.target
                && !el.contains(event.target)
            ) {
                let inComposedPath = false;
                if (event.composedPath) {
                    const composedPath = event.composedPath();
                    composedPath.forEach(element => {
                        if (el === element) {
                            inComposedPath = true;
                        }
                    });
                }

                if (!inComposedPath) {
                    vnode.context[binding.expression](event);
                }
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },

    unbind(el) {
        el.removeEventListener('click', el.clickOutsideEvent);
    },
};