import {
    getCurrentLocation,
} from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure/actions/getCurrentLocation.js';

export const getCorrespondenceInformation = (callback, oneLni) => {
    const requestBody = {
        MethodName: 'GetCorrespondenceInformation',
        Params: [],
        ServiceName: 'EWNMenuService',
    };

    const currentEnvironment = getCurrentLocation(oneLni);
    const submitUrl
        = `${currentEnvironment}/ewn/v4/GatewayProxy/UiProxy.aspx/UiGatewayRestMethod`;

    fetch(submitUrl, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify(requestBody),
    }).then(response => response.json())
        .then(data => {
            let returnData = JSON.parse(data.d);
            callback(returnData.returnTypeField.UnreadEcorreMessages);
        });
};