/* eslint-disable max-len */
export default {
    'lni-checkbox': {
        messages: {
            valueMissing: {
                global: 'እባክህ "{legend}{labelText}" ምልክት ያድርጉ፣ ያስፈልጋል',
                inline: 'እባክዎትን ይህንን መስክ ያረጋግጡ',
            },
        },
    },
    'lni-checkbox-list': {
        messages: {
            valueMissing: {
                global: 'እባክዎትን ለ"{legend}{labelText}" ይምረጡ፣ ያስፈልጋል',
                inline: 'እባክዎትን ይምረጡ',
            },
        },
    },
    'lni-error-container': {
        heading: 'ከመቀጠላችን በፊት፡',
        message: 'እባክዎትን የሚከተሉትን ስህተቶች ያስተካክሉ',
    },
    'lni-input-phone': {
        messages: {
            patternMismatch: {
                global: 'እባክዎትን የሚሰራ ስልክ ቁጥር ያስገቡ (ፎርማት፡ XXX-XXX-XXXX)',
                inline: 'እባክዎትን የሚሰራ ስልክ ቁጥር ያስገቡ (ፎርማት፡ XXX-XXX-XXXX)',
            },
        },
        phoneHelperText: 'ፎርማት፡ XXX-XXX-XXXX',
    },
    'lni-input-text': {
        messages: {
            valueMissing: {
                global: 'እባክዎትን "{legend}{labelText}" ያስገቡ፣ ያስፈልጋል',
                inline: 'እባክዎ ይህንን መስክ ይሙሉ',
            },
            patternMismatch: {
                global: 'እባክዎ ለ "{legend}{labelText}" ትክክለኛውን ፎረማት ይጠቀሙ',
                inline: 'እባክዎ ትክክለኛውን ቅርጸት ይጠቀሙ{example}',
            },
            typeMismatch: {
                global: 'እባክዎ ለ "{legend}{labelText}" ትክክለኛውን ፎረማት ይጠቀሙ',
                inline: 'እባክዎ ትክክለኛውን ቅርጸት ይጠቀሙ{example}',
            },
            tooShort: {
                global: '"{labelText}" ርዝመት ቢያንስ {num} ካራክተሮች መሆን አለበት። እባክህ {difference} ተጨማሪ ካራክተሮችን ይጨምሩ።',
                inline: '"{labelText}" ርዝመት ቢያንስ {num} ካራክተሮች መሆን አለበት። እባክህ {difference} ተጨማሪ ካራክተሮችን ይጨምሩ።',
            },
        },
    },
    'lni-input-email': {
        messages: {
            valueMissing: {
                global: 'እባክዎትን "{legend}{labelText}" ያስገቡ፣ ያስፈልጋል',
                inline: 'እባክዎ ይህንን መስክ ይሙሉ',
            },
            patternMismatch: {
                global: 'እባክዎ ለ "{legend}{labelText}" ትክክለኛ ኢሜይል ይጠቀሙ',
                inline: 'እባክዎትን ትክክለኛ ኢሜይል ያስገቡ{example}',
            },
            typeMismatch: {
                global: 'እባክዎ ለ "{legend}{labelText}" ትክክለኛ ኢሜይል ይጠቀሙ',
                inline: 'እባክዎትን ትክክለኛ ኢሜይል ያስገቡ{example}',
            },
        },
    },
    'lni-radios': {
        messages: {
            valueMissing: {
                global: 'እባክዎትን ለ"{legend}{labelText}" ይምረጡ፣ ያስፈልጋል',
                inline: 'እባክዎትን ይምረጡ',
            },
        },
    },
    'lni-select': {
        messages: {
            valueMissing: {
                global: 'እባክዎ ለ"{legend}{labelText}" የሆነ ነገር ይምረጡ፣ ያስፈልጋል',
                inline: 'እባክዎትን የሆነ ነገር ይምረጡ',
            },
        },
    },
    'lni-spinner': {
        label: 'በመምጣት ላይ…',
    },
};
/* eslint-enable max-len */