import Framework from '@gov.wa.lni/framework.one-lni.core/source/Framework';
import {
    PayloadOptions,
} from '@gov.wa.lni/framework.one-lni.core/source/types/PayloadOptions';
import {
    RequestPayload,
} from '@gov.wa.lni/framework.one-lni.core/source/types/RequestPayload';

const API_STRING = 'api';

const defaultOptions = {
    operation: '',
    httpMethod: 'GET',
    restParameters: [],
    body: '',
    ServiceName: '',
};

export const getGatewayPayload = (options: PayloadOptions) => {
    const params = {
        ...defaultOptions,
        ...options,
    };

    const {
        operation,
        httpMethod,
        restParameters,
        body,
        ServiceName,
    } = params;

    let UrlData = `${API_STRING}${operation}`;

    if (Array.isArray(restParameters)) {
        restParameters.forEach(parameter => {
            UrlData = `${UrlData}/${parameter}`;
        });
    }

    const requestBody: RequestPayload = {
        ServiceName,
        UrlData,
        RestHttpMethod: httpMethod,
    };

    if (httpMethod === 'POST') {
        requestBody.RequestContent = JSON.stringify(body);
    }

    const request = {
        method: 'POST',
        body: JSON.stringify(requestBody),
    };

    return request;
};

export const getGatewayUrl = (framework: Framework, url: string) => {
    const currentEnvironment: any = framework.environment.current;
    const hostName = window.location.hostname;
    let newUrl = `${currentEnvironment.baseUrl}/${url}`;

    if (hostName === 'localhost') {
        newUrl = `http://localhost/GatewayProxy/UIProxy.aspx?op=UiGatewayRestfulOperation `;
    } else if (hostName === 'dev2-secure.lni.wa.gov') {
        newUrl = `${url.replace(currentEnvironment.baseUrl, 'https://dev2-secure.lni.wa.gov')}`;
    }

    return newUrl;
};