<template>
    <header class="lni-c-site-header__container">
        <lni-site-alert :id="`${id}_alert`"></lni-site-alert>
        <div
            class="lni-c-site-header lni-c-simple-site-header"
            :class="{
                '--legacy': isLegacy
            }">
            <div class="lni-c-site-header__inner-container">
                <div class="lni-c-site-header__content lni-u-flex lni-u-full-width lni-u-justify-between lni-o-color-context--inverse">
                    <div
                        id="ewnLogo"
                        class="lni-c-site-header__logo lni-u-ma1">
                        <a href="/">
                             <img
                                :id="`${id}_icon-logo`"
                                src="./lni-branding-white.svg?data"
                                class="lni-c-secure-header__icon"
                                alt="Washington State Department of Labor and Industries logo"
                                width="300" height="76"
                            />
                        </a>
                    </div>
                    <div class="lni-u-mr2 lni-u-nowrap">
                        {{ appName }}
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>
<script>
export default {
    name: 'lni-header-simple',

}; </script>