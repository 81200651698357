import validation from '@gov.wa.lni/framework.one-lni.core/source/validation';

export default (id, moduleState, onlyOwn) => {
    if (!id || !moduleState) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }

        return null;
    }

    if (validation.validity.hasValidityError(moduleState.validity)) {
        return validation.validity.processedValidityError(id, moduleState);
    }

    if (validation.component.hasProcessedError(id, moduleState, onlyOwn)) {
        return validation.component.firstProcessedError(id, moduleState, onlyOwn);
    }

    return null;
};