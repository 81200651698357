
import {
    redirectAfterLogout,
} from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure/actions/redirectAfterLogOut.js';

export const logOut = () => {
    fetch('/pkmslogout', {
        credentials: 'include',
        method: 'GET',
    }).then(response => {
        if (response.ok) {
            return response.blob();
        }
        return null;
    }).then(() => {
        redirectAfterLogout();
    });
};