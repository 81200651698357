import actions from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/component/actions';

export default moduleInstance => {
    if (!moduleInstance) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }

        return;
    }

    if (!moduleInstance.actions) {
        moduleInstance.actions = {};
    }

    moduleInstance.actions.initializeErrorsCollectedFrom
        = actions.initializeErrorsCollectedFrom;
    moduleInstance.actions.setErrorsCollectedBy
        = actions.setErrorsCollectedBy;

    // allow these actions to be overridden by the components themselves
    if (!moduleInstance.actions.customValidity) {
        moduleInstance.actions.customValidity
            = actions.customValidity;
    }
};