export default () => ({
    state: {
        content: null,
        hasFunctional: true,
        isLoaded: false,
        ieNotifierEnabled: true,
        ieNotifierTTL: 5259600000, // two months
        shareConfig: null,
        isUrlMapped: false,
    },
});