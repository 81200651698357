<template>
    <div
        v-slide="visible"
        :class="classString"
        class="lni-c-collapse">
        <slot></slot>
    </div>
</template>

<script>
import slide from '@gov.wa.lni/framework.one-lni.directives/source/slide.js';
/**
 *  The lni-collapse component.
 *  @module components/lni-collapse
 */

/**
 * lni-collapse component wraps other component hierarchies
 * and shows and hides them based on an interaction function
 */
export default {
    name: 'lni-collapse',
    directives: {
        slide,
    },
}; </script>