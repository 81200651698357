<template>
    <header
        v-if="isLoaded"
        class="lni-c-site-header__container"
    >
        <a
            id="skipLinkToMainContent"
            href="#lniMainContent"
            class="lni-c-skip-link lni-u-visually-hidden --focusable"
        >{{ content.skipToMainContent }}</a>
        <a
            :href="`#${id}_mega-menu`"
            class="lni-c-skip-link --skip-lang-links lni-u-visually-hidden --focusable"
        >{{ content.skipToMainNav }}</a>
        <lni-site-alert
            :id="`${id}_alert`"
            :origin="origin"
        />
        <nav
            v-if="!multilingualEnabled"
            class="lni-c-language-links__wrapper --legacy-nav"
            aria-labelledby="language-nav-label"
        >
            <span
                id="language-nav-label"
                class="lni-u-visually-hidden lni-u-type--xxs"
            >{{ content.languageLabel }}</span>
            <div class="lni-c-language-links lni-u-flex lni-u-mhauto">
                <div class="lni-c-language-links__container lni-u-ph1">
                    <span
                        :id="`${id}_icon-translate`"
                        class="lni-u-pr2 lni-c-icon lnicon--translate"
                        aria-hidden="true"
                    />
                    <ul class="lni-c-language-links__list lni-u-full-width lni-u-list-reset lni-u-flex">
                        <li class="lni-c-language-links__list-item">
                            <a
                                class="lni-c-language-links__link"
                                :class="landingPageLanguage === ('' || 'english') ? '--current' : null"
                                title="English language"
                                :href="`${origin}/agency/languages/english`"
                            >English</a>
                        </li>
                        <li class="lni-c-language-links__list-item">
                            <a
                                lang="es"
                                class="lni-c-language-links__link"
                                :class="landingPageLanguage === 'spanish' ? '--current' : null"
                                title="Spanish language"
                                :href="`${origin}/agency/languages/spanish`"
                            >Español</a>
                        </li>
                        <li class="lni-c-language-links__list-item">
                            <a
                                lang="km"
                                class="lni-c-language-links__link"
                                :class="landingPageLanguage === 'cambodian' ? '--current' : null"
                                title="Cambodian language"
                                :href="`${origin}/agency/languages/cambodian`"
                            >ប្រទេសកម្ពុជា</a>
                        </li>
                        <li class="lni-c-language-links__list-item">
                            <a
                                lang="zh-Hans"
                                class="lni-c-language-links__link"
                                :class="landingPageLanguage === 'chinese-simplified' ? '--current' : null"
                                title="Chinese Simplified language"
                                :href="`${origin}/agency/languages/chinese-simplified`"
                            >中国 - 简体</a>
                        </li>
                        <li class="lni-c-language-links__list-item">
                            <a
                                lang="zh-Hant"
                                class="lni-c-language-links__link"
                                :class="landingPageLanguage === 'chinese-traditional' ? '--current' : null"
                                title="Chinese Traditional language"
                                :href="`${origin}/agency/languages/chinese-traditional`"
                            >中文繁體</a>
                        </li>
                        <li class="lni-c-language-links__list-item">
                            <a
                                lang="ko"
                                class="lni-c-language-links__link"
                                :class="landingPageLanguage === 'korean' ? '--current' : null"
                                title="Korean language"
                                :href="`${origin}/agency/languages/korean`"
                            >한국어</a>
                        </li>
                        <li class="lni-c-language-links__list-item">
                            <a
                                lang="ru"
                                class="lni-c-language-links__link"
                                :class="landingPageLanguage === 'russian' ? '--current' : null"
                                title="Russian language"
                                :href="`${origin}/agency/languages/russian`"
                            >русский</a>
                        </li>
                        <li class="lni-c-language-links__list-item">
                            <a
                                lang="so"
                                class="lni-c-language-links__link"
                                :class="landingPageLanguage === 'somali' ? '--current' : null"
                                title="Somali language"
                                :href="`${origin}/agency/languages/somali`"
                            >Soomaali</a>
                        </li>
                        <li class="lni-c-language-links__list-item">
                            <a
                                lang="vi"
                                class="lni-c-language-links__link"
                                :class="landingPageLanguage === 'vietnamese' ? '--current' : null"
                                title="Vietnamese language"
                                :href="`${origin}/agency/languages/vietnamese`"
                            >tiếng Việt</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <lni-language-bar
            v-else
            :origin="origin"
            :label="content.languageLabel"
            :otherLanguagesLabel="content.otherLanguagesLabel"
            :languageHelpLabel="content.languageHelpLabel"
            :languageHelpUrl="content.languageHelpUrl"
            :storage="$oneLni.storage"
            :multilingualEnabled="multilingualEnabled"
            :secureHref="secureHref"
        />

        <div class="lni-c-site-header">
            <a
                href="#lniMainContent"
                class="lni-u-visually-hidden --focusable lni-c-skip-link"
            >{{ content.skipToMainContent }}</a>
            <div class="lni-c-site-header__inner-container">
                <div class="lni-c-site-header__content lni-u-flex lni-u-items-center lni-o-color-context--inverse">
                    <div
                        id="ewnLogo"
                        class="lni-c-site-header__logo lni-u-ma1"
                    >
                        <div v-if="(location === '/' || location === '/es/') && environment === 'public'">
                            <span
                                :id="`${id}_icon-logo`"
                                class="lni-c-icon lnicon--logo-laborIndustries"
                                aria-hidden="true"
                            />
                            <h1 class="lni-u-visually-hidden">
                                {{ content.logoLabel }}
                            </h1>
                        </div>
                        <a
                            v-else
                            :href="homeUrl"
                            :aria-label="content.homeLabel"
                        >
                            <span
                                :id="`${id}_icon-logo`"
                                class="lni-c-icon lnicon--logo-laborIndustries"
                                aria-hidden="true"
                            />
                        </a>
                    </div>
                    <nav
                        class="lni-c-utility-nav lni-u-mlauto lni-u-mr1"
                        aria-label="Contact and log-in links"
                    >
                        <ul class="lni-u-list-reset lni-u-flex">
                            <li class="lni-c-utility-nav__list-item">
                                <a
                                    class="lni-c-utility-nav__link lni-c-icon-button --transparent --responsive"
                                    :href="content.contact.link"
                                >
                                    <span
                                        :id="`${id}_icon-contact`"
                                        class="lni-u-pr1 lnicon--address-book"
                                        aria-hidden="true"
                                        aria-label="Contact"
                                    />
                                    <span class="lni-c-icon-button__label">{{ content.contact.label }}</span>
                                </a>
                            </li>
                            <li class="lni-c-utility-nav__list-item">
                                <a
                                    class="lni-c-utility-nav__link lni-c-icon-button --transparent --responsive"
                                    href="https://secure.lni.wa.gov"
                                >
                                    <span
                                        :id="`${id}_icon-user`"
                                        class="lni-u-pr1 lnicon--user"
                                        aria-hidden="true"
                                    />
                                    <span class="lni-c-icon-button__label">{{ content.loginLabel }}</span>
                                </a>
                            </li>
                            <!-- TODO: potentially add help icon here -->
                        </ul>
                    </nav>
                    <form
                        class="lni-c-site-header__search-container lni-u-ba lni-u-b--white"
                        method="GET"
                        :action="`${origin}/agency/site-search/index`"
                    >
                        <input
                            type="hidden"
                            name="index"
                            value="All_Content_Nav"
                        >
                        <!-- TODO: use component and accessible markup -->
                        <label
                            class="lni-u-visually-hidden"
                            for="header-search"
                        >{{ content.searchLabel }}</label>
                        <input
                            id="header-search"
                            :placeholder="content.searchLabel"
                            type="search"
                            name="query"
                        >
                        <input
                            type="hidden"
                            name="pg"
                            value="1"
                        >
                        <input
                            type="hidden"
                            name="group"
                            value="10"
                        >
                        <button :aria-label="content.searchButton">
                            <span
                                :id="`${id}_icon-search`"
                                class="lni-c-icon lnicon--search"
                                aria-hidden="true"
                            />
                        </button>
                    </form>
                </div>
                <lni-mega-menu
                    :id="`${id}_mega-menu`"
                    :navigation="navigation"
                    :content="content"
                    :topTasks="topTasks"
                    :location="location"
                    :origin="origin"
                />
            </div>
        </div>
    </header>
</template>

<script>
import * as api from '@gov.wa.lni/framework.one-lni.navigation-components/source/navigation.api.js';
import megaMenu from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-mega-menu/lni-mega-menu.vue';
import languageBar from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-anonymous/lni-language-bar.vue';

export default {
    name: 'LniHeaderAnonymous',
    components: {
        'lni-mega-menu': megaMenu,
        'lni-language-bar': languageBar,
    },
    data() {
        return {
            multilingualEnabled: false,
        };
    },
    computed: {
        landingPageLanguage() {
            return window.location.href.split('/').pop();
        },
        homeUrl() {
            return this.origin.charAt(this.origin.length - 1) === '/'
                ? this.origin : `${this.origin}/`;
        },
    },
    created() {
        this.setMultilingualMode();
    },
    mounted() {
        this.getNavigation();
    },
    methods: {
        setMultilingualMode() {
            const env = this.$oneLni.environment.current;
            this.multilingualEnabled = env.isMultilingual;
        },
        getNavigation() {
            api.getNavigation(null, this.$oneLni, this.isUrlMapped).then(data => {
                this.updateData(data);
            });
        },
        updateData(data) {
            this.$store.commit('setAttribute', {
                id: this.id,
                attribute: 'navigation',
                value: data.primaryNavigation,
            });

            this.$store.commit('setAttribute', {
                id: this.id,
                attribute: 'topTasks',
                value: data.topTasks,
            });

            this.$store.commit('setAttribute', {
                id: this.id,
                attribute: 'content',
                value: data.courtesyMenu,
            });
            this.$store.commit('setAttribute', {
                id: this.id,
                attribute: 'origin',
                value: data.origin,
            });
            this.$store.commit('setAttribute', {
                id: this.id,
                attribute: 'isLoaded',
                value: true,
            });

            this.$store.commit('setAttribute', {
                id: this.id,
                attribute: 'location',
                value: data.location,
            });

            let currentEnvironment = this.$oneLni.environment.current;
            if (currentEnvironment.name.includes('Authenticated')) {
                this.$store.commit('setAttribute', {
                    id: this.id,
                    attribute: 'environment',
                    value: 'authenticated',
                });
            }
        },
    },
}; </script>