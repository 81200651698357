import Framework from '@gov.wa.lni/framework.one-lni.core/source/Framework';
import validateSetupParameters from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/validateSetupParameters';

function scriptTemplate(propertyId: string) {
    return `
        var siteId = '${propertyId}';

        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
        m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

        ga('create', siteId, 'auto');
        ga('send', 'pageview');

    `;
}

function getPropertyId(framework: Framework): string {
    if (framework.environment.current.type === 'intermediate') {
        return `${framework.analyticsId}-2`;
    }

    if (framework.environment.current.type === 'production') {
        return `${framework.analyticsId}-1`;
    }

    return '';
}

export default function setupAnalytics(framework: Framework, options: FrameworkOptions) {
    validateSetupParameters(framework, options);

    if (!framework.environment.current.supportsAnalytics) {
        return;
    }

    // If this variable exists on the window, then Google Analytics has been loaded already by EWN
    if (window.siteId) {
        return;
    }

    const propertyId = getPropertyId(framework);

    if (!propertyId) {
        return;
    }

    const element: HTMLScriptElement = window.document.createElement('script');
    element.innerHTML = scriptTemplate(propertyId);
    window.document.querySelector('head')?.appendChild(element);
}