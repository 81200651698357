import hasValidityError from '@gov.wa.lni/framework.one-lni.core/source/validation/validity/hasValidityError.js';
import hasProcessedError from '@gov.wa.lni/framework.one-lni.core/source/validation/component/hasProcessedError.js';

export default (id, moduleState, onlyOwn) => {
    if (!id || !moduleState) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }

        return null;
    }

    // if the module has a validity state, it is primary input or
    // a custom validity object has been added to it and that
    // error is what we use for determining whether there is an error
    if (moduleState.validity && Object.keys(moduleState.validity).length > 0) {
        return hasValidityError(moduleState.validity);
    }
    return hasProcessedError(id, moduleState, onlyOwn);
};