import firstValidityError from '@gov.wa.lni/framework.one-lni.core/source/validation/validity/firstValidityError.js';

export default (id, moduleState) => {
    if (!id || !moduleState) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }

        return null;
    }

    const validationTest = firstValidityError(moduleState.validity);
    const errorMessageMeta = moduleState.messages[validationTest];
    return {
        id,
        validationTest,
        global: errorMessageMeta.global(moduleState),
        inline: errorMessageMeta.inline(moduleState),
    };
};