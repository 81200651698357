/* eslint-disable max-len */
export default {
    'lni-checkbox': {
        messages: {
            valueMissing: {
                global: 'Ilalai maaloo "{legend}{labelText}", ni barbaachisa',
                inline: 'Mee fiildii kana ilaali',
            },
        },
    },
    'lni-checkbox-list': {
        messages: {
            valueMissing: {
                global: 'Maaloo filannoo "{legend}{labelText}" qabaadhu, ni barbaachisa',
                inline: 'Mee filannoo tokko qabaadhu',
            },
        },
    },
    'lni-error-container': {
        heading: 'Osoo itti fufiin dura:',
        message: 'Maaloo dogoggoroota itti aanana kana sirreessi',
    },
    'lni-input-phone': {
        messages: {
            patternMismatch: {
                global: 'Lakkoofsa bilbilaa sirrii ta\'e galchi (Bifa: XXX-XXX-XXXX)',
                inline: 'Lakkoofsa bilbilaa sirrii ta\'e galchi (Bifa: XXX-XXX-XXXX)',
            },
        },
        phoneHelperText: 'Bifa: XXX-XXX-XXXX',
    },
    'lni-input-text': {
        messages: {
            valueMissing: {
                global: 'Maaloo galchi "{legend}{labelText}", ni barbaachisa',
                inline: 'Mee dirree kana guutaa',
            },
            patternMismatch: {
                global: 'Maaloo "{legend}{labelText}" tiif foormaatii sirrii ta\'etti fayyadami.',
                inline: 'Mee foormaatii sirrii fayyadami{example}',
            },
            typeMismatch: {
                global: 'Maaloo "{legend}{labelText}" tiif foormaatii sirrii ta\'etti fayyadami.',
                inline: 'Mee foormaatii sirrii fayyadami{example}',
            },
            tooShort: {
                global: '"{labelText}" yoo xiqqaate karaakteroota {num} dheerachuu qaba. Maaloo karaakteroota {difference} dabalataa itti dari.',
                inline: '"{labelText}" yoo xiqqaate karaakteroota {num} dheerachuu qaba. Maaloo karaakteroota {difference} dabalataa itti dari.',
            },
        },
    },
    'lni-input-email': {
        messages: {
            valueMissing: {
                global: 'Maaloo galchi "{legend}{labelText}", ni barbaachisa',
                inline: 'Mee dirree kana guutaa',
            },
            patternMismatch: {
                global: 'Maaloo "{legend}{labelText}" dhaaf iimeelii sirrii ta\'e fayyadami',
                inline: 'Mee email sirrii ta\'e galchi',
            },
            typeMismatch: {
                global: 'Maaloo "{legend}{labelText}" dhaaf iimeelii sirrii ta\'e fayyadami',
                inline: 'Mee email sirrii ta\'e galchi',
            },
        },
    },
    'lni-radios': {
        messages: {
            valueMissing: {
                global: 'Maaloo filannoo "{legend}{labelText}" qabaadhu, ni barbaachisa',
                inline: 'Mee filannoo tokko qabaadhu',
            },
        },
    },
    'lni-select': {
        messages: {
            valueMissing: {
                global: 'Maaloo waan ta\'e "{legend}{labelText}" filadhu, ni barbaachisa',
                inline: 'Mee waan tokko filadhu',
            },
        },
    },
    'lni-spinner': {
        label: 'Dhufaa jira…',
    },
};
/* eslint-enable max-len */