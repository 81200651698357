export default (context, payload) => new Promise(resolve => {
    if (!context
         || !payload
         || !payload.targetId
         || !payload.validation) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }
        resolve();
        return;
    }

    context.commit(`${payload.targetId}/setDirty`, {
        value: true,
    });

    context.dispatch('setErrorsOnSelf', {
        targetId: payload.targetId,
        validation: {
            errorsCollectedBy: payload.validation.errorsCollectedBy,
        },
    }, {
        root: true,
    }).then(() => {
        resolve();
    });
});