/* eslint-disable no-underscore-dangle */

/**
 * Represents the the framework version number.
 */
export default class Version {
    private _major: number;
    private _minor: number;
    private _patch: number;

    constructor(major: number, minor: number, patch: number) {
        this._major = major;
        this._minor = minor;
        this._patch = patch;
    }

    get major() {
        return this._major;
    }
    get minor() {
        return this._minor;
    }
    get patch() {
        return this._patch;
    }
    get text() {
        return `${this.major}.${this.minor}.${this.patch}`;
    }

    toString() {
        return this.text;
    }

    get libraries() {
        return {
            get vue(): string {
                return '2.6.14';
            },
            get vuex(): string {
                return '3.6.2';
            },
        };
    }
}