/* eslint-disable max-len */
export default {
    'lni-checkbox': {
        messages: {
            valueMissing: {
                global: 'Vui lòng kiểm tra "{legend}{labelText}", điều này bắt buộc',
                inline: 'Vui lòng đánh dấu vào trường này',
            },
        },
    },
    'lni-checkbox-list': {
        messages: {
            valueMissing: {
                global: 'Vui lòng chọn lựa đối với "{legend}{labelText}", điều này bắt buộc',
                inline: 'Vui lòng chọn lựa',
            },
        },
    },
    'lni-error-container': {
        heading: 'Trước khi chúng tôi tiếp tục:',
        message: 'Vui lòng sửa các lỗi sau đây',
    },
    'lni-input-phone': {
        messages: {
            patternMismatch: {
                global: 'Vui lòng nhập một số điện thoại hợp lệ (Định dạng: XXX-XXX-XXXX)',
                inline: 'Vui lòng nhập một số điện thoại hợp lệ (Định dạng: XXX-XXX-XXXX)',
            },
        },
        phoneHelperText: 'Định dạng: XXX-XXX-XXXX',
    },
    'lni-input-text': {
        messages: {
            valueMissing: {
                global: 'Vui lòng ghi "{legend}{labelText}", điều này bắt buộc',
                inline: 'Vui lòng điền vào trường này',
            },
            patternMismatch: {
                global: 'Xin sử dụng đúng định dạng đối với "{legend}{labelText}"',
                inline: 'Xin sử dụng đúng định dạng{example}',
            },
            typeMismatch: {
                global: 'Xin sử dụng đúng định dạng đối với "{legend}{labelText}"". (VI)',
                inline: 'Xin sử dụng đúng định dạng{example}',
            },
            tooShort: {
                global: '"{labelText}" phải dài ít nhất {num} ký tự. Xin thêm nhiều ký tự {difference} hơn.',
                inline: '"{labelText}" phải dài ít nhất {num} ký tự. Xin thêm nhiều ký tự {difference} hơn.',
            },
        },
    },
    'lni-input-email': {
        messages: {
            valueMissing: {
                global: 'Vui lòng ghi "{legend}{labelText}", điều này bắt buộc',
                inline: 'Vui lòng điền vào trường này',
            },
            patternMismatch: {
                global: 'Xin sử dụng một email hợp lệ đối với "{legend}{labelText}"',
                inline: 'Vui lòng nhập một email hợp lệ',
            },
            typeMismatch: {
                global: 'Xin sử dụng một email hợp lệ đối với "{legend}{labelText}"',
                inline: 'Vui lòng nhập một email hợp lệ',
            },
        },
    },
    'lni-radios': {
        messages: {
            valueMissing: {
                global: 'Vui lòng chọn lựa đối với "{legend}{labelText}", điều này bắt buộc',
                inline: 'Vui lòng chọn lựa',
            },
        },
    },
    'lni-select': {
        messages: {
            valueMissing: {
                global: 'Vui lòng chọn một nội dung nào đó cho "{legend}{labelText}", điều này bắt buộc',
                inline: 'Vui lòng chọn một nội dung nào đó',
            },
        },
    },
    'lni-spinner': {
        label: 'Đang tải…',
    },
};
/* eslint-enable max-len */