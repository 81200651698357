/* eslint-disable @typescript-eslint/no-non-null-assertion */
/**
 * Loads a single script or style resource.
 * @param url The url of the resource to load
 * @param type The type of the resource. Valid values are 'js' or 'css'
 * @param callback The function that is called when the resource has finished
 * loading.
 */
export default (
    url: string, type: string,
    callback?: (error?: Error | Event) => void,
) => {
    if (!url || !type) {
        const error = new Error(`A required parameter is missing`);

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        callback && callback(error);
        return;
    }

    let loaderElement: HTMLScriptElement | HTMLLinkElement | null;

    if (type === 'js') {
        loaderElement = window.document.createElement('script');
        loaderElement.setAttribute('type', 'text/javascript');
        loaderElement.setAttribute('src', url);
    } else if (type === 'css') {
        loaderElement = window.document.createElement('link');
        loaderElement.setAttribute('rel', 'stylesheet');
        loaderElement.setAttribute('type', 'text/css');
        loaderElement.setAttribute('href', url);
    } else {
        const error: Error = new Error(`The resource type "${type}" for resource "${url}" is not valid.`);

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        callback && callback(error);

        return;
    }

    loaderElement.addEventListener('load', () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        callback && callback();
    });

    loaderElement.addEventListener('error', error => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        callback && callback(error);
    });

    window.document.getElementsByTagName('head')[0]!.appendChild(loaderElement);
};