import IStorageProxy from '@gov.wa.lni/framework.one-lni.core/source/IStorageProxy';
import MemoryStorage from '@gov.wa.lni/framework.one-lni.core/source/MemoryStorage';
import StorageProxy from '@gov.wa.lni/framework.one-lni.core/source/StorageProxy';
import {
    jsCookie,
} from '@gov.wa.lni/framework.one-lni.core/source/external';

export default class FrameworkStorage {
    public readonly session: IStorageProxy;
    public readonly persistent: IStorageProxy;
    public readonly memory: IStorageProxy;
    public readonly cookie: any;

    constructor() {
        this.session = new StorageProxy(window.sessionStorage);
        this.persistent = new StorageProxy(window.localStorage);
        this.memory = new MemoryStorage();
        this.cookie = jsCookie;
    }
}