const NAVIGATION_ENDPOINT = '/navigation/';
const ALERTS_ENDPOINT = '/system-status/';
let navigationPromise = null;
let alertPromise = null;

export const resetNavigation = function() {
    navigationPromise = null;
};

export const resetAlerts = function() {
    alertPromise = null;
};

const location = oneLni => {
    const pathname = window.location.pathname;
    if (pathname === '/dotAdmin/') {
        // get the location from the dotCMS app hash
        // example: https://dev-public.lni.wa.gov/dotAdmin/#/edit-page/content?url=%2Fworkers-rights%2Fworkplace-complaints%2Fprotected-leave-complaints&language_id=1&host_id=f9a194e0-9f37-4df3-8bdc-2f46d0215ead
        return window.parent.location.hash.split('?')[1]
            .split('&')
            .find(paramString => paramString.split('=')[0] === 'url')
            .split('=')[1];
    }

    if (oneLni.getLocation()) {
        return oneLni.getLocation();
    }

    return encodeURIComponent(pathname);
};

export const getNavigation = function(alternateApiLocation, oneLni, isUrlMapped) {
    const language = oneLni.getLanguage();
    const langIds = {
        en: '1',
        es: '2',
    };

    const languageParam = language === 'es' ? `&language_id=${langIds[language]}` : '';
    const apiLocation = alternateApiLocation
        ? alternateApiLocation : oneLni.environment.current.apiLocation2;
    const endpoint = `${apiLocation}${NAVIGATION_ENDPOINT}?location=${location(oneLni)}${languageParam}`;

    if (!navigationPromise) {
        navigationPromise = fetch(endpoint, {
            mode: 'cors',
        }).then(response => response.json())
            .then(data => {
                if (isUrlMapped) {
                    const parentLocation = window.location.pathname.split('/')
                        .slice(0, -1).join('/');
                    oneLni.setLocation(parentLocation);
                }
                return data.results;
            });
    }
    return navigationPromise;
};

export const getAlerts = function(alternateApiLocation, oneLni) {
    const language = oneLni.getLanguage();
    const langIds = {
        en: '1',
        es: '2',
    };
    const languageParam = language === 'es' ? `&language_id=${langIds[language]}` : '';
    const apiLocation = alternateApiLocation
        ? alternateApiLocation : oneLni.environment.current.apiLocation2;
    const endpoint = `${apiLocation}${ALERTS_ENDPOINT}?${languageParam}`;

    if (!alertPromise) {
        alertPromise = fetch(endpoint, {
            mode: 'cors',
        }).then(response => response.json())
            .then(data => data);
    }
    return alertPromise;
};