import Framework from '@gov.wa.lni/framework.one-lni.core/source/Framework';
import validateSetupParameters from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/validateSetupParameters';

import accessibleRoutingMixin from '@gov.wa.lni/framework.one-lni.core/source/mixins/accessibleRouting';

export default function setupAccessibleRouting(framework: Framework, options: FrameworkOptions) {
    validateSetupParameters(framework, options);

    if (!options.addAccessibleRouting) {
        return;
    }

    if (options.components && options.components.ApplicationMain) {
        const applicationMain = options.components.ApplicationMain;
        applicationMain.mixins = [
            ...applicationMain.mixins || [],
            accessibleRoutingMixin,
        ];
    }

}