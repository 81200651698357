import Framework from '@gov.wa.lni/framework.one-lni.core/source/Framework';
import validateSetupParameters from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/validateSetupParameters';

export default function setupLanguage(framework: Framework, options: FrameworkOptions) {
    validateSetupParameters(framework, options);

    //TODO: [Aaron] Validate this with Matt - should is save or not?
    if (options.languageOverride) {
        framework.setLanguage(options.languageOverride, false);
    }

}