import text from '@gov.wa.lni/framework.one-lni/source/locale/en.js';

export default () => ({
    state: {
        classString: '',
        /*
            The title for the modal.  This is required from an accessibility standpoint
            and also will be the header.
        */
        title: 'Placeholder - Title Required',

        /* Visually hide the title. It will still be available to screen readers. */
        hideTitle: false,

        /* Hide close button. Only use if you have provided a close button in the actions slot */
        hideCloseButton: false,

        /* If true, the content will have smaller margins on top and bottom */
        compact: false,

        /*
            Whether to have the ability to close the modal; through a close button,
                a click outside of the modal, or using the ESC key
        */
        allowClose: false,

        /*
            If true, this modal just gives information and then is closed.
            If false, this modal provides actions for the user to take(such as form inputs
                or links) beyond just OK or Cancel buttons.
        */
        isAlert: false,

        /*
            Set this attribute to open or close the modal.
        */
        visible: false,

        /*
            This action will be called if the modal is closed using one of the methods allowed
            from the "allowClose" attribute(see above).  If you provide buttons such as OK or
            Cancel, you will be able to respond by using the 'click-action' of that button
            and then will be responsible for closing the modal by setting the "visible"
            attribute to false.
        */
        closeAction: '',

        /*
            This action will be called when the modal is opened. If you have any async
            operations that need to be performed after the modal has opened to display modal
            body you can use this action.
        */
        openAction: '',

        closeLabel: text['lni-modal'].closeLabel,
    },
});