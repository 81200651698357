import getAttribute from '@gov.wa.lni/framework.one-lni.core/source/store/utils/getAttribute.js';

export default (context, id) => {
    if (!context || !id) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }
        return null;
    }

    return getAttribute(context, id, 'value');
};