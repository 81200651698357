import script from '@gov.wa.lni/framework.one-lni.core/source/lib/script';

const dependencyState = {
    completed: false,
    oneLniComponents: {
        required: false,
        loaded: false,
        scriptElement: null,
    },
};

export default () => {
    const componentScript = script('oneLniComponents.js');

    if (componentScript) {
        dependencyState.oneLniComponents.required = true;
        dependencyState.oneLniComponents.scriptElement = componentScript.element;
    }

    return dependencyState;
};