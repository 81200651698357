import {
    getRelationShipCode,
} from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure/actions/getInfoForRelationshipCode.js';

export const onEntityClick = (event, entities) => {
    if (entities) {
        entities.forEach( entity => {
            if (entity.entity_id === event.target.id) {
                const entityRelationship = entity.EntityRelationships[0];
                let code = getRelationShipCode(entityRelationship.relationship_code);
                const pathName = 'home';
                const currentEnvironment
                    = `${window.document.location.protocol}//${window.document.location.host}/${pathName}`;
                let entityURL;
                if (code === 'rel_employer') {
                    entityURL = currentEnvironment
                                    + '/mylni/default.aspx#/dashboard/rel_employer/'
                                    + entity.EntityRelationships[0].identifier_value_data
                                    + '/home';
                } else if (code === 'rel_provider') {
                    entityURL = currentEnvironment
                                    + '/mylni/default.aspx#/dashboard/rel_provider/'
                                    + entity.EntityRelationships[0].identifier_value_data;
                } else if (code === 'rel_worker') {
                    entityURL = currentEnvironment
                                    + '/mylni/default.aspx#/dashboard/rel_worker/'
                                    + entity.EntityRelationships[0].identifier_value_data;
                } else {
                    entityURL = currentEnvironment
                                    + '/mylni/#audience/' + code;
                }
                window.parent.location = entityURL;
            }
        });
    }
};