/* eslint-disable max-len */
export default {
    'lni-checkbox': {
        messages: {
            valueMissing: {
                global: 'ਕਿਰਪਾ ਕਰਕੇ "{legend}{labelText}" \'ਤੇ ਨਿਸ਼ਾਨ ਲਾਓ, ਇਹ ਲੋੜੀਂਦਾ ਹੈ',
                inline: 'ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਫੀਲਡ \'ਤੇ ਨਿਸ਼ਾਨ ਲਾਓ',
            },
        },
    },
    'lni-checkbox-list': {
        messages: {
            valueMissing: {
                global: 'ਕਿਰਪਾ ਕਰਕੇ "{legend}{labelText}" ਲਈ ਕੋਈ ਚੋਣ ਕਰੋ, ਇਹ ਲੋੜੀਂਦਾ ਹੈ',
                inline: 'ਕਿਰਪਾ ਕਰਕੇ ਕੋਈ ਚੋਣ ਕਰੋ',
            },
        },
    },
    'lni-error-container': {
        heading: 'ਇਸਤੋਂ ਪਹਿਲਾਂ ਅਸੀਂ ਜਾਰੀ ਰੱਖਿਏ:',
        message: 'ਕਿਰਪਾ ਕਰਕੇ ਹੇਠਾਂ ਦਿੱਤੀਆਂ ਤਰੁੱਟੀਆਂ ਨੂੰ ਠੀਕ ਕਰੋ',
    },
    'lni-input-phone': {
        messages: {
            patternMismatch: {
                global: 'ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਵੈਧ ਫੋਨ ਨੰਬਰ ਦਰਜ਼ ਕਰੋ (ਫਾਰਮੈਟ: XXX-XXX-XXXX)',
                inline: 'ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਵੈਧ ਫੋਨ ਨੰਬਰ ਦਰਜ਼ ਕਰੋ (ਫਾਰਮੈਟ: XXX-XXX-XXXX)',
            },
        },
        phoneHelperText: 'ਫਾਰਮੈਟ: XXX-XXX-XXXX',
    },
    'lni-input-text': {
        messages: {
            valueMissing: {
                global: 'ਕਿਰਪਾ ਕਰਕੇ "{legend}{labelText}" ਦਰਜ਼ ਕਰੋ, ਇਹ ਲੋੜੀਂਦਾ ਹੈ',
                inline: 'ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਖੇਤਰ ਨੂੰ ਭਰੋ',
            },
            patternMismatch: {
                global: 'ਕਿਰਪਾ ਕਰਕੇ "{legend}{labelText}" ਲਈ ਸਹੀ ਫਾਰਮੈਟ ਦੀ ਵਰਤੋਂ ਕਰੋ',
                inline: 'ਕਿਰਪਾ ਕਰਕੇ ਸਹੀ ਫਾੱਰਮੈਟ ਦੀ ਵਰਤੋਂ ਕਰੋ{example}',
            },
            typeMismatch: {
                global: 'ਕਿਰਪਾ ਕਰਕੇ "{legend}{labelText}" ਲਈ ਸਹੀ ਫਾਰਮੈਟ ਦੀ ਵਰਤੋਂ ਕਰੋ',
                inline: 'ਕਿਰਪਾ ਕਰਕੇ ਸਹੀ ਫਾੱਰਮੈਟ ਦੀ ਵਰਤੋਂ ਕਰੋ{example}',
            },
            tooShort: {
                global: '"{labelText}" ਘੱਟੋ-ਘੱਟ {num} ਅੱਖਰਾਂ ਜਿਹਾ ਲੰਮਾ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ {difference} ਹੋਰ ਅੱਖਰ ਸ਼ਾਮਲ ਕਰੋ।',
                inline: '"{labelText}" ਘੱਟੋ-ਘੱਟ {num} ਅੱਖਰਾਂ ਜਿਹਾ ਲੰਮਾ ਹੋਣਾ ਚਾਹੀਦਾ ਹੈ। ਕਿਰਪਾ ਕਰਕੇ {difference} ਹੋਰ ਅੱਖਰ ਸ਼ਾਮਲ ਕਰੋ।',
            },
        },
    },
    'lni-input-email': {
        messages: {
            valueMissing: {
                global: 'ਕਿਰਪਾ ਕਰਕੇ "{legend}{labelText}" ਦਰਜ਼ ਕਰੋ, ਇਹ ਲੋੜੀਂਦਾ ਹੈ',
                inline: 'ਕਿਰਪਾ ਕਰਕੇ ਇਸ ਖੇਤਰ ਨੂੰ ਭਰੋ',
            },
            patternMismatch: {
                global: 'ਕਿਰਪਾ ਕਰਕੇ "{legend}{labelText}" ਲਈ ਵੈਧ ਈਮੇਲ ਦੀ ਵਰਤੋਂ ਕਰੋ',
                inline: 'ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਵੈਧ ਈਮੇਲ ਦਰਜ਼ ਕਰੋ',
            },
            typeMismatch: {
                global: 'ਕਿਰਪਾ ਕਰਕੇ "{legend}{labelText}" ਲਈ ਵੈਧ ਈਮੇਲ ਦੀ ਵਰਤੋਂ ਕਰੋ',
                inline: 'ਕਿਰਪਾ ਕਰਕੇ ਇੱਕ ਵੈਧ ਈਮੇਲ ਦਰਜ਼ ਕਰੋ',
            },
        },
    },
    'lni-radios': {
        messages: {
            valueMissing: {
                global: 'ਕਿਰਪਾ ਕਰਕੇ "{legend}{labelText}" ਲਈ ਕੋਈ ਚੋਣ ਕਰੋ, ਇਹ ਲੋੜੀਂਦਾ ਹੈ',
                inline: 'ਕਿਰਪਾ ਕਰਕੇ ਕੋਈ ਚੋਣ ਕਰੋ',
            },
        },
    },
    'lni-select': {
        messages: {
            valueMissing: {
                global: 'ਕਿਰਪਾ ਕਰਕੇ "[label]" ਲਈ ਕੁਝ ਚੋਣ ਕਰੋ, ਇਹ ਲੋੜੀਂਦਾ ਹੈ',
                inline: 'ਕਿਰਪਾ ਕਰਕੇ ਕੁਝ ਚੋਣ ਕਰੋ',
            },
        },
    },
    'lni-spinner': {
        label: 'ਲੋਡ ਹੋ ਰਿਹਾ ਹੈ...',
    },
};
/* eslint-enable max-len */