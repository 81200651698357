import Framework from '@gov.wa.lni/framework.one-lni.core/source/Framework';
import validateSetupParameters from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/validateSetupParameters';

export default function setupFeatureFlags(framework: Framework, options: FrameworkOptions) {
    validateSetupParameters(framework, options);

    if (options.featureFlags) {
        framework.setFeatureFlags(options.featureFlags);
    }

}