import Framework from '@gov.wa.lni/framework.one-lni.core/source/Framework';
import {
    getGatewayPayload, getGatewayUrl, HttpInterceptor,
} from '@gov.wa.lni/framework.one-lni.core/source/lib';
export default function setupInterceptor(framework: Framework) {
    const fetchInterceptor = new HttpInterceptor(framework);

    fetchInterceptor.register({
        request(url: string, config: any) {
            const headers = {
                'Content-type': 'application/json',
            };

            const requestOptions: any = getGatewayPayload(config);
            const newUrl = getGatewayUrl(framework, url);

            return [newUrl, {
                ...requestOptions,
                headers: {
                    ...headers,
                    ...requestOptions.headers,
                },
            }];
        },

        // TODO: use below functions to modify response, response errors or request errors
        // requestError: function (error: any) {
        //     return error;
        // },

        // response: function (response: Response) {
        //     return response;
        // },

        // responseError: function (error: any) {
        //     return error;
        // }
    });

}