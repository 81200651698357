<template>
    <div
        class="one-lni-isolation-container"
        :class="{ '--compact': compact }">
        <transition name="modal">
            <div
                v-show="visible"
                :id="`${id}_modal_overlay`"
                v-tabbableElements
                class="lni-c-modal__overlay"
                :aria-labelledby="`${id}_title`"
                @click.stop.self="allowClose ? closeModal() : null"
                @keydown.esc="allowClose ? closeModal() : null">
                <div
                    :class="['lni-c-modal',
                             'lni-u-pa2',
                             'lni-u-flex',
                             'lni-u-flex-column',
                             'lni-u-shadow--xl',
                             classString,
                    ]"
                    aria-live="assertive"
                    :role="isAlert ? 'alert' : 'dialog'"
                    :aria-busy="isAlert"
                    @keydown.tab.exact="handleTab"
                    @keydown.tab.shift="handleShiftTab">
                    <div class="lni-u-flex lni-u-justify-between lni-u-items-start">
                        <h2
                            :id="`${id}_title`"
                            class="lni-u-heading--2"
                            :class="{ 'lni-u-visually-hidden' : hideTitle, 'lni-u-heading--3': compact }"
                            tabindex="0">
                            {{ title }}
                        </h2>
                        <button
                            v-if="allowClose && !hideCloseButton"
                            class="lni-c-modal__close-button lni-c-button lni-u-pa1 lni-u-mr0 lni-u-mlauto"
                            @click="closeModal">
                            <span
                                class="lnicon--close lni-c-modal__close-button-icon lni-u-block"
                                aria-hidden="true"></span>
                            <span class="lni-u-visually-hidden">{{ closeLabel }}</span>
                        </button>
                    </div>

                    <div class="lni-c-modal__content">
                        <slot name="content">
                            Content must be added to the "content" slot.
                        </slot>
                    </div>
                    <div
                        v-if="hasActions"
                        class="lni-c-modal__actions">
                        <slot name="actions">
                            Actions(Buttons) can be added to the "actions" slot.
                        </slot>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>


<script>
import tabbableElements from '@gov.wa.lni/framework.one-lni.directives/source/tabbableElements.js';

/**
*  The lni-modal component.
*  @module components/lni-modal
*/

/**
* The lni-modal
*/

export default {
    name: 'lni-modal',
    directives: {
        tabbableElements,
    },
    data() {
        return {
            lastFocusedElement: null,
            tabbableEls: [],
        };
    },
    computed: {
        hasActions() {
            return !!this.$slots.actions;
        },
    },
    beforeUpdate() {
        let el = document.getElementById(`${this.id}_modal_overlay`);
        if (!el) {
            /* Add the overlay as first child of the body so it covers everything */
            document.body.insertBefore(this.$el, document.body.firstChild);
        }

        this.$nextTick(() => {
            if (this.tabbableEls.length > 0) {
                this.firstTabStop = this.tabbableEls[0];
                this.lastTabStop = this.tabbableEls[this.tabbableEls.length - 1];

                /* Put focus on first tabbable */
                this.firstTabStop.focus();
            }
        });
    },
    mounted() {
        /* Add the overlay as first child of the body so it covers everything */
        document.body.insertBefore(this.$el, document.body.firstChild);

        this.$watch('visible', function updateState(newValue) {
            if (newValue) {
                this.isOpening();
            } else {
                this.isClosing();
            }
        });

        if (!this.allowClose) {
            document.getElementsByClassName('lni-c-modal__overlay')[0].onclick = e => {
                if (e.target.id === `${this.id}_modal_overlay`) {
                    this.firstTabStop.focus();
                }
            };
        }
    },
    methods: {
        isOpening() {
            this.$emit('open');
            this.dispatchEvent('openAction');
            this.lastFocusedElement = document.activeElement;
        },
        isClosing() {
            this.$emit('close');
            this.dispatchEvent('closeAction');
            this.$el.parentNode.removeChild(this.$el);

            // This isn't resetting focus unless this timeout is used.
            setTimeout(() => {
                if (this.lastFocusedElement) {
                    this.lastFocusedElement.focus();
                }
            }, 0);
        },
        closeModal() {
            this.visible = false;
        },
        handleTab(event) {
            if (document.activeElement === this.lastTabStop) {
                event.preventDefault();
                this.firstTabStop.focus();
            }
        },
        handleShiftTab(event) {
            if (document.activeElement === this.firstTabStop) {
                event.preventDefault();
                this.lastTabStop.focus();
            }
        },
    },
}; </script>