import allValidityErrors from '@gov.wa.lni/framework.one-lni.core/source/validation/validity/allValidityErrors.js';
import firstValidityError from '@gov.wa.lni/framework.one-lni.core/source/validation/validity/firstValidityError.js';
import hasValidityError from '@gov.wa.lni/framework.one-lni.core/source/validation/validity/hasValidityError.js';
import processedValidityError from '@gov.wa.lni/framework.one-lni.core/source/validation/validity/processedValidityError.js';

export default {
    firstValidityError,
    allValidityErrors,
    hasValidityError,
    processedValidityError,
};