import {
    Vue,
} from '@gov.wa.lni/framework.one-lni.core/source/external';

import Framework from '@gov.wa.lni/framework.one-lni.core/source/Framework';

import setupStore  from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/setupStore';
import setupRouter  from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/setupRouter';
import setupAccessibleRouting  from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/setupAccessibleRouting';
import setupScrollTo  from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/setupScrollTo';
import setupAnalytics  from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/setupAnalytics';
import setupFeatureFlags  from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/setupFeatureFlags';
import setupLanguage  from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/setupLanguage';
import setupInterceptor from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/setupInterceptor';

Vue.config.productionTip = false;

export default new Framework([
    setupStore,
    setupRouter,
    setupAccessibleRouting,
    setupScrollTo,
    setupAnalytics,
    setupFeatureFlags,
    setupLanguage,
    setupInterceptor,
]);