import Framework from '@gov.wa.lni/framework.one-lni.core/source/Framework';

type DispatchEventThis = {
    $parent: any;
    $store: any;
    $oneLni: Framework;
    $router: any;
    id: string;
    index: number;
    key: string;
};

function multipleDispatch(
    this: DispatchEventThis,
    actions: Array<string>,
    payload: ActionPayload,
): Promise<any> {
    const promise = new Promise(resolve => {
        // remove actions that are empty strings
        const validActions = actions.filter((singleAction: string) => singleAction !== '');

        const tasks = validActions.map(action => () => this.$store.dispatch(action, payload));

        // invoke each action sequentially and wait for it to finish before starting the next.
        return tasks.reduce((
            promiseChain,
            currentTask,
        ) => promiseChain.then(chainResults =>
            currentTask().then((currentResult: any) =>
                [
                    ...chainResults,
                    currentResult,
                ],
            ),
        ), Promise.resolve([])).then(arrayOfResults => {
            resolve(arrayOfResults);
        });
    });

    return promise;
}

/**
 * Dispatches an action that can modify the state of components or do any other tasks.
 * @param this The component that calls this action
 * @param actionName The name of the action to dispatch. Must have been registered with the framework.
 * @param customData Any custom data to pass into the framework
 * @returns Returns a promise that is resolved when the action completes.
 */
export default function dispatchEvent(
    this: DispatchEventThis,
    actionName: string,
    customData: any,
): Promise<any> {
    if (!actionName) {
        return Promise.resolve('Missing actionName in dispatchEvent');
    }

    let action: string | Array<string> = this.$store.state[this.id][actionName];

    if (action === '') {
        return Promise.resolve('No action specified');
    } else if (typeof action === 'undefined') {
        action = actionName;
    }

    const validation = {
        errorsCollectedBy: this.$store.state[this.id].errorsCollectedBy,
    };

    const payload: ActionPayload = {
        targetId: this.id,
        targetIndex: this.index,
        targetKey: this.key,
        sourceId: this.$parent.id,
        validation,
        customData,
        oneLni: this.$oneLni,
    };

    if (this.$oneLni.isSpa()) {
        payload.router = this.$router;
    }

    if (Array.isArray(action)) {
        return multipleDispatch.apply(this, [action, payload]);
    }

    return this.$store.dispatch(action, payload);
}