<template>
    <li
        :class="[
            `--level-${level}`,
            isCurrent ? '--current' : null,
            showChildren || isCurrent ? '--active': null,
        ]"
        class="lni-c-side-nav__menu-item">
        <a
            v-if="!item.children || (item.children && item.children.length === 0)"
            class="lni-c-side-nav__link lni-u-pa2 "
            :class="[
                isCurrent ? '--current' : null,
                level === 1 ? 'lni-u-pv3' : 'lni-u-mv2',
                `--level-${level}`,
            ]"
            :href="item.href">
            <div class="lni-c-side-nav__label-content lni-u-pl1">
                <span
                    class="lni-u-anti-jank"
                    :data-label="item.title">
                    {{ item.title }}
                </span>
            </div>
        </a>
        <template v-else>
            <component
                :is="navItemType(item.href)"
                v-if="isCurrent"
                class="lni-c-side-nav__link lni-u-mv2 lni-u-pv0"
                :class="[
                    `--level-${level}`,
                    isCurrent ? '--current' : null,
                    showChildren || isCurrent ? '--active': null,
                ]"
                :href="item.href">
                <div class="lni-c-side-nav__label-content lni-u-pl2 lni-u-pv2 lni-u-flex">
                    <span class="lni-u-mr1">{{ item.title }}</span>
                    <span
                        :aria-hidden="true"
                        :class="['lni-u-mlauto',
                                 'lni-u-mr2',
                                 `lnicon--${showChildren ? 'caret--up' : 'caret--down'}`]"></span>
                </div>
            </component>
            <a
                v-else
                class="lni-c-side-nav__link"
                :class="[
                    isCurrent ? '--current' : null,
                    showChildren ? '--active':null,
                    `--level-${level}`,
                    'lni-u-pv1',
                    'lni-u-text--left']"
                :href="item.href">
                <div
                    class="lni-c-side-nav__label-content lni-u-pv3 lni-u-pl2 lni-u-flex">
                    <span class="lni-u-mr1 lni-u-pl1">{{ item.title }}</span>
                    <span
                        aria-hidden="true"
                        :class="['lni-u-mlauto',
                                 'lni-u-mr2',
                                 `lnicon--${showChildren ? 'caret--up' : 'caret--down'}`]"></span>
                </div>
            </a>
            <div v-if="showChildren || isCurrent">
                <ul
                    :class="[
                        'lni-c-side-nav__list',
                        'lni-u-pl2',
                        'lni-u-pb1',
                    ]">
                    <lni-side-nav-item
                        v-for="(subItem, subIndex) in item.children"
                        :id="`side-nav-item_level-${level + 1}_${subIndex}_${title}`"
                        :key="`side-nav-li_level-${level + 1}_${subIndex}_${title}`"
                        :item="subItem"
                        :level="level + 1"
                        :context="context"
                        :index="subIndex"></lni-side-nav-item>
                </ul>
            </div>
        </template>
    </li>
</template>

<script>
export default {
    name: 'lni-side-nav-item',
    computed: {
        title() {
            return this.item.title.replace(/\s/g, '');
        },
        showChildren() {
            return this.item.children
                             && this.item.children.length > 0
                             && this.item.hierarchyActive;
        },
        isCurrent() {
            if (this.item.active === 'true' || this.item.hierarchyActive === true) {
                return true;
            }
            return false;
        },
        navItemType() {
            return pageUrl => this.isCurrentPage(pageUrl) ? 'span' : 'a';
        },
    },
    methods: {
        cleanUrl(url) {
            if (url.charAt(url.length - 1) === '/') {
                return url.substring(0, url.length - 1);
            }

            return url;
        },
        isCurrentPage(pageUrl) {
            return this.cleanUrl(pageUrl) === this.cleanUrl(window.location.href);
        },
    },
}; </script>