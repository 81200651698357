export const checkForSMC = entityData => {
    // SMC Audience whitelist
    const whitelist = [
        'Employer',
        'Claimant',
        'MedicalProvider',
        'ThirdParty',
        'RetroGroup',
        'LegalRep',
    ];

    // black list - effectively, whats left over.
    // [
    //     'IME',
    //     'Surgical',
    //     'ActivityCoach',
    //     'LNI',
    //     'SelfInsuredEmployer',
    //     'ServiceProvider',
    //     'ApprenticeshipGovernment',
    //     'ApprenticeshipSponsor',
    //     'ApprenticeProgram',
    //     'PrevailingWages',
    //     'WageUpdate',
    //     'PWAwardingAgency',
    //     'ElectricalPlanReviewer',
    //     'ElevatorPlanReviewer',
    //     'FASPlanReviewer',
    //     'COHE'
    // ]
    let hasAccess = false;
    if (entityData.Entitys) {
        // examine each entity relationship
        entityData.Entitys.forEach( entity => {
            if (entity.Roles) {
                // check the Role node of each entity
                entity.Roles.forEach( role => {
                    if (whitelist.indexOf(role.relationship_code) !== -1) {
                        hasAccess = true;
                    }
                });
            }
        });
    }
    return hasAccess;
};