import text from '@gov.wa.lni/framework.one-lni.core/source/lib/getText.js';

const content = text('', 'lni-sharing-widget').content;

export default () => ({
    state: {
        alignment: 'right',
        opensFrom: 'top',
        fixed: true,
        shareTypes: [
            'copy',
            'email',
            'print',
            'tweet',
            'facebook',
            'native',
        ],
        content,
    },
});