
import addMessageGroup from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/component/mutations/customizeValidationMessage.js';
import hasError from '@gov.wa.lni/framework.one-lni.core/source/validation/component/hasError.js';
import hasProcessedError from '@gov.wa.lni/framework.one-lni.core/source/validation/component/hasProcessedError.js';
import firstProcessedError from '@gov.wa.lni/framework.one-lni.core/source/validation/component/firstProcessedError.js';
import firstError from '@gov.wa.lni/framework.one-lni.core/source/validation/component/firstError.js';

export default {
    addMessageGroup,
    hasError,
    hasProcessedError,
    firstProcessedError,
    firstError,
};