import Framework from '@gov.wa.lni/framework.one-lni.core/source/Framework';
import validateSetupParameters from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/validateSetupParameters';


import * as actions from '@gov.wa.lni/framework.one-lni.core/source/store/actions';
import * as mutations from '@gov.wa.lni/framework.one-lni.core/source/store/mutations';
import {
    Vue,
    Vuex,
} from '../external/index';

/**
 * Sets up the Vuex store for use by the framework and application components.
 * @param framework The framework instance to set up.
 * @param options The framework options to use when configuring
 */
export default function setupStore(framework: Framework, options: FrameworkOptions) {
    validateSetupParameters(framework, options);

    if (options.applicationActions) {
        framework.useActions(options.applicationActions);
    }

    const store = {
        strict: !framework.environment.current.isRelease,
        state: {
            count: 45,
        },
        mutations: {
            ...mutations,
        },
        actions: {
            ...actions,
            ...framework.options.applicationActions,
        },
    };

    Vue.use(Vuex);

    options.store = new Vuex.Store(store);

    if (framework.options.storeModules && Object.keys(framework.options.storeModules).length) {
        Object.keys(framework.options.storeModules).forEach(key => {
            options.store.registerModule(key, (framework.options.storeModules as any)[key] as any);
        });

    }
}