import setValue from '@gov.wa.lni/framework.one-lni.core/source/store/utils/setValue.js';
import setAttribute from '@gov.wa.lni/framework.one-lni.core/source/store/utils/setAttribute.js';
import getValue from '@gov.wa.lni/framework.one-lni.core/source/store/utils/getValue.js';
import getAttribute from '@gov.wa.lni/framework.one-lni.core/source/store/utils/getAttribute.js';

export default {
    setValue,
    setAttribute,
    getValue,
    getAttribute,
};