<template>
    <header>
        <div class="lni-c-secure-site-header">
            <div class="lni-c-site-header__inner-container">
                <div class="lni-c-site-header-secure__content lni-u-flex lni-u-items-center lni-o-color-context--inverse">
                    <div
                        id="ewnLogo"
                        class="lni-c-site-header__logo lni-u-ma1 secureHeaderLogo"
                    >
                        <a
                            href="/"
                            class="lni-u-flex lni-u-items-center "
                        >
                            <img
                                :id="`${id}_icon-logo`"
                                src="./lni-branding-white.svg?data"
                                class="lni-c-secure-header__icon"
                                alt=""
                                width="300"
                                height="76"
                            >
                            <span class="lni-u-visually-hidden">Washington State Department of Labor and Industries</span>
                        </a>
                    </div>

                    <nav
                        class="lni-c-site-header-secure_main-nav lni-u-mr1 lni-u-mlauto"
                        :class="{ 'lni-u-absolute lni-u-r5': isMobile }"
                    >
                        <ul class="lni-u-list-reset lni-u-flex  lni-u-items-right">
                            <li
                                v-if="isLoggedIn && hasSMC"
                                id="my-mail-icon"
                                class="lni-c-utility-nav__list-item lni-u-mt2"
                            >
                                <a
                                    class="lni-c-utility-nav__link lni-c-icon-button --transparent --responsive lni-u-mr2 lni-u-text--no-underline"
                                    :href="mailLink"
                                >
                                    <span
                                        v-if="correspondenceCount"
                                        class="lni-c-secure-header_correspondence-count lni-u-absolute"
                                    >
                                        <span class="lni-c-secure-header_correspondence-count-inner lni-u-inline-block lni-u-relative lni-u-text--center">
                                            {{ correspondenceCount }}
                                        </span>
                                    </span>
                                    <span
                                        :id="`${id}_icon-user`"
                                        aria-hidden="true"
                                        class="lni-c-icon lnicon--mail"
                                    />
                                    <span class="lni-c-icon-button__label">
                                        Mail
                                    </span>
                                </a>
                            </li>
                            <li
                                v-if="!isMobile && isLoggedIn"
                                class="lni-c-utility-nav__list-item lni-u-mt2"
                            >
                                <a
                                    class="lni-c-utility-nav__link lni-c-icon-button --transparent --responsive lni-u-mr2 lni-u-text--no-underline"
                                    :href="appHomeLink"
                                >
                                    <span
                                        :id="`${id}_icon-home`"
                                        class="lni-c-icon lnicon--lock"
                                        aria-hidden="true"
                                    />
                                    <span
                                        class="lni-c-icon-button__label"
                                    >{{ appTitle }}</span>
                                </a>
                            </li>
                            <li
                                v-if="!isMobile && isLoggedIn"
                                class="lni-c-utility-nav__list-item"
                            >
                                <button
                                    id="desktop-username-dropdown-button"
                                    class="lni-c-button lni-u-mr3 lni-c-mega-menu-secure__tab-button lni-u-nowrap lni-u-mt1 lni-u-base-font"
                                    :aria-expanded="`${isDesktopOpen}`"
                                    aria-controls="destop-entities"
                                    @click="toggleRolePopup"
                                    @keydown.esc.exact="toggleRolePopup"
                                >
                                    {{ userName }}
                                    <span
                                        aria-hidden="true"
                                        class="lnicon--triangle--down"
                                    />
                                </button>
                                <lni-collapse
                                    v-if="isLoggedIn && !isMobile"
                                    id="desktop-entities"
                                    :visible="false"
                                    :speed="2.0"
                                    class="lni-u-absolute lni-c-secure-header_collapse"
                                >
                                    <div
                                        id="entities-wrapper"
                                        class="lni-c-secure-header-entities-wrapper"
                                    >
                                        <ul>
                                            <li>
                                                <a
                                                    id="profile-link"
                                                    class="lni-c-secure-header-entity-link lni-u-inline-block lni-u-relative lni-u-text--no-underline"
                                                    :href="appHomeLink"
                                                    @keydown.shift.tab="moveFocusToLastLink"
                                                >
                                                    My Profile
                                                </a>
                                            </li>
                                            <li
                                                v-for="entity in entitiesWithAccess"
                                                :key="entity.entity_id"
                                                track-by="id"
                                            >
                                                <button
                                                    :id="makeRef(entity.entity_id)"
                                                    class="lni-c-secure-header-entity-link lni-u-inline-block lni-u-relative lni-u-text--left "
                                                    @click="entityClick"
                                                    @keydown.esc.exact="toggleRolePopup"
                                                >
                                                    {{ entity.entity_name }}
                                                </button>
                                            </li>
                                            <li>
                                                <a
                                                    id="registration-link"
                                                    class="lni-c-secure-header-entity-link lni-u-inline-block lni-u-relative lni-u-text--no-underline"
                                                    href="/home/Default.aspx#registration"
                                                    @keydown.esc.exact="toggleRolePopup"
                                                    @keydown.tab.exact="moveFocusToFirstLink"
                                                >
                                                    Get More Access +
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </lni-collapse>
                            </li>
                            <li
                                v-if="!isMobile && isLoggedIn"
                                class="lni-c-utility-nav__list-item"
                            >
                                <button
                                    id="desktop-logout-button"
                                    class="lni-c-button lni-u-text--all-caps lni-u-nowrap lni-u-mt1"
                                    @click="logOutButtonClick"
                                >
                                    Log Out
                                </button>
                            </li>
                        </ul>
                    </nav>
                    <div class="lni-u-flex lni-u-absolute lni-u-r0 lni-c-secure-header_nav-button">
                        <div
                            v-if="isMobile && isLoggedIn"
                            class="lni-c-primary-nav__container"
                        >
                            <lni-mega-menu-secure
                                :id="`${id}_mega-menu`"
                                :is-mobile="isMobile"
                                :content="entitiesWithAccess"
                                :user-name="userName"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <lni-modal
            id="inactive-modal"
            :visible="false"
            title="Alert"
        >
            <template #content>
                <div>Are you still there?</div>
            </template>
            <template #actions>
                <div class="height100">
                    <button
                        id="inactive-dialog-button"
                        class="lni-c-button lni-u-text--all-caps lni-u-nowrap"
                    >
                        Yes
                    </button>
                </div>
            </template>
        </lni-modal>
    </header>
</template>

<script>
import megaMenu from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-mega-menu-secure/lni-mega-menu-secure.vue';
import {
    getHeaderData,
} from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure/actions/getHeaderData.js';
import {
    getEntities,
} from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure/actions/getEntities.js';
import {
    getRelationShipCode,
    getTabName,
} from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure/actions/getInfoForRelationshipCode.js';
import {
    logOut,
} from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure/actions/logOut.js';
import {
    onEntityClick,
} from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure/actions/onEntityClick.js';
import {
    getCorrespondenceInformation,
} from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure/actions/getCorrespondenceInformation.js';
import {
    checkForSMC,
} from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure/actions/checkForSMC.js';
import {
    injectModalsStyles,
} from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure/actions/injectHeaderStyles.js';
import * as audience from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure/audiences.js';

const loginExemptList = [
    'local', //local or localhost
    'lnidpc', //particular PC
    'demo.lni.wa.gov', //demo site
    'dev.lni.wa.gov', //responsive dev site
    '.dev', //Development (Elroy)
    'unit-test.wads', //Test / Integration (Jane)
    'dev.wads', //Development (Elroy)
    '/ewn3/', //Directly loading EWN sample pages
    '/ewn3branch/', //Directly loading EWN sample pages
];

const body = document.querySelector('body');
const twoMinutes = 120000;
const twentyEightMins = 1680000;
const sixtySeconds = 60000;
const megaMenuBreakpoint = 715;

export default {
    name: 'LniHeaderSecure',
    components: {
        'lni-mega-menu-secure': megaMenu,
    },

    data() {
        return {
            isOpenNav: false,
            windowWidth: window.innerWidth,
            userName: '',
            isLoggedIn: false,
            hasSMC: false,
            correspondenceCount: null,
            isDesktopOpen: false,
            entities: [],
            appTitle: '',
            appHomeLink: '',
            mailLink: '',
            hasDesktopEntitiesModal: false,
        };
    },
    computed: {
        isMobile() {
            return this.windowWidth <= megaMenuBreakpoint;
        },
        entitiesWithAccess() {
            //filter pending requests
            let filteredEntities = this.entities.filter(entity => entity.Roles !== null);
            //create the correct display name and flag it if there is no code
            filteredEntities.forEach(renamedEntity => {
                const relationshipCode = renamedEntity.EntityRelationships[0].relationship_code;
                let code = getRelationShipCode(relationshipCode);
                if (code === 'rel_employer') {
                    // eslint-disable-next-line
                    renamedEntity.entity_name = renamedEntity.entity_name + ', UBI: '
                                        + renamedEntity.EntityRelationships[0]
                                            .identifier_value_data
                                            .replace(/(\d{3})(\d{3})(\d{3})/, '$1 $2 $3');
                } else if (code === 'rel_worker') {
                    // eslint-disable-next-line
                    renamedEntity.entity_name = renamedEntity.entity_name + ' worker dashboard';
                } else if (code === 'rel_provider') {
                    // eslint-disable-next-line
                    renamedEntity.entity_name = renamedEntity.entity_name + ' provider dashboard';
                } else if (code === '') {
                    // eslint-disable-next-line
                    filteredEntities = filteredEntities.filter(entity => {
                        return entity.entity_id !== renamedEntity.entity_id;
                    });
                } else {
                    const tabName = getTabName(relationshipCode);
                    if (tabName !== '' && code !== '') {
                        // eslint-disable-next-line
                        renamedEntity.entity_name = tabName;
                    }
                }
            });
            //filter out duplicates
            let unique = {};
            // eslint-disable-next-line
            let final = filteredEntities.filter(obj => !unique[obj.entity_name] && (unique[obj.entity_name] = true));
            return final;
        },
    },
    created() {
        if (window.app) {
            window.app.audiences = audience.audiences;
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('click', this.closeRolePopup);

        this.configureHeader();
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        logOutButtonClick(e) {
            e.preventDefault();
            const seapUrl = window.location.origin + '/pkmslogout';
            const iframe = document.createElement('iframe');
            const sawLogoutUrl = this.getLogoutUrl();
            iframe.style.display = 'none';
            iframe.src = sawLogoutUrl;
            iframe.sandbox = 'allow-same-origin';
            iframe.id = 'logOutIFrame';
            document.body.appendChild(iframe);
            document.getElementById('logOutIFrame').addEventListener('load', () => {
                window.location = seapUrl;
            });
        },
        getLogoutUrl: function getLogoutUrl() {
            const baseUrl = this.$oneLni.environment.current.baseUrl;
            let sawUrl = 'secureaccess.wa.gov';
            if (baseUrl.indexOf('test-') > -1) {
                sawUrl = `https://test-${sawUrl}/myFortress`;
            } else {
                sawUrl = `https://${sawUrl}/myAccess`;
            }
            sawUrl += '/saw/logout.do';
            return sawUrl;
        },

        configureHeader() {
            const currentEnvironment = this.$oneLni.environment.current;
            if (currentEnvironment.baseUrl.indexOf('localhost') === -1) {
                let hasAppTitle = !!window.ewnGlobals
                                  && !!window.ewnGlobals.config
                                  && !!window.ewnGlobals.config.apptitle;
                if (hasAppTitle) {
                    this.appTitle = window.ewnGlobals.config.apptitle;
                }
                this.appHomeLink = currentEnvironment.baseUrl + '/home';
                let mailAppTitle = 'securemessagecenter';
                if (currentEnvironment.baseUrl.indexOf('test-secure') !== -1) {
                    mailAppTitle = 'securemessagepreprod';
                }
                this.mailLink = `/home/autoAddService.aspx?App=${mailAppTitle}`;

                const self = this;
                getHeaderData(headerData => {
                    self.isLoggedIn = headerData.IsLoggedIn;
                    self.userName = unescape(headerData.UserName);
                    if (self.isLoggedIn) {
                        self.hasDesktopEntitiesModal = true;
                        //check if user is still logged in every 2 minutes
                        self.startLogInTimer();
                        getEntities(entitiyData => {
                            let entityObj = JSON.parse(entitiyData.d);
                            self.entities = entityObj.returnTypeField.Entitys;
                            //check for smc link
                            const hasSMCAccess = checkForSMC(entityObj.returnTypeField);
                            if (hasSMCAccess) {
                                this.hasSMC = true;
                                const limit = 99;
                                //get correspondence count
                                getCorrespondenceInformation( correspondenceCount => {
                                    if (correspondenceCount > 0) {
                                        if (correspondenceCount > limit) {
                                            self.correspondenceCount = '99+';
                                        } else {
                                            self.correspondenceCount = correspondenceCount;
                                        }
                                    }
                                }, this.$oneLni);
                                //check mail count every 60 second after
                                setInterval(() => {
                                    getCorrespondenceInformation( correspondenceCount => {
                                        if (correspondenceCount > limit) {
                                            self.correspondenceCount = '99+';
                                        } else {
                                            self.correspondenceCount = correspondenceCount;
                                        }
                                    }, this.$oneLni);
                                }, sixtySeconds);
                            }
                        }, this.$oneLni);
                    }
                }, this.$oneLni);
            } else {
                //populate local data
            }
        },
        promptInactivity() {
            //inject isolatedOneLniStyles.css if style sheet exists on main dom
            let hasOneLniStyles = this.oneLniStylesExist();
            if (!hasOneLniStyles) {
                injectModalsStyles();
            }
            this.attachModalClick();
            this.listenForModalClose();

            this.$store.commit('setAttribute', {
                id: 'inactive-modal',
                attribute: 'visible',
                value: true,
            }, {
                root: true,
            });
        },
        oneLniStylesExist() {
            let hasOneLniStyles = false;
            if (document.styleSheets) {
                for (const key of Object.keys(document.styleSheets)) {
                    if (document.styleSheets[key].href) {
                        if (document.styleSheets[key].href.indexOf('oneLni.css') !== -1) {
                            hasOneLniStyles = true;
                        }
                    }
                }
            }
            return hasOneLniStyles;
        },
        attachModalClick() {
            let closeButton = document.querySelector('#inactive-dialog-button');
            if (closeButton) {
                closeButton.onclick = () => {
                    window.parent.postMessage('CLOSE_INACTIVE_MODAL', '*');
                };
            }
        },
        listenForModalClose() {
            const self = this;
            window.addEventListener('message', messageEvent => {
                const data = messageEvent.data;
                if (typeof data !== 'string') {
                    return;
                }
                if (data === 'CLOSE_INACTIVE_MODAL') {
                    self.onModalClick();
                    setTimeout(() => {
                        self.listenForModalClose();
                    }, 0);
                }
            });
        },
        onModalClick() {
            this.$store.commit('setAttribute', {
                id: 'inactive-modal',
                attribute: 'visible',
                value: false,
            }, {
                root: true,
            });
        },
        // this is modified from the mdn polyfill
        // https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
        matches(element, selector) {
            if (element.matches) {
                return element.matches(selector);
            } else if (element.msMatchesSelector) {
                return element.msMatchesSelector(selector);
            } else if (element.webkitMatchesSelector(selector)) {
                return element.webkitMatchesSelector(selector);
            }
            return null;
        },
        // this is modified from the mdn polyfill
        // https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
        closest(element, selector) {
            let el = element;

            do {
                if (this.matches(el, selector)) {
                    return el;
                }

                el = el.parentElement || el.parentNode;
            } while (el !== null && el.nodeType === 1);
            return null;
        },
        closeRolePopup(event) {
            this.$el.removeEventListener('click', this.closeRolePopup);
            const openButton = this.closest(event.target, '#desktop-username-dropdown-button');
            if (openButton
                || event.target.id === 'desktop-username-dropdown-button'
                || event.target.tagName === 'LNI-EWN-HEADER') {
                return;
            }

            const popup = this.closest(event.target, '#lni-c-secure-header_collapse');
            if (!popup) {
                if (this.hasDesktopEntitiesModal) {
                    this.$store.commit('setAttribute', {
                        id: 'desktop-entities',
                        attribute: 'visible',
                        value: false,
                    }, {
                        root: true,
                    });
                }
                this.isDesktopOpen = false;
            }
        },
        toggleRolePopup() {
            setTimeout(() => {
                this.$el.addEventListener('click', this.closeRolePopup);
            }, 0);
            if (this.isDesktopOpen) {
                if (this.hasDesktopEntitiesModal) {
                    this.$store.commit('setAttribute', {
                        id: 'desktop-entities',
                        attribute: 'visible',
                        value: false,
                    }, {
                        root: true,
                    });
                }
                this.isDesktopOpen = false;
            } else if (!this.isDesktopOpen) {
                if (this.hasDesktopEntitiesModal) {
                    this.$store.commit('setAttribute', {
                        id: 'desktop-entities',
                        attribute: 'visible',
                        value: true,
                    }, {
                        root: true,
                    });
                }
                this.isDesktopOpen = true;
            }
        },
        startLogInTimer() {
            // eslint-disable-next-line
            let loginExempt = !!loginExemptList.find(str => window.location.href.toLowerCase().indexOf(str) > -1);
            if (!loginExempt) {
                //check if SAW has logged them out every 2 mins
                setInterval(() => {
                    getHeaderData(headerData => {
                        if (!headerData.IsLoggedIn) {
                            logOut();
                        }
                    }, this.$oneLni);
                }, twoMinutes);
                this.resetInactiveTimer();
                //add events to reset the inactive timer
                body.addEventListener('click', () => this.resetInactiveTimer());
                body.addEventListener('keydown', () => this.resetInactiveTimer());
                body.addEventListener('keyup', () => this.resetInactiveTimer());
                body.addEventListener('mousemove', () => this.resetInactiveTimer());
            }
        },
        resetInactiveTimer() {
            //clear old timeouts
            if (window.inactiveTimerThread) {
                window.clearTimeout(window.inactiveTimerThread);
            }
            if (window.finalInactiveTimerThread) {
                window.clearTimeout(window.finalInactiveTimerThread);
            }
            const self = this;
            // //start 28 min inactive timer
            window.inactiveTimerThread = setTimeout(() => {
                //prompt them after 28 mins
                self.promptInactivity();
                //if they havent responded in 2 mins log them out
                window.finalInactiveTimerThread = setTimeout(() => {
                    logOut();
                }, twoMinutes);
            }, twentyEightMins);
        },
        entityClick(event) {
            onEntityClick(event, this.entities);
        },
        moveFocusToFirstLink(event) {
            event.preventDefault();
            this.$el.querySelector('#profile-link').focus();
        },
        moveFocusToLastLink(event) {
            event.preventDefault();
            this.$el.querySelector('#registration-link').focus();
        },
        // Use menu item data to create unique string based on location
        makeRef(item) {
            return item;
        },
    },
}; </script>