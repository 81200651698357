export default (context, id, attribute) => {
    if (!context || !id || !attribute) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }

        return null;
    }

    return context.rootState[id][attribute];
};