/* eslint-disable max-len */
export default {
    'lni-checkbox': {
        messages: {
            valueMissing: {
                global: 'نرجو وضع علامة على "{legend}{labelText}"، فهذا مطلوب',
                inline: 'نرجو تحديد هذا الحقل',
            },
        },
    },
    'lni-checkbox-list': {
        messages: {
            valueMissing: {
                global: 'نرجو تحديد خيار لأجل "{legend}{labelText}"، فهذا مطلوب',
                inline: 'نرجو تحديد خيار',
            },
        },
    },
    'lni-error-container': {
        heading: 'قبل أن تتابع:',
        message: 'نرجو إصلاح الأخطاء التالية',
    },
    'lni-input-phone': {
        messages: {
            patternMismatch: {
                global: 'نرجو إدخال رقم هاتف صالح (الصيغة: XXX-XXX-XXXX)',
                inline: 'نرجو إدخال رقم هاتف صالح (الصيغة: XXX-XXX-XXXX)',
            },
        },
        phoneHelperText: 'الصيغة: XXX-XXX-XXXX',
    },
    'lni-input-text': {
        messages: {
            valueMissing: {
                global: 'نرجو إدخال "{legend}{labelText}"، فهذا مطلوب',
                inline: 'نرجو ملء هذا الحقل',
            },
            patternMismatch: {
                global: 'يرجى استخدام الصيغة الصحيحة لـ "{legend}{labelText}"',
                inline: 'الرجاء استخدام التنسيق الصحيح{example}',
            },
            typeMismatch: {
                global: 'يرجى استخدام الصيغة الصحيحة لـ "{legend}{labelText}". (AR)',
                inline: 'الرجاء استخدام التنسيق الصحيح{example}',
            },
            tooShort: {
                global: '"{labelText}" الذي تحتاجه هو {num}. الرجاء إضافة {difference} إلى القيمة المطلوبة.',
                inline: '"{labelText}" الذي تحتاجه هو {num}. الرجاء إضافة {difference} إلى القيمة المطلوبة.',
            },
        },
    },
    'lni-input-email': {
        messages: {
            valueMissing: {
                global: 'نرجو إدخال "{legend}{labelText}"، فهذا مطلوب',
                inline: 'نرجو ملء هذا الحقل',
            },
            patternMismatch: {
                global: 'يرجى استخدام بريد إلكتروني صحيح لـ "{legend}{labelText}"',
                inline: 'نرجو إدخال بريد إلكتروني صالح',
            },
            typeMismatch: {
                global: 'يرجى استخدام بريد إلكتروني صحيح لـ "{legend}{labelText}"',
                inline: 'نرجو إدخال بريد إلكتروني صالح',
            },
        },
    },
    'lni-radios': {
        messages: {
            valueMissing: {
                global: 'نرجو تحديد خيار لأجل "{legend}{labelText}"، فهذا مطلوب',
                inline: 'نرجو تحديد خيار',
            },
        },
    },
    'lni-select': {
        messages: {
            valueMissing: {
                global: 'نرجو تحديد شيءٍ ما لأجل "{legend}{labelText}"، فهذا مطلوب',
                inline: 'نرجو تحديد شيءٍ ما',
            },
        },
    },
    'lni-spinner': {
        label: 'جارٍ التحميل...',
    },
};
/* eslint-enable max-len */