import validation from '@gov.wa.lni/framework.one-lni.core/source/validation';

export default (context, payload) => new Promise(resolve => {
    const moduleState = context.state[payload.targetId];
    const componentHasError = validation.component.hasError(payload.targetId, moduleState, true);

    // only set the error text if this component has registered that they are interested in it
    const hasErrorTextProperty = typeof moduleState.errorText !== 'undefined';

    // This will reset the error array and add the first error of this specific
    // component if there is one.  If there are errors from descendant components(watched)
    // they will be removed and re-"collected".
    if ( componentHasError) {
        const firstError = validation.component.firstError(payload.targetId, moduleState, true);

        // set the errors on self component
        context.commit('setAttribute', {
            id: payload.targetId,
            attribute: 'errors',
            value: [
                firstError,
            ],
        }, {
            root: true,
        });

        if (hasErrorTextProperty) {
            // set the error text on self component
            context.commit('setAttribute', {
                id: payload.targetId,
                attribute: 'errorText',
                value: firstError.inline,
            }, {
                root: true,
            });
        }

    } else {
        context.commit('setAttribute', {
            id: payload.targetId,
            attribute: 'errors',
            value: [],
        }, {
            root: true,
        });

        if (hasErrorTextProperty) {
            context.commit('setAttribute', {
                id: payload.targetId,
                attribute: 'errorText',
                value: '',
            }, {
                root: true,
            });
        }
    }

    if (moduleState.errorsCollectedFrom) {
        context.dispatch('collectErrors', payload).then(() => {
            resolve();
        });
    } else {
        resolve();
    }
});