/* eslint-disable max-len */
export default {
    'lni-checkbox': {
        messages: {
            valueMissing: {
                global: '如有必要，请勾选"{legend}{labelText}"',
                inline: '请勾选此字段',
            },
        },
    },
    'lni-checkbox-list': {
        messages: {
            valueMissing: {
                global: '如有必要，请为"{legend}{labelText}"做出选择',
                inline: '请做出选择',
            },
        },
    },
    'lni-error-container': {
        heading: '我们继续之前：',
        message: '请修复以下错误',
    },
    'lni-input-phone': {
        messages: {
            patternMismatch: {
                global: '请输入有效的电话号码 (格式：XXX-XXX-XXXX)',
                inline: '请输入有效的电话号码 (格式：XXX-XXX-XXXX)',
            },
        },
        phoneHelperText: '格式：XXX-XXX-XXXX',
    },
    'lni-input-text': {
        messages: {
            valueMissing: {
                global: '如有必要，请输入"{legend}{labelText}"',
                inline: '请填写此字段',
            },
            patternMismatch: {
                global: '请为"{legend}{labelText}"使用正确的格式',
                inline: '请使用正确的格式{example}',
            },
            typeMismatch: {
                global: '请为"{legend}{labelText}"使用正确的格式',
                inline: '请使用正确的格式{example}',
            },
            tooShort: {
                global: '"{labelText}"必须为至少{num}个字符。请再添加{difference}个字符。',
                inline: '"{labelText}"必须为至少{num}个字符。请再添加{difference}个字符。',
            },
        },
    },
    'lni-input-email': {
        messages: {
            valueMissing: {
                global: '如有必要，请输入"{legend}{labelText}"',
                inline: '请填写此字段',
            },
            patternMismatch: {
                global: '请在"{legend}{labelText}"处使用有效的电子邮箱',
                inline: '请输入有效的电子邮件',
            },
            typeMismatch: {
                global: '请在"{legend}{labelText}"处使用有效的电子邮箱',
                inline: '请输入有效的电子邮件',
            },
        },
    },
    'lni-radios': {
        messages: {
            valueMissing: {
                global: '如有必要，请为"{legend}{labelText}"做出选择',
                inline: '请做出选择',
            },
        },
    },
    'lni-select': {
        messages: {
            valueMissing: {
                global: '如有必要，请为"{legend}{labelText}"选择一些选项',
                inline: '请做出选择',
            },
        },
    },
    'lni-spinner': {
        label: '正在加载…',
    },
};
/* eslint-enable max-len */