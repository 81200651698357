export default (context, payload) => {
    if (!context
        || !payload
        || !payload.validation) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }
        return;
    }

    let errorsCollectedBy = payload.validation.errorsCollectedBy;
    while (errorsCollectedBy) {
        let grandparentErrorsCollectedBy = context.rootState[errorsCollectedBy].errorsCollectedBy;
        context.dispatch('reportToSelf', {
            targetId: errorsCollectedBy,
            validation: {
                errorsCollectedBy: grandparentErrorsCollectedBy,
            },
        }, {
            root: true,
        });

        errorsCollectedBy = grandparentErrorsCollectedBy;
    }
};