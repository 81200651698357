export default (state, payload) => {
    if (!payload
        || !payload.validityTest
        || !payload.global
        || !payload.inline) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing from the payload object.');
        }

        return;
    }

    let currentValidationsToPerform = state.messages;

    if (!currentValidationsToPerform) {
        currentValidationsToPerform = {};
    }

    const newValidationsToPerform = {
        ...currentValidationsToPerform,
    };

    newValidationsToPerform[payload.validityTest] = {
        inline: payload.inline,
        global: payload.global,
    };

    let currentValidityObject = state.validity;

    if (!currentValidityObject) {
        currentValidityObject = {};
    }

    const newValidityObject = {
        ...currentValidityObject,
    };

    newValidityObject[payload.validityTest] = false;

    state.validity = newValidityObject;
    state.messages = newValidationsToPerform;
};