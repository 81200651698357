
export type ScriptSearchResult = {
    source: string;
    root: string;
    element: HTMLScriptElement;
};

/**
 * Finds a script element with a url that contains the provided scriptName
 * @param scriptName The name of the script to search for.
 * @returns A search result containing information about the script, or null if no script was found.
 */
export default function script(scriptName: string): ScriptSearchResult | null {
    const hostElement: HTMLScriptElement | null = window.document.querySelector(`script[src$='${scriptName}']`);

    if (!hostElement) {
        return null;
    }

    return {
        source: hostElement.src,
        root: hostElement.src.split('/').slice(0, -1).join('/'),
        element: hostElement,
    };
}