/* eslint-disable max-len */
export default {
    'lni-auto-suggestion': {
        statusMessages: {
            0: 'No se encontraron sugerencias.',
            1: 'Se encontró una sugerencia.',
            2: 'Se encontraron {count} sugerencias.',
        },
        instructions: 'Use las flechas del teclado para seleccionar.',
        messages: {
            valueMissing: {
                global: 'Se requiere una selección para "{legend}{labelText}."',
                inline: 'Se requiere una selección.',
            },
            valueMismatch: {
                global: 'Se requiere una selección para "{legend}{labelText}".',
                inline: 'Se requiere una selección',
            },
        },
    },
    'lni-checkbox': {
        messages: {
            valueMissing: {
                global: 'Se requiere una verificación para "{legend}{labelText}."',
                inline: 'Se requiere una verificación.',
            },
        },
    },
    'lni-checkbox-list': {
        messages: {
            valueMissing: {
                global: 'Se requiere una verificación para "{legend}{labelText}."',
                inline: 'Se requiere una verificación.',
            },
        },
    },
    'lni-error-container': {
        heading: 'Antes de continuar:',
        message: 'Corrija los siguientes errores.',
    },
    'lni-file-upload': {
        messages: {
            valueMissing: {
                global: 'Seleccione un archivo para cargar',
                inline: 'Seleccione un archivo.',
            },
            invalidFileSize: {
                global: 'Seleccione un archivo que no supere el tamaño de {sizeLimit}',
                inline: 'El tamaño del archivo es demasiado grande',
            },
            uploadLimit: {
                global: 'Ha alcanzado el límite de {fileLimit} archivos que se pueden cargar',
                inline: 'Se alcanzó el límite de carga de archivos',
            },
            invalidFileType: {
                global: 'Los archivos seleccionados son del tipo incorrecto para cargar. Seleccione un archivo con una de las extensiones que se muestran a continuación: {properExtensionList}',
                inline: 'Seleccione un archivo con una de las siguientes extensiones: {properExtensionList}',
            },
        },
        label: 'Seleccione archivo(s)',
        maxFileSize: 'Tamaño máximo de archivo:',
        uploadLimit: 'Se puede cargar un máximo de ({fileLimit}) archivos con este envío.',
        validFileTypes: 'Tipos de archivos válidos:',
        delete: 'Eliminar',
    },
    'lni-file-upload-form': {
        success: ' Su carga se realizó con éxito.',
        availableUploads: 'Archivos cargados ({available} cargas disponibles restantes)',
    },
    'lni-input-phone': {
        messages: {
            patternMismatch: {
                global: 'Se requiere un número de teléfono válido (Formato:XXX-XXX-XXXX).',
                inline: 'Se requiere un número de teléfono válido (Formato:XXX-XXX-XXXX).',
            },
        },
        extensionLabelText: 'Ext.',
        phoneHelperText: 'Formato: XXX-XXX-XXXX',
        phoneFieldsetLabelText: 'Teléfono ',
    },
    'lni-input-search': {
        labelText: 'Search',
        messages: {
            valueMissing: {
                global: '"{legend}{labelText}" es obligatorio.',
                inline: 'Este campo es obligatorio.',
            },
        },
    },
    'lni-input-text': {
        messages: {
            valueMissing: {
                global: '"{legend}{labelText}" es obligatorio.',
                inline: 'Este campo es obligatorio.',
            },
            patternMismatch: {
                global: '"{legend}{labelText}" requiere un formato correcto.',
                inline: 'Se requiere un formato correcto {example}.',
            },
            typeMismatch: {
                global: '"{legend}{labelText}" requiere un formato correcto.',
                inline: 'Se requiere un formato correcto {example}.',
            },
            tooLong: {
                global: '{labelText} está limitado a {limit} caracteres.',
                inline: 'Se requiere un límite de {limit} caracteres.',
            },
            tooShort: {
                global: '{labelText} debe tener al menos {num} caracteres. Agregue {difference} caracter(es) más.',
                inline: '{labelText} debe tener al menos {num} caracteres. Agregue {difference} caracter(es) más.',
            },
        },
    },
    'lni-input-address': {
        messages: {
            valueMissing: {
                global: '"{legend}{labelText}" es obligatorio.',
                inline: 'Complete los campos obligatorios.',
            },
            badZip: {
                global: '"{legend}{labelText}" requiere un formato correcto.',
                inline: 'El código postal requiere un formato correcto. Ejemplos: "98248" o "98504-4000"',
            },
        },
        requiredFields: 'Campos obligatorios: {fieldsString}.',
        labelText: {
            address1: ' Dirección 1',
            address2: 'Dirección 2',
            city: 'Ciudad',
            state: 'Estado',
            zip: 'Código Postal',
            county: 'Condado',
        },
    },
    'lni-input-date': {
        messages: {
            invalidMonth: {
                global: 'Introduzca un mes válido entre 01 y 12.',
                inline: 'Introduzca un mes válido entre 01 y 12.',
            },
            monthEnd: {
                global: 'Introduzca un día válido para el mes ingresado.',
                inline: 'Introduzca un día válido para el mes ingresado.',
            },
            invalidDay: {
                global: 'Introduzca un día válido entre 01 y 31.',
                inline: 'Introduzca un día válido entre 01 y 31.',
            },
            invalidYear: {
                global: 'Introduzca un año válido (ej. 1999).',
                inline: 'Introduzca un año válido (ej. 1999).',
            },
            leapYear: {
                global: '{value} no es un año bisiesto válido.',
                inline: '{value} no es un año bisiesto válido.',
            },
            dateIsInvalid: {
                global: 'La fecha para {labelText} no es válida.',
                inline: 'Esta fecha no es válida.',
            },
            inputMissing: {
                global: '{labelText} es obligatorio.',
                inline: 'Este campo es obligatorio.',
            },
        },
        helperText: 'Ejemplo: 10/22/2018',
    },
    // TODO: These are not being used (lni-input-text messages are used instead)
    'lni-input-email': {
        messages: {
            valueMissing: {
                global: '{legend} {labelText} es obligatorio.',
                inline: 'Este campo es obligatorio.',
            },
            patternMismatch: {
                global: 'Use un correo electrónico válido para {legend} {labelText}.',
                inline: 'Use un correo electrónico válido{ejemplo}.',
            },
            typeMismatch: {
                global: 'Use un correo electrónico válido para {legend} {labelText}.',
                inline: 'Use un correo electrónico válido{ejemplo}.',
            },
        },
    },
    'lni-input-password': {
        messages: {
            notStrongPassword: {
                global: '',
                inline: 'Las contraseñas deben contener al menos 3 de los siguientes elementos: una letra mayúscula, una letra minúscula y un número o carácter especial.',
            },
        },
    },
    'lni-modal': {
        closeLabel: 'Cerrar',
    },
    'lni-multiselect': {
        messages: {
            valueMissing: {
                global: 'Se requiere una selección para "{labelText}."',
                inline: 'Se requiere una selección.',
            },
        },
    },
    'lni-pager': {
        currentPageLabel: 'Página actual, Página {i}',
        goToPageLabel: 'Ir a página {i}',
        selectLabel: 'Filas por página',
        previousPageLabel: 'Anterior',
        ellipsisLabel: 'Ir al rango anterior',
        nextPageLabel: 'Siguiente',
        navLabel: 'Páginas',
        firstPageLabel: 'Ir a la página 1',
    },
    'lni-radios': {
        messages: {
            valueMissing: {
                global: 'Se requiere una selección para "{legend}{labelText}".',
                inline: 'Se requiere una selección.',
            },
        },
    },
    'lni-select': {
        messages: {
            valueMissing: {
                global: 'Se requiere una selección para "{legend}{labelText}."',
                inline: 'Se requiere una selección.',
            },
        },
    },
    'lni-sharing-widget': {
        content: {
            share: 'Compartir',
            copy: 'Obtener enlace',
            copied: 'Copiado',
            email: 'Correo electrónico',
            print: 'Imprimir',
            tweet: 'Tweet',
            tweetText: '',
            emailSubject: '',
            facebook: 'Compartir',
            shareVia: 'Compartir via...',
        },
    },
    'lni-spinner': {
        label: 'Cargando...',
    },
    'lni-table': {
        noRowsMessage: 'No se encontraron filas.',
        errorMessage: 'Hubo un error al recuperar los datos.',
    },
    readableList: {
        conjunction: 'y',
    },
};
/* eslint-enable max-len */