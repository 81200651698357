import framework from '@gov.wa.lni/framework.one-lni.core/source';

//Note: These options are only used when starting one-lni via the plugin.
//      This confusion is resolved in the vue3 branch.
const defaultOptions = {
    el: '#app',

    // The following should just contain the startup component(generally 'ApplicationMain')
    components: {},
    featureFlags: {},
    enableRouter: true,
    enableRouteBasedAnalytics: false,
    template: '<application-main/>',
    addAccessibleRouting: false,
    applicationComponents: [],
    applicationActions: {},
    navigationLocationOverride: '',
    routes: [],
    filters: {},
};

const OneLniPlugin = {};

OneLniPlugin.initialize = function initialize(options, callback) {
    const mergedOptions = Object.assign({}, defaultOptions, options);
    framework.initialize(mergedOptions)
        .then(configuration => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            callback && callback(configuration);
        })
        .catch(error => {
            throw error;
        });
};

OneLniPlugin.start = function start(callback) {
    framework.start()
        .then(configuration => {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            callback && callback(configuration);
        })
        .catch(error => {
            throw error;
        });
};

OneLniPlugin.setApplication = function setApplication(application) {
    if (framework.isStarted()) {
        throw new Error('Only a single instance of one-lni can per started on each page.');
    }

    framework.setApplication(application);
};

OneLniPlugin.install = function install(Vue) {
    Vue.prototype.$oneLni = framework;
};

export default OneLniPlugin;