<template>
    <div class="lni-c-top-tasks">
        <section class="lni-c-top-tasks__links lni-u-pa3">
            <h2 class="lni-u-text--overline lni-u-text--all-caps">
                {{ topTasksLabel }}
            </h2>
            <ul class="lni-c-top-tasks__link-list lni-u-pa0">
                <li
                    v-for="link in links"
                    :key="link.label">
                    <a
                        v-if="link === tabbable[0]"
                        ref="firstTabStop"
                        class="lni-c-top-tasks__link"
                        :href="link.href">{{ link.label }}</a>
                    <a
                        v-else
                        class="lni-c-top-tasks__link"
                        :href="link.href">{{ link.label }}</a>
                </li>
            </ul>
        </section>
        <section class="lni-c-top-tasks__contact lni-u-pa3">
            <ul class="lni-u-list-reset">
                <li
                    v-if="emailLink.email"
                    class="lni-u-flex">
                    <span
                        class="lni-c-icon lnicon--mail lni-c-top-tasks__icon lni-u-mr2"
                        aria-hidden="true"></span>
                    <div class="lni-u-flex-auto">
                        <span>{{ emailLink.label }}</span>
                        <a
                            v-if="emailLink === tabbable[tabbable.length - 1]"
                            ref="lastTabStop"
                            :href="'mailto:' + emailLink.email"
                            class="lni-c-top-tasks__link lni-u-nowrap"
                            @keydown.tab.exact="$emit('tabFromLastItem', $event)">{{ emailLink.email }}</a>
                        <a
                            v-else
                            :href="'mailto:' + emailLink.email"
                            class="lni-c-top-tasks__link lni-u-nowrap">{{ emailLink.email }}</a>
                    </div>
                </li>
                <li
                    v-if="phoneLink.number"
                    :class="['lni-u-flex']">
                    <span
                        class="lni-c-icon lnicon--phone lni-c-top-tasks__icon lni-u-mr2"
                        aria-hidden="true"></span>
                    <div class="lni-u-flex-auto">
                        <span>{{ phoneLink.label }}</span>
                        <a
                            ref="lastTabStop"
                            :href="'tel:+1' + phoneLink.number"
                            type="tel"
                            class="lni-c-top-tasks__link lni-u-nowrap"
                            @keydown.tab.exact="$emit('tabFromLastItem', $event)">{{ phoneLink.number | phoneFormat }}</a>
                    </div>
                </li>
            </ul>
        </section>
    </div>
</template>
<script>
export default {
    name: 'lni-top-tasks',
    filters: {
        phoneFormat(val) {
            let m = val.match(/^(\d{3})([A-Z0-9]{3})([A-Z0-9]{4})$/);
            return m ? '1-' + m[1] + '-' + m[2] + '-' + m[3] : null;
        },
    },
    props: {
        links: {
            type: Array,
            required: true,
        },
        phoneLink: {
            type: Object,
        },
        emailLink: {
            type: Object,
        },
        index: {
            type: Number,
        },
        topTasksLabel: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            tabbable: [...this.links],
        };
    },
    mounted() {
        if (this.emailLink && this.emailLink.email) {
            this.tabbable.push(this.emailLink);
        }

        if (this.phoneLink  && this.phoneLink.number) {
            this.tabbable.push(this.phoneLink);
        }
    },
}; </script>