/* eslint-disable max-len */
export default {
    'lni-checkbox': {
        messages: {
            valueMissing: {
                global: 'لطفًا "{legend}{labelText}" را علامت بزنید، این لازم است',
                inline: 'لطفًا این قسمت را بررسی کنید',
            },
        },
    },
    'lni-checkbox-list': {
        messages: {
            valueMissing: {
                global: 'لطفاً برای "{legend}{labelText}" یک انتخابی کنید، این لازم ',
                inline: 'لطفًا یک انتخاب کنید',
            },
        },
    },
    'lni-error-container': {
        heading: 'قبل از اینکه ادامه دهیم:',
        message: 'لطفًا خطاهای زیر را رفع کنید',
    },
    'lni-input-phone': {
        messages: {
            patternMismatch: {
                global: 'لطفًا یک شماره تلیفون معتبر را وارد کنید (قالب: XXX-XXX-XXXX)',
                inline: 'لطفًا یک شماره تلیفون معتبر را وارد کنید (قالب: XXX-XXX-XXXX)',
            },
        },
        phoneHelperText: 'قالب: XXX-XXX-XXXX',
    },
    'lni-input-text': {
        messages: {
            valueMissing: {
                global: 'لطفًا "{legend}{labelText}" را وارد کنید، این لازم است',
                inline: 'لطفا این قسمت رو پر کنید',
            },
            patternMismatch: {
                global: 'لطفاً از فرمت صحیح "{legend}{labelText}" استفاده کنید',
                inline: 'لطفاً از فرمت صحیح  استفاده کنید{example}',
            },
            typeMismatch: {
                global: 'لطفاً از فرمت صحیح "{legend}{labelText}" استفاده کنید',
                inline: 'لطفاً از فرمت صحیح  استفاده کنید{example}',
            },
            tooShort: {
                global: '"{labelText}" باید حداقل دارای {num} کاراکتر باشد. لطفاً یک نویسه {difference} دیگر اضافه کنید.',
                inline: '"{labelText}" باید حداقل دارای {num} کاراکتر باشد. لطفاً یک نویسه {difference} دیگر اضافه کنید.',
            },
        },
    },
    'lni-input-email': {
        messages: {
            valueMissing: {
                global: 'لطفًا "{legend}{labelText}" را وارد کنید، این لازم است',
                inline: 'لطفا این قسمت رو پر کنید',
            },
            patternMismatch: {
                global: 'لطفاً از یک ایمیل معتبر برای "{legend}{labelText}" استفاده کنید',
                inline: 'لطفًا یک ایمیل معتبر را وارد کنید',
            },
            typeMismatch: {
                global: 'لطفاً از یک ایمیل معتبر برای "{legend}{labelText}" استفاده کنید',
                inline: 'لطفًا یک ایمیل معتبر را وارد کنید',
            },
        },
    },
    'lni-radios': {
        messages: {
            valueMissing: {
                global: 'لطفاً برای "{legend}{labelText}" یک انتخابی کنید، این لازم است',
                inline: 'لطفًا یک انتخاب کنید',
            },
        },
    },
    'lni-select': {
        messages: {
            valueMissing: {
                global: 'لطفًا چیزی را برای "{legend}{labelText}" انتخاب کنید، این لازم است',
                inline: 'لطفاً یک چیزی را انتخاب کنید',
            },
        },
    },
    'lni-spinner': {
        label: 'در حال بارگیری…',
    },
};
/* eslint-enable max-len */