/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
import IStorageProxy, { StorageRecord } from '@gov.wa.lni/framework.one-lni.core/source/IStorageProxy';

/**
 * Stores key / value pairs in memory. Data stored here only lasts until the next page reload.
 */
export default class MemoryStorage implements IStorageProxy {

    private _store: Map<string, StorageRecord>;

    constructor() {
        this._store = new Map<string, StorageRecord>();
    }

    get available(): boolean {
        return true;
    }

    load(name: string): string | null {
        if (!this.has(name)) {
            return null;
        }

        return this._store.get(name)!.data;
    }

    loadMeta(name: string): StorageRecord | null {
        if (!this.has(name)) {
            return null;
        }

        return this._store.get(name)!; //TOOO: [Aaron] This should copy the result. (Check original code)
    }

    has(name: string) {
        return this._store.has(name);
    }

    clear() {
        this._store.clear();
    }

    remove(name: string) {
        this._store.delete(name);
    }

    save(name: string, data: any) {
        const record: StorageRecord = {
            type: typeof data,
            modified: Date.now(),
            data,
        };

        this._store.set(name, record);
    }
}