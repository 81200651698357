/* eslint-disable @typescript-eslint/no-non-null-assertion */
/**
 * Takes a string in kebab case and transforms it to camel case.
 * @param str The string to transform
 * @returns A camel case string
 */
export default function toCamelCase(str: string): string {
    if (!str || (typeof str !== 'string')) {
        return '';
    }

    return str.replace(/-([a-z])/g, g => g[1]!.toUpperCase());
}