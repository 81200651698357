import {
    getCurrentLocation,
} from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure/actions/getCurrentLocation.js';
import { fakeEntities } from '@gov.wa.lni/framework.one-lni.navigation-components/source/components/lni-header-secure/mockApi';
export const getEntities = (callback, oneLni) => {
    const currentEnvironment = getCurrentLocation(oneLni);
    let entitiesURL = `${currentEnvironment}/ewn/v4/GatewayProxy/UiProxy.aspx/UiGatewayRestMethod`;
    const sendData = {
        ServiceName: 'EwnMenuService',
        MethodName: 'GetEntities',
        Params: [window.location.pathname],
    };

    if (oneLni.environment.current.name === 'Local Development') {
        callback(fakeEntities);
    } else {
        fetch(entitiesURL, {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {
                'Content-type': 'application/json',
            },
            body: JSON.stringify(sendData),
        }).then(response => response.json())
            .then(data => {
                callback(data);
            });
    }
};