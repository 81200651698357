import tabbable from '@gov.wa.lni/framework.one-lni.directives/source/tabbable.js';

export default {
    componentUpdated(el, binding, vnode) {
        const options = binding.value || {};

        let tabbableElements = tabbable(el, options);

        vnode.context.tabbableEls = tabbableElements;
    },
};