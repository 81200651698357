export default (context, payload) => new Promise(resolve => {
    if (!context
        || !payload
        || !payload.targetId) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }
        resolve();
        return;
    }

    const moduleState = context.rootState[payload.targetId];
    if (!moduleState) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('The component\'s store module was not found.');
        }
        resolve();
        return;
    }

    const errorsCollectedFrom = moduleState.errorsCollectedFrom;
    if (!errorsCollectedFrom || !Array.isArray(errorsCollectedFrom)) {
        if (window.lni.Vue.config.devtools) {
            throw new Error(`The component's store module does not have a valid errorsCollectedFrom property.`);
        }
        resolve();
        return;
    }

    errorsCollectedFrom.forEach(id => {
        context.commit(`${id}/initializeValidationState`);
        let innerErrorsCollectedFrom = context.rootState[id].errorsCollectedFrom;
        if (innerErrorsCollectedFrom) {
            innerErrorsCollectedFrom.forEach(innerId => {
                context.commit(`${innerId}/initializeValidationState`);
                let innerInnerErrorsCollectedFrom = context.rootState[innerId].errorsCollectedFrom;
                if (innerInnerErrorsCollectedFrom) {
                    innerInnerErrorsCollectedFrom.forEach(innerInnerId => {
                        context.commit(`${innerInnerId}/initializeValidationState`);
                    });
                }
            });
        }
    });
    resolve();
});