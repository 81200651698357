
export default (context, payload) => new Promise(resolve => {
    if (!context
        || !payload
        || !payload.targetId) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }
        resolve();
        return;
    }

    const moduleState = context.rootState[payload.targetId];
    if (!moduleState) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('The component\'s store module was not found.');
        }
        resolve();
        return;
    }

    if (!payload.validity) {
        payload.validity = {
            ...moduleState.validity,
        };
    }

    const customValidityAction = moduleState.customValidityAction
        ? moduleState.customValidityAction
        : `${payload.targetId}/customValidity`;

    context.dispatch(customValidityAction, payload).then(customValidity => {

        const messageFunctions = moduleState.messages;
        if (!messageFunctions) {
            resolve();
            return;
        }

        const validationsToPerform = Object.keys(messageFunctions);

        // the previously set validity state that has all of the registered
        // validity tests recorded
        const updatedValidity = {
            ...moduleState.validity,
        };

        const newValidityObject = customValidity ? customValidity : payload.validity;

        // get validity keys from html validity object that this input is interested in
        validationsToPerform.forEach(key => {
            if (typeof newValidityObject[key] !== 'undefined') {
                updatedValidity[key] = newValidityObject[key];
            }
        });

        context.commit(`${payload.targetId}/setValidity`, {
            validity: updatedValidity,
        });

        const errorsCollectedBy = moduleState.errorsCollectedBy;
        if (errorsCollectedBy) {
            context.dispatch('updateValidity', {
                targetId: errorsCollectedBy,
                validity: null,
            }).then(() => {
                resolve();
            });
        } else {
            resolve();
        }
    });
});