import {
    Vue,
} from '@gov.wa.lni/framework.one-lni.core/source/external/index';
import generateAccessors from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/generateAccessors';
import component from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/component';
import oneLniComponentMixin from '@gov.wa.lni/framework.one-lni.core/source/mixins/oneLniComponent.js';
import Framework from '@gov.wa.lni/framework.one-lni.core/source/Framework';

/**
 * Registers a component with the framework so it can be used in applications.
 * @param framework The framework instance to add the component to.
 * @param options The framework options object
 * @param definition The component definition, including Vue component and module definition.
 */
export default function useComponent(
    framework: Framework,
    options: FrameworkOptions,
    definition: OneLniComponentDefinition,
) {
    if (!definition
        || !definition.component
        || !definition.module) {
        if (window.lni?.Vue.config.devtools) {
            throw new Error('A required parameter is missing or invalid.');
        }

        return;
    }

    definition.component.mixins = [...definition.component.mixins || [], oneLniComponentMixin];
    const generatedDefinition = generateAccessors(definition);

    if (framework.started) {
        throw new Error(`Cannot add component '${generatedDefinition.component.name} after the application has started`);
    }

    generatedDefinition.component.destroyed = function destroyed() {
        const thisModule = options.store.state[this.id];
        // If preserveState is true lets keep this module around
        if (thisModule && !thisModule.preserveState) {
            options.store.unregisterModule(this.id);
        }
    };

    generatedDefinition.component.beforeMount = function beforeMount() {
        const instanceId = this.$attrs.id;
        if (options.store.state[this.id]) {
            // This module already exists, and has been initialized, so do nothing
            return;
        }

        if (!this.$attrs.id) {
            throw new Error(`(${generatedDefinition.component.name}) Each component must have a valid id`);
        }

        const moduleInstance = generatedDefinition.module();
        moduleInstance.namespaced = true;

        component.addGetters(moduleInstance, instanceId);
        component.addActions(moduleInstance);
        component.addMutations(moduleInstance);

        options.store.registerModule(instanceId, moduleInstance);

        Object.keys(this.$attrs)
            .map(key => ({
                original: key,
                key: framework.util.toCamelCase(key),
            })).forEach(attr => {
                this[attr.key] = this.$attrs[attr.original];
            });

        if (!this.$oneLni.environment.current.isRelease) {
            this.$store.commit('REFRESH_STORE');
        }
    };

    Vue.component(generatedDefinition.component.name, generatedDefinition.component);
}