export default (context, id, attribute, value) => {
    if (!context || !id || !attribute) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }
        return;
    }

    context.commit('setAttribute', {
        id,
        attribute,
        value,
    }, {
        root: true,
    });
};