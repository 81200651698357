<template>
    <div
        v-if="isLoaded"
        class="lni-c-breadcrumbs lni-o-color-context--inverse"
    >
        <div
            class="lni-c-breadcrumbs__container lni-u-mhauto lni-u-pv2"
        >
            <nav
                v-if="!titleOnly"
                class="lni-c-breadcrumbs__nav lni-u-mh1"
                aria-label="breadcrumbs"
            >
                <ol class="lni-u-list-reset lni-u-pa0 lni-c-breadcrumbs__list ">
                    <li class="lni-u-inline-block lni-u-type--xxs lni-c-breadcrumbs__crumb">
                        <a
                            class="lni-c-breadcrumbs__link"
                            :href="origin ? homeUrl : '/'">{{ homeLabel }}</a>
                        <span
                            aria-hidden="true"
                            class="lni-c-breadcrumbs__separator lni-u-ph1 lnicon--caret--right"
                        />
                    </li>
                    <li
                        v-for="(crumb, index) in crumbs"
                        :key="crumb.label"
                        class="lni-u-inline-block lni-u-type--xxs lni-c-breadcrumbs__crumb"
                        :class="index === 1 ? 'lni-u-text--all-caps' : null"
                    >
                        <a
                            v-if="index !== 1"
                            class="lni-c-breadcrumbs__link"
                            :href="crumb.route"
                        >{{ crumb.label }}
                        </a>
                        <span v-else>{{ crumb.label }}</span>

                        <span
                            v-if="index !== 2"
                            aria-hidden="true"
                            class="lni-c-breadcrumbs__separator lni-u-ph1 lnicon--caret--right"
                        />
                        <span v-else>:&nbsp;</span>
                    </li>
                </ol>
            </nav>
            <h1
                v-if="page"
                class="lni-c-breadcrumbs__title lni-u-mh1 lni-u-heading--1"
            >
                {{ page.label }}
            </h1>
        </div>
    </div>
</template>

<script>
import * as api from '@gov.wa.lni/framework.one-lni.navigation-components/source/navigation.api.js';

export default {
    name: 'LniBreadcrumb',
    computed: {
        homeUrl() {
            return this.origin.charAt(this.origin.length - 1) === '/'
                ? this.origin : `${this.origin}/`;
        },
    },
    mounted() {
        this.$nextTick(() => {
            //TODO: should we call navigation at all if titleOnly is true
            if (this.crumbs === null) {
                this.getNavigation();
            }
        });
    },
    methods: {
        getNavigation() {
            api.getNavigation(null, this.$oneLni).then(data => {
                this.updateData(data);
            });
        },
        updateData(data) {
            const pageFromAPI = data.breadcrumbTrail ? data.breadcrumbTrail.pop() : null;
            if (!this.page)  {
                this.page = pageFromAPI;
            }

            this.crumbs = data.breadcrumbTrail;
            this.origin = data.origin;
            this.isLoaded = true;
            this.homeLabel = data.courtesyMenu.homeLabel;
        },
    },
}; </script>