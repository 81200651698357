import setAttribute from '@gov.wa.lni/framework.one-lni.core/source/store/utils/setAttribute.js';

export default (context, id, value) => {
    if (!context || !id) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }
        return;
    }

    setAttribute(context, id, 'value', value);
};