import detectDependencies from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/dependency/detectDependencies.js';
import updateDependencyStatus from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/dependency/updateDependencyStatus.js';
import waitForDependencyScript from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/dependency/waitForDependencyScript.js';

export default function loadDependencies(callback) {
    const dependencyState = detectDependencies();

    waitForDependencyScript(dependencyState, 'oneLniComponents', callback);

    if (updateDependencyStatus(dependencyState)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        callback && callback();
    }
}