<template>
    <div class="lni-c-mega-menu__heading lni-u-flex lni-u-items-stretch">
        <div
            class="lni-u-flex lni-u-items-center lni-u-pl2 lni-u-pt2 lni-u-pb1"
            :class="{
                'lni-c-mega-menu-secure__heading-content lni-c-mega-menu-secure__dark-background': secure,
                'lni-c-mega-menu__heading-content': !secure,
                'lni-u-justify-between': level === 1,
            }">
            <button
                ref="mobileBackButton"
                :aria-label="level === 1 ? 'Close Menu' : `Back to level ${level - 1}`"
                class="lni-u-button-reset lni-u-flex lni-u-mr2 lni-u-justify-center lni-u-items-center"
                :class="secure ? 'lni-c-mega-menu-secure__back-button lni-c-mega-menu-secure__dark-background' : 'lni-c-mega-menu__back-button'"
                @click="$emit('click')"
                @keydown.shift.tab.exact="$emit('shiftTabFromStart', $event)">
                <div
                    :class="level === 1 ? 'lnicon--close' : 'lnicon--arrow--left'"
                    aria-hidden="true"></div>
            </button>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'lni-mega-menu-heading',
    props: {
        level: {
            type: Number,
            required: true,
        },
        secure: {
            type: Boolean,
            default: false,
        },
    },
}; </script>