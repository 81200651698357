/* eslint-disable max-len */
export default {
    'lni-checkbox': {
        messages: {
            valueMissing: {
                global: 'Waajibin ya ni an na "{legend}{labelText}" segesege',
                inline: 'Ke nuqu segesege',
            },
        },
    },
    'lni-checkbox-list': {
        messages: {
            valueMissing: {
                global: 'Waajibin ya ni an nan sugandi "{legend}{labelText}" danŋa',
                inline: 'Sugandinde dabari',
            },
        },
    },
    'lni-error-container': {
        heading: 'Saado an bica jokki:',
        message: 'Ku helanu teleŋondi',
    },
    'lni-input-phone': {
        messages: {
            patternMismatch: {
                global: 'An telehon nimeero siren rondi (Giya: XXX-XXX-XXXX)',
                inline: 'An telehon nimeero siren rondi (Giya: XXX-XXX-XXXX)',
            },
        },
        phoneHelperText: 'Giya: XXX-XXX-XXXX',
    },
    'lni-input-text': {
        messages: {
            valueMissing: {
                global: 'Waajibin ya ni an na {legend}{labelText}" xibaari',
                inline: 'Ke nuqu tirindindun jaabi',
            },
            patternMismatch: {
                global: 'Sigirirenme "{legend}{labelText}" ke ro nuqu be siren ga ni.',
                inline: 'Dudoxo nan golli ti yokki siren ŋa{example}',
            },
            typeMismatch: {
                global: 'Sigirirenme "{legend}{labelText}" ke ro nuqu be siren ga ni.',
                inline: 'Dudoxo nan golli ti yokki siren ŋa{example}',
            },
            tooShort: {
                global: 'Sigirirenme {num} ke ho xooren nan xawa "{labelText}" noxon di. Xa da sigirirenme {difference} xa kahu a yi.',
                inline: 'Sigirirenme {num} ke ho xooren nan xawa "{labelText}" noxon di. Xa da sigirirenme {difference} xa kahu a yi.',
            },
        },
    },
    'lni-input-email': {
        messages: {
            valueMissing: {
                global: 'Waajibin ya ni an na {legend}{labelText}" xibaari',
                inline: 'Ke nuqu tirindindun jaabi',
            },
            patternMismatch: {
                global: 'Sigirirenme "{legend}{labelText}" ke ro internetin kitekillen ŋa.',
                inline: 'An kitekillen ho siren rondi',
            },
            typeMismatch: {
                global: 'Sigirirenme "{legend}{labelText}" ke ro internetin kitekillen ŋa.',
                inline: 'An kitekillen ho siren rondi',
            },
        },
    },
    'lni-radios': {
        messages: {
            valueMissing: {
                global: 'Waajibin ya ni an nan sugandi "{legend}{labelText}" danŋa',
                inline: 'Sugandinde dabari',
            },
        },
    },
    'lni-select': {
        messages: {
            valueMissing: {
                global: 'Waajibin ya ni an na ho sugandi "{legend}{labelText}" danŋa',
                inline: 'Sugandinde dabari',
            },
        },
    },
    'lni-spinner': {
        label: 'Gaayindinde…',
    },
};
/* eslint-enable max-len */