export const injectModalsStyles = () => {
    let modalStyleExists = !!document.getElementById('lni-isolated-styles');
    if (!modalStyleExists) {
        const currentEnvironment
                = `${window.document.location.protocol}//${window.document.location.host}`;
        let head = document.getElementsByTagName('head')[0];
        let link  = document.createElement('link');
        link.rel  = 'stylesheet';
        link.type = 'text/css';
        link.id = 'lni-isolated-styles';
        link.href = currentEnvironment + '/one-lni/v3/isolatedOneLniStyles.css';
        head.appendChild(link);
    }
};