export default (state, payload) => {
    if (!payload
        || !payload.validationTest
        || !payload.global
        || !payload.inline) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing from the payload object.');
        }

        return;
    }

    let currentValidationsToPerform = state.messages;
    const newValidationsToPerform = {
        ...currentValidationsToPerform,
    };

    if (newValidationsToPerform[payload.validationTest]) {
        newValidationsToPerform[payload.validationTest] = {
            inline: payload.inline,
            global: payload.global,
        };
    }

    state.messages = newValidationsToPerform;
};