export default state => {
    if (state) {
        if (!state.flags) {
            state.flags = {};
        }

        state.flags.dirty = false;
        state.errors = [];
        state.errorText = '';
    }
};