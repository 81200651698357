export default function generateAccessors(componentDefinition) {
    if (!componentDefinition
        || !componentDefinition.module
        || !componentDefinition.component) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }

        return null;
    }

    const mixin = {
        computed: {},
    };

    const state = componentDefinition.module().state;

    if (state) {
        state.preserveState = false;
        Object.keys(state).forEach(key => {
            mixin.computed[key] = {
                get() {
                    return this.$store.state[this.id][key];
                },
                set(value) {
                    this.$store.commit('setAttribute', {
                        id: this.id,
                        attribute: key,
                        value,
                    });
                },
            };
        });

        mixin.computed.isValid = {
            get() {
                const errors = this.$store.state[this.id].errors;
                if (typeof errors === 'undefined') {
                    return null;
                }

                return errors && errors.length === 0;
            },
        };
    }

    componentDefinition.component.mixins.push(mixin);

    return componentDefinition;
}