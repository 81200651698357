import setDirty from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/component/mutations/setDirty.js';
import setValidity from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/component/mutations/setValidity.js';
import addValidityTest from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/component/mutations/addValidityTest.js';
import setValidityTestValue from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/component/mutations/setValidityTestValue.js';
import customizeValidationMessage from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/component/mutations/customizeValidationMessage.js';
import initializeValidationState from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/component/mutations/initializeValidationState.js';

export default {
    setDirty,
    setValidity,
    addValidityTest,
    setValidityTestValue,
    customizeValidationMessage,
    initializeValidationState,
};