/**
 * Returns the text of the specified locale
 *  If lang is falsey, uses the framework language
 *  Defaults to english if locale or id don't exist
 */

import locales from '@gov.wa.lni/framework.one-lni/source/locale';

export default function getText(lang, id) {
    let language = lang;
    if (!lang && window.oneLni) {
        language = window.oneLni.getLanguage();
    }
    const locale = locales[language] ? locales[language] : locales.en;
    return locale?.[id] || locales.en[id];
}