/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-underscore-dangle */
import Version from '@gov.wa.lni/framework.one-lni.core/source/Version';
import environment, { CurrentEnvironment } from '@gov.wa.lni/framework.one-lni.core/source/environment';
import { Vue } from '@gov.wa.lni/framework.one-lni.core/source/external';
import Utility from '@gov.wa.lni/framework.one-lni.core/source/Utility';
import storeUtils from '@gov.wa.lni/framework.one-lni.core/source/store/utils';
import * as mixins from '@gov.wa.lni/framework.one-lni.core/source/mixins';
import validation from '@gov.wa.lni/framework.one-lni.core/source/validation';
import loadDependencies from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/dependency/loadDependencies.js';
import components from '@gov.wa.lni/framework.one-lni.core/source/components';
import conflictResolution from '@gov.wa.lni/framework.one-lni.core/source/conflict-resolution';

import useComponent from '@gov.wa.lni/framework.one-lni.core/source/lifecycle/useComponent';
import FrameworkStorage from '@gov.wa.lni/framework.one-lni.core/source/FrameworkStorage';

export type SetupFunction = (framework: Framework, userOptions: FrameworkOptions) => void;

/**
 * This is the main framework class. An instance gets exported ant put on the window.
 * It is also available in vue components and actions.
 */
export default class Framework {
    private _version: Version;
    private _environment: CurrentEnvironment;
    private _featureFlags: { [key: string]: any };
    private _storage: FrameworkStorage;
    private _utility: Utility;
    private _setupFunctions: Array<SetupFunction>;
    private _options: FrameworkOptions;

    private _analyticsId: string;
    private _started: boolean;
    private _language: string;
    private _location: string;
    private _sharingWidget: any;
    private _application: any;

    /**
     *
     * @returns an empty options object
     */
    public static createDefaultOptions(): FrameworkOptions {
        const result: FrameworkOptions = {
            el: '#app',
            comments: true,
            components: {},
            featureFlags: {},
            enableRouter: true,
            enableRouteBasedAnalytics: false,
            addAccessibleRouting: false,
            applicationComponents: [],
            applicationActions: {},
            navigationLocationOverride: '',
            routes: [],
            filters: {},
        };

        return result;
    }

    constructor(setupFunctions?: Array<SetupFunction> | null) {
        this._options = Framework.createDefaultOptions();

        this._version = new Version(3, 0, 0);
        this._environment = environment;
        this._featureFlags = {};
        this._utility = new Utility(this);
        this._analyticsId = '';
        this._started = false;
        this._location = '';
        this._sharingWidget = null;
        this._application = null;

        this._storage = new FrameworkStorage();

        this._setupFunctions = [];

        if (setupFunctions) {
            setupFunctions.forEach(fn => this._setupFunctions.push(fn));
        }

        this._language = '';
        this.setLanguage();
    }

    get version() {
        return this._version;
    }

    get environment() {
        return this._environment;
    }

    get featureFlags() {
        return this._featureFlags;
    }

    get storage() {
        return this._storage;
    }

    get util() {
        return this._utility;
    }

    get storeUtils() {
        return storeUtils;
    }

    get mixins() {
        return mixins;
    }

    get validation() {
        return validation;
    }

    get options() {
        return this._options;
    }

    get analyticsId() {
        return this._analyticsId;
    }

    get started() {
        return this._started;
    }

    isStarted() {
        return this.started;
    }

    public getLocation() {
        return this._location;
    }

    public setLocation(value: string) {
        this._location = value;
    }

    public getSharingWidget() {
        return this._sharingWidget;
    }

    public setSharingWidget(value: any) {
        this._sharingWidget = value;
    }

    isSpa(): boolean {
        return !!this._options.enableRouter;
    }

    setFeatureFlags(incoming: { [key: string]: any }) {
        Object.keys(this._featureFlags).forEach(flag => {
            if (typeof incoming[flag] !== 'undefined') {
                this._featureFlags[flag] = incoming[flag];
            }
        });
    }

    setLanguage(value: string | null = null, save: boolean = true) {
        this._language = value ? value : this.util.detectLanguagePreference();

        if (save) {
            this.storage.cookie.set('lniLanguagePreference', this._language, {
                domain: '.lni.wa.gov',
            });
        }
    }

    getLanguage(): string {
        return this._language;
    }

    private _mergeOptions(userOptions: FrameworkOptions) {
        if (typeof userOptions.el !== 'undefined') {
            this._options.el = userOptions.el;
        }

        if (typeof userOptions.template !== 'undefined') {
            this._options.template = userOptions.template;
        }

        if (typeof userOptions.enableRouter !== 'undefined') {
            this._options.enableRouter = !!userOptions.enableRouter;
        }

        if (userOptions.enableRouteBasedAnalytics) {
            this._options.enableRouteBasedAnalytics = true;
        }

        if (userOptions.addAccessibleRouting) {
            this._options.addAccessibleRouting = true;
        }

        if (userOptions.components) {
            this._options.components = userOptions.components;
        }

        if (userOptions.filters) {
            this._options.filters = userOptions.filters;
        }

        if (userOptions.routes) {
            this._options.routes = userOptions.routes;
        }

        if (userOptions.routing) {
            this._options.routing = userOptions.routing;
        }

        if (userOptions.storeModules) {
            this._options.storeModules = userOptions.storeModules;
        }

        if (userOptions.applicationActions) {
            this.useActions(userOptions.applicationActions);
        }
    }

    private _initializeAfterLoad(userOptions: FrameworkOptions): Promise<FrameworkOptions> {
        return new Promise((resolve, reject) => {
            try {
                this._mergeOptions(userOptions);

                this._setupFunctions.forEach(fn => fn(this, this._options));

                this.useComponents(components);

                if (userOptions.applicationComponents) {
                    this.useComponents(userOptions.applicationComponents);
                }

                if (window.oneLniComponents) {
                    this.useComponents(window.oneLniComponents!);
                }

                if (this._options.filters) {
                    Object.keys(this._options.filters).forEach(filterName => {
                        window.lni.Vue.filter(filterName, this._options.filters![filterName]);
                    });
                }

                resolve(this._options);
            } catch (error) {
                reject(error);
            }
        });
    }

    /**
     * Initializes the framework by registering any components etc. Must be completed before calling start().
     * @param options the options to use when creating the Vue application instance and configuring the framework.
     * @param callback optional callback that is called when initialize is completed.
     * @returns A promise if callback is not provided otherwise return null after framework initialization.
     */
    initialize(
        options: FrameworkOptions,
        callback?: null | ((configuration: FrameworkOptions) => void),
    ): null | Promise<FrameworkOptions> {
        const componentRegistration: Promise<FrameworkOptions> = new Promise((resolve, reject) => {
            loadDependencies(() => {
                this._initializeAfterLoad(options)
                    .then((frameWorkOptions: FrameworkOptions) => {
                        resolve(frameWorkOptions);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        });
        if (callback) {
            componentRegistration.then((configuration: FrameworkOptions) => {
                callback(configuration);
            });
            return null;
        }
        return componentRegistration;

    }

    /**
     * Starts the framework, must be called after initialize().
     * @returns A promise that resolves to the Vue application instance if callback is not provided otherwise returns null.
     */
    start(callback?: (application: any) => void): null | Promise<any> {
        if (this._started) {
            throw new Error('Only a single instance of one-lni can per started on each page.');
        }

        const appInstance = new Promise((resolve, reject) => {
            try {
                const configuration = {
                    ...this._options,
                    store: this._options.store,
                };

                if (this._options.router) {
                    configuration.router = this._options.router;
                }

                Vue.prototype.$oneLni = this;
                this._application = new Vue(configuration);
                this._started = true;

                resolve(this._application);
            } catch (error) {
                reject(error);
            }

        });

        if (callback) {
            appInstance.then(vueAppInstance => {
                callback(vueAppInstance);
            });
            return null;
        }
        return appInstance;

    }

    /**
     * Registers an action with the framework so it can be used in applications.
     * @param name The name of the action to register. This is the name that will be used to dispatch the action later.
     * @param action The function to call when the action is dispatched.
     */
    useAction(name: string, action: ActionFunction): void {
        if (!name || !action || typeof action !== 'function') {
            if (window.lni.Vue.config.devtools) {
                throw new Error('A required parameter is missing or invalid.');
            }

            return;
        }

        if (this.started) {
            throw new Error(`Cannot add interaction '${name} after the application has started`);
        }

        if (!this._options.applicationActions) {
            throw new Error('Initialize Error - applicationActions undefined');
        }

        this._options.applicationActions[name] = action;
    }

    /**
     * Registers the actions with the framework so it can be used in applications.
     * @param actions an object that where each key is the name of the action and the value is the action function.
     */
    useActions(actions: { [key: string]: ActionFunction }): void {
        Object.keys(actions).forEach(key => this.useAction(key, actions[key]!));
    }

    /**
     * Registers a component with the framework so it can be used in applications.
     * @param definition The component definition, including Vue component and module definition.
     */
    useComponent(definition: OneLniComponentDefinition): void {
        useComponent(this, this._options, definition);
    }

    /**
     * Registers an array of component with the framework so it can be used in applications.
     * @param definitions An array of component definitions, each includes a Vue component and module definition.
     */
    useComponents(definitions: Array<OneLniComponentDefinition>): void {
        definitions.forEach(def => this.useComponent(def));
    }

    setAnalyticsId(value: string) {
        this._analyticsId = value;
    }

    getStore() {
        return this._options.store;
    }

    setApplication(application: any) {
        this._application = application;
        this._started = true;
    }

    get application() {
        return this._application;
    }

    activeFramework() {
        return conflictResolution.activeFramework();
    }

    noConflict() {
        return conflictResolution.noConflict();
    }
}