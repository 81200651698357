import validation from '@gov.wa.lni/framework.one-lni.core/source/store/actions/validation';

// TODO: jws-why is the import and then spread not working here?
const collectErrors = validation.collectErrors;
const reportDown = validation.reportDown;
const reportToSelf = validation.reportToSelf;
const reportUp = validation.reportUp;
const setErrorsOnSelf = validation.setErrorsOnSelf;
const updateValidity = validation.updateValidity;
const validate = validation.validate;
const validateForm = validation.validateForm;
const resetFormValidationState = validation.resetFormValidationState;
const resetDown = validation.resetDown;

export {
    collectErrors,
    reportDown,
    reportToSelf,
    reportUp,
    setErrorsOnSelf,
    updateValidity,
    validate,
    validateForm,
    resetFormValidationState,
    resetDown,

};