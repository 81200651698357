import {
    Vue,
} from '@gov.wa.lni/framework.one-lni.core/source/external';
import oneLniCorePlugin from '@gov.wa.lni/framework.one-lni.core/one-lni.core.plugin.js';
import framework from '@gov.wa.lni/framework.one-lni.core/source';

window.oneLni = framework;
// Put the version resolver on the window
window.activeOneLni = framework.activeFramework;

if (window.lni && window.lni.featureFlags) {
    framework.setFeatureFlags(window.lni.featureFlags);
}

window.lni = {
    Vue,
    oneLniCorePlugin,
    featureFlags: framework.featureFlags,
};

export default framework;