/* eslint-disable max-len */
export default {
    'lni-checkbox': {
        messages: {
            valueMissing: {
                global: 'कृपया "{legend}{labelText}" जाँचें, यह आवश्यक है',
                inline: 'कृपया इस फील्ड को जाँचें',
            },
        },
    },
    'lni-checkbox-list': {
        messages: {
            valueMissing: {
                global: 'कृपया "{legend}{labelText}" के लिए एक चयन करें, यह आवश्यक है',
                inline: 'कृपया एक चयन करें',
            },
        },
    },
    'lni-error-container': {
        heading: 'हम आगे बढ़ें इससे पहले:',
        message: 'कृपया ये त्रुटियाँ ठीक करें',
    },
    'lni-input-phone': {
        messages: {
            patternMismatch: {
                global: 'कृपया एक वैध फोन नंबर दर्ज करें (प्रारूप: XXX-XXX-XXXX)',
                inline: 'कृपया एक वैध फोन नंबर दर्ज करें (प्रारूप: XXX-XXX-XXXX)',
            },
        },
        phoneHelperText: '(प्रारूप: XXX-XXX-XXXX)',
    },
    'lni-input-text': {
        messages: {
            valueMissing: {
                global: 'कृपया "{legend}{labelText}" दर्ज करें, यह आवश्यक है',
                inline: 'कृपया इस क्षेत्र को भरें',
            },
            patternMismatch: {
                global: 'कृपया "{legend}{labelText}" के लिए सही फ़ॉर्मेट का इस्तेमाल करें',
                inline: 'कृपया का सही फॉर्मेट का इस्तेमाल करें{example}',
            },
            typeMismatch: {
                global: 'कृपया "{legend}{labelText}" के लिए सही फ़ॉर्मेट का इस्तेमाल करें',
                inline: 'कृपया का सही फॉर्मेट का इस्तेमाल करें{example}',
            },
            tooShort: {
                global: '"{labelText}" कम से कम {num} कैरेक्टर लंबा होना चाहिए। कृपया {difference} और कैरेक्टर जोड़ें',
                inline: '"{labelText}" कम से कम {num} कैरेक्टर लंबा होना चाहिए। कृपया {difference} और कैरेक्टर जोड़ें',
            },
        },
    },
    'lni-input-email': {
        messages: {
            valueMissing: {
                global: 'कृपया "{legend}{labelText}" दर्ज करें, यह आवश्यक है',
                inline: 'कृपया इस क्षेत्र को भरें',
            },
            patternMismatch: {
                global: 'कृपया "{legend}{labelText}" के लिए एक मान्य ईमेल का इस्तेमाल करें',
                inline: 'कृपया एक वैध ईमेल दर्ज करें',
            },
            typeMismatch: {
                global: 'कृपया "{legend}{labelText}" के लिए एक मान्य ईमेल का इस्तेमाल करें',
                inline: 'कृपया एक वैध ईमेल दर्ज करें',
            },
        },
    },
    'lni-radios': {
        messages: {
            valueMissing: {
                global: 'कृपया "{legend}{labelText}" के लिए एक चयन करें, यह आवश्यक है',
                inline: 'कृपया एक चयन करें',
            },
        },
    },
    'lni-select': {
        messages: {
            valueMissing: {
                global: 'कृपया "{legend}{labelText}" के लिए कुछ चुनें, यह आवश्यक है',
                inline: 'कृपया कुछ चुनें',
            },
        },
    },
    'lni-spinner': {
        label: 'लोड हो रहा है…',
    },
};
/* eslint-enable max-len */