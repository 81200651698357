export default () => ({
    state: {
        content: null,
        navigation: null,
        topTasks: null,
        isLoaded: false,
        activeTab: -1,
        location: null,
        origin: '',
        environment: 'public',
        language: '',
        isUrlMapped: false,
        secureHref: null,
    },
});