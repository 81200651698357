<template>
    <button
        type="button"
        class="lni-c-sharing-button--copy"
        @click="onClick">
        <span
            class="lnicon--link"
            aria-hidden="true"></span>
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: 'lni-copy-url',
    data() {
        return {
            isCopied: false,
        };
    },
    methods: {
        onClick() {
            this.copyUrl();
        },
        copyUrl() {
            window.navigator.clipboard.writeText(window.location.href).then(() => {
                this.isCopied = true;
                this.$emit('copied');
            }).catch(() => {
                //TODO: better error handling. give user the text to copy by hand? feature test?
                console.warn(`Couldn't copy ${window.location.href}.`);
            });
        },
    },
};
</script>