import validation from '@gov.wa.lni/framework.one-lni.core/source/validation';

export default (context, payload) => new Promise(resolve => {
    if (!context || !payload || !payload.targetId) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }
        resolve();
        return;
    }

    const moduleState = context.rootState[payload.targetId];
    if (!moduleState) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('The validation component\'s store module was not found.');
        }
        resolve();
        return;
    }

    const errorsCollectedFrom = moduleState.errorsCollectedFrom;

    // Before this action is called, we have cleared out all child errors.
    // Now we recreate the collection of child errors.
    const errors = [];
    if (moduleState.errors) {
        moduleState.errors.forEach(error => {
            errors.push(error);
        });
    }

    errorsCollectedFrom.forEach(id => {
        let childModuleState = context.rootState[id];
        if (validation.component.hasError(id, childModuleState)) {
            errors.push(validation.component.firstError(id, childModuleState, false));
        }
    });

    context.commit('setAttribute', {
        id: payload.targetId,
        attribute: 'errors',
        value: errors,
    }, {
        root: true,
    });

    resolve();
});