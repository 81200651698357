import dispatchEvent from '@gov.wa.lni/framework.one-lni.core/source/mixins/dispatchEvent';

export default {
    inheritAttrs: false,
    computed: {
        id: {
            get() {
                return this.$attrs.id;
            },
            set(value) {
                this.$attrs.id = value;
            },
        },
        index: {
            get() {
                return this.$attrs.index;
            },
            set(value) {
                this.$attrs.index = value;
            },
        },
        key: {
            get() {
                return this.$attrs.key;
            },
            set(value) {
                this.$attrs.key = value;
            },
        },
    },
    mounted() {
        if (this.errorsCollectedFrom) {
            this.$store.dispatch(`${this.id}/initializeErrorsCollectedFrom`, {
                targetId: this.id,
            }).then(() => {
                this.$store.dispatch(`${this.id}/setErrorsCollectedBy`, {
                    targetId: this.id,
                });
            });
        }
    },
    methods: {
        dispatchEvent,
    },
};