import oneLniComponent from '@gov.wa.lni/framework.one-lni.core/source/mixins/oneLniComponent.js';
import wholeEnvironment from '@gov.wa.lni/framework.one-lni.core/source/environment';

export default {
    name: 'lni-environment',
    mixins: [
        oneLniComponent,
    ],
    render(createElement) {
        const environment = wholeEnvironment.current;

        if (!environment.display) {
            return null;
        }

        return createElement('div', {
            class: `lni-c-environment__container --${environment.type} lni-u-pa1`,
        }, [
            createElement('p', environment.name),
        ]);
    },
    methods: {},

};