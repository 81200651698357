<template>
    <div class="lni-c-courtesy-menu lni-u-pa2">
        <nav
            class="lni-c-courtesy-menu__nav lni-c-box--highlight lni-u-pa2"
            aria-label="utility links">
            <ul class="lni-u-list-reset lni-u-pl0 lni-u-flex lni-u-justify-between">
                <li class="lni-c-utility-nav__item">
                    <a
                        class="lni-c-utility-nav__link lni-c-icon-button --transparent --vertical"
                        :href="content.contact.link">
                        <span
                            class="lni-c-icon lnicon--address-book"
                            aria-hidden="true"
                            aria-label="Contact  lni-u-type--lg"></span>
                        <span class="lni-c-icon-button__label lni-u-type--xs">{{ content.contact.label }}</span>
                    </a>
                </li>
                <li class="lni-c-utility-nav__item">
                    <div class="lni-c-mega-menu__language-select lni-u-bw0 lni-u-relative lni-u-overflow-hidden lni-u-full-hieght lni-c-icon-button --transparent --vertical">
                        <span
                            class="lni-u-absolute lni-u-pointer-event--none lni-c-icon  lni-u-type--lg lnicon--translate"
                            aria-hidden="true"></span>
                        <span class="lni-u-visually-hidden">Change language from:</span>
                        <select
                            class="lni-u-utitlity-nav__control lni-u-text-center lni-u-type--xs lni-c-mega-menu__language-select-icon"
                            @change="reRoute($event)">
                            <option
                                value="english"
                                :selected="language===(''||'english')">
                                English
                            </option>
                            <option
                                value="spanish"
                                :selected="language==='spanish'">
                                Español
                            </option>
                            <option
                                value="cambodian"
                                :selected="language==='cambodian'">
                                ប្រទេសកម្ពុជា
                            </option>
                            <option
                                value="chinese-simplified"
                                :selected="language==='chinese-simplified'">
                                中国 - 简体
                            </option>
                            <option
                                value="chinese-traditional"
                                :selected="language==='chinese-traditional'">
                                中文繁體
                            </option>
                            <option
                                value="korean"
                                :selected="language==='korean'">
                                한국어
                            </option>
                            <option
                                value="russian"
                                :selected="language==='russian'">
                                русский
                            </option>
                            <option
                                value="somali"
                                :selected="language==='somali'">
                                Soomaali
                            </option>
                            <option
                                value="vietnamese"
                                :selected="language==='vietnamese'">
                                tiếng Việt
                            </option>
                        </select>
                    </div>
                </li>
                <li class="lni-c-utility-nav__item">
                    <a
                        ref="lastTabStop"
                        class="lni-c-utility-nav__link lni-c-icon-button --transparent --vertical"
                        href="https://secure.lni.wa.gov"
                        @keydown.tab.exact="$emit('tabFromLastItem', $event)">
                        <span
                            class="lni-c-icon lnicon--user lni-u-type--lg"
                            aria-hidden="true"></span>
                        <span
                            class="lni-c-icon-button__label lni-u-type--xs">{{ content.loginLabel }}</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
/**
 *  The lni-sub-menu component.
 *  @module components/lni-mega-menu
 */

/**
 * The lni-mega-menu is a fullwidth menu.
 */
export default {
    name: 'lni-courtesy-menu',
    props: {
        origin: {
            type: String,
            default: 'https://www.lni.wa.gov/',
        },
        content: {
            type: Object,
            default: () => ({
                homeLabel: 'Home',
                loginLabel: 'My L&I',
            }),
        },
    },
    data() {
        return {
            showLanguages: false,
        };
    },
    computed: {
        language() {
            return window.location.href.split('/').pop();
        },
    },
    methods: {
        reRoute(val) {
            window.location = `${this.origin}/agency/languages/${val.target.value}`;
        },
        toggleLanguages() {
            this.showLanguages = !this.showLanguages;
        },
    },
}; </script>