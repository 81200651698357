
export default (context, payload) => new Promise(resolve => {
    if (!context || !payload || !payload.targetId) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }
        resolve();
        return;
    }

    const moduleState = context.rootState[payload.targetId];
    if (!moduleState) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('The validation component\'s store module was not found.');
        }
        resolve();
        return;
    }

    const errorsCollectedFrom = moduleState.errorsCollectedFrom;
    const errorContainerId = moduleState.errorContainerId;

    if (!errorsCollectedFrom && !errorContainerId) {
        resolve();
        return;
    }

    // if error container is provided, simulate that it is leading the form validation
    if (errorContainerId) {
        payload.targetId = errorContainerId;

        const errorContainerModuleState = context.rootState[errorContainerId];
        if (!errorContainerModuleState) {
            if (window.lni.Vue.config.devtools) {
                throw new Error(`The error container module that is pointed to does not exist.`);
            }
            resolve();
            return;
        }

        const errorContainerWatched = errorContainerModuleState.errorsCollectedFrom;
        if (!errorContainerWatched) {
            context.commit('setAttribute', {
                id: errorContainerId,
                attribute: 'errorsCollectedFrom',
                value: errorsCollectedFrom,
            }, {
                root: true,
            });
        }
    }

    context.dispatch('reportDown', payload, {
        root: true,
    }).then(() => {
        context.dispatch('reportToSelf', payload, {
            root: true,
        }).then(() => {
            resolve();
        });
    });
});