import am from '@gov.wa.lni/framework.one-lni/source/locale/am.js';
import ar from '@gov.wa.lni/framework.one-lni/source/locale/ar.js';
import en from '@gov.wa.lni/framework.one-lni/source/locale/en.js';
import es from '@gov.wa.lni/framework.one-lni/source/locale/es.js';
import fa from '@gov.wa.lni/framework.one-lni/source/locale/fa.js';
import fr from '@gov.wa.lni/framework.one-lni/source/locale/fr.js';
import hi from '@gov.wa.lni/framework.one-lni/source/locale/hi.js';
import om from '@gov.wa.lni/framework.one-lni/source/locale/om.js';
import pa from '@gov.wa.lni/framework.one-lni/source/locale/pa.js';
import ru from '@gov.wa.lni/framework.one-lni/source/locale/ru.js';
import sn from '@gov.wa.lni/framework.one-lni/source/locale/sn.js';
import so from '@gov.wa.lni/framework.one-lni/source/locale/so.js';
import ti from '@gov.wa.lni/framework.one-lni/source/locale/ti.js';
import vi from '@gov.wa.lni/framework.one-lni/source/locale/vi.js';
import zhHans from '@gov.wa.lni/framework.one-lni/source/locale/zh-hans.js';
import zhHant from '@gov.wa.lni/framework.one-lni/source/locale/zh-hant.js';

export default {
    am,
    ar,
    en,
    es,
    fa,
    fr,
    hi,
    om,
    pa,
    ru,
    sn,
    so,
    ti,
    vi,
    zhHans,
    zhHant,
};