export default function dependencyStatus(dependencyState, dependencyType) {
    if (!dependencyState) {
        if (window.lni.Vue.config.devtools) {
            throw new Error('A required parameter is missing.');
        }

        return false;
    }

    if (dependencyState.completed === true) {
        return false;
    }

    if (dependencyType) {
        dependencyState[dependencyType].loaded = true;
    }

    const noneRequired = !dependencyState.oneLniComponents.required;

    if (noneRequired) {
        dependencyState.completed = true;
        return true;
    }

    const missingOneLniComponents
        = dependencyState.oneLniComponents.required && !dependencyState.oneLniComponents.loaded;

    const missingDependency = missingOneLniComponents;

    if (!missingDependency) {
        dependencyState.completed = true;
        return true;
    }

    return false;
}