<template>
    <!-- eslint-disable vue/no-v-text-v-html-on-component -->
    <!-- eslint-disable vue/no-v-html -->
    <component
        :is="element"
        :id="id"
        :key="index"
        :class="classString"
        v-html="sanitizedHtmlString"
    />
</template>

<script>
import Sanitizer from '@gov.wa.lni/component.lni-html-element/sanitizer.js';

/**
 *  The lni-html-element component. The lni-html-element renders an element from a block of html.
 *  @module components/lni-html-element
 */
export default {
    name: 'LniHtmlElement',
    computed: {
        sanitizedHtmlString() {
            return this.sanitize(this.htmlString);
        },
    },
    methods: {
        sanitize() {
            let sanitizer = new Sanitizer(true);
            let sanitizedHtml = sanitizer.sanitizeString(this.htmlString);
            if (sanitizedHtml === '') {
                // TODO: research and document the reason for this. Is it because our sanitize breaks?
                sanitizedHtml = this.htmlString.replace(new RegExp('<', 'g'), '&lt;');
                sanitizedHtml = sanitizedHtml.replace(new RegExp('>', 'g'), '&gt;');
            }
            return sanitizedHtml;
        },
    },
}; </script>