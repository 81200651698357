/**
 * Sanitizer which filters a set of whitelisted tags and attributes.
 * https://www.quaxio.com/html_white_listed_sanitizer/
 *
 */
export default class Sanitizer {

    /**
     * Creates a new instance of the Sanitizer class.
    * @param {Boolean} escape whether to escape or strip undesirable content.
    * @param {Map} tags Map of allowed tag-attribute-attribute-parsers.
    */
    constructor(escape, tags) {
        this.escape = escape;
        this.allowedTags = tags;
        this.initialize();
    }

    initialize() {
        // Use the browser to parse the input but create a new HTMLDocument.
        // This won't evaluate any potentially dangerous scripts since the element
        // isn't attached to the window's document. It also won't cause img.src to
        // preload images.
        this.doc = document.implementation.createHTMLDocument('sandbox');

        if (!this.allowedTags) {
            let unconstrained = x => x;
            let globalAttributes = {
                dir: unconstrained,
                lang: unconstrained,
                title: unconstrained,
                class: unconstrained,
            };
            this.allowedTags = {
                a: {
                    ...globalAttributes,
                    download: unconstrained,
                    href: unconstrained,
                    hreflang: unconstrained,
                    ping: unconstrained,
                    rel: unconstrained,
                    target: unconstrained,
                    type: unconstrained,
                },
                img: {
                    ...globalAttributes,
                    alt: unconstrained,
                    height: unconstrained,
                    src: unconstrained,
                    width: unconstrained,
                },
                p: globalAttributes,
                div: globalAttributes,
                span: globalAttributes,
                br: globalAttributes,
                b: globalAttributes,
                i: globalAttributes,
                u: globalAttributes,
                em: globalAttributes,
                strong: globalAttributes,
                small: globalAttributes,
                ul: globalAttributes,
                ol: globalAttributes,
                pre: globalAttributes,
                li: globalAttributes,
                dl: globalAttributes,
                dt: globalAttributes,
                dd: globalAttributes,
                sub: globalAttributes,
                sup: globalAttributes,
                h1: globalAttributes,
                h2: globalAttributes,
                h3: globalAttributes,
                h4: globalAttributes,
                h5: globalAttributes,
                h6: globalAttributes,
                table: globalAttributes,
                td: globalAttributes,
                tr: globalAttributes,
                colgroup: globalAttributes,
                col: globalAttributes,
                tbody: globalAttributes,
                svg: {
                    ...globalAttributes,
                    style: unconstrained,
                },
                defs: globalAttributes,
                symbol: {
                    ...globalAttributes,
                    id: unconstrained,
                    viewBox: unconstrained,
                },
                title: globalAttributes,
                path: {
                    ...globalAttributes,
                    d: unconstrained,
                },
            };
        }
    }

    sanitizeString(input) {
        let div = this.doc.createElement('div');
        div.innerHTML = input;

        // Return the sanitized version of the node.
        return this.sanitizeNode(div).innerHTML;
    }

    sanitizeNode(node) {
        let nodeName = node.nodeName.toLowerCase();
        if (nodeName === '#text') {
            // text nodes are always safe
            return node;
        }
        if (nodeName === '#comment') {
            // always strip comments
            return this.doc.createTextNode('');
        }
        if (!Object.prototype.hasOwnProperty.call(this.allowedTags, nodeName)) {
            // this node isn't allowed
            if (this.escape) {
                return this.doc.createTextNode(node.outerHTML);
            }
            return this.doc.createTextNode('');
        }

        // create a new node
        let copy = this.doc.createElement(nodeName);

        // copy the whitelist of attributes using the per-attribute sanitizer
        // the inner sanitizer here can support a url sanitizer or other type of function
        // that would validate each attribute.  Currently, they all
        // have the default "unconstrained" sanitizer function.
        for (let nAttr = 0; nAttr < node.attributes.length; nAttr++) {
            let attr = node.attributes.item(nAttr).name;
            if (Object.prototype.hasOwnProperty.call(this.allowedTags[nodeName], attr)) {
                let sanitizer = this.allowedTags[nodeName][attr];
                copy.setAttribute(attr, sanitizer(node.getAttribute(attr)));
            }
        }

        // recursively sanitize child nodes
        while (node.childNodes.length > 0) {
            let child = node.removeChild(node.childNodes[0]);
            copy.appendChild(this.sanitizeNode(child));
        }

        return copy;
    }
}